@import 'mixins';

$gridRow: 1070px;
$gridColumn: $md;

.filtersCol {
  width: 100%;

  &:first-child:last-child {
    width: 100%;
  }

  @include mMaxWidth($gridColumn) {
    width: 100%;
  }
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include mMaxWidth($gridRow) {
    flex-wrap: wrap;
  }

  @include mMaxWidth($gridColumn) {
    flex-direction: column;
  }
}

.formItem {
  margin: 0 10px;
}
