.linkContainer {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  display: inline;
  line-height: var(--text-b2-line-height);
}

.linkColoredText {
  color: var(--palette-color-primary);
}
