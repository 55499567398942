@import '../../styles/shared';

$side-content-width-lg: 280px;
$side-content-indent-lg: 8px;

.wrapper {
  @include mMinWidth($layout-lg) {
    display: flex;
  }
}

.sideContent {
  margin-bottom: 8px;

  @include mMinWidth($layout-lg) {
    width: $side-content-width-lg;
    margin-right: $side-content-indent-lg;
    margin-bottom: 0;
  }
}

.mainContent {
  @include mMinWidth($layout-lg) {
    flex-grow: 1;
    width: calc(100% - #{$side-content-width-lg});
  }
}
