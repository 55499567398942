.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.addDocButton {
  margin-top: -10px; // hack due to big form fields default margin-bottom
  margin-left: auto;
  margin-bottom: calc(var(--spacing-base) * 3);
}

.addBillButton {
  margin-top: -24px; // hack due to big form fields default margin-bottom and extra
  margin-left: auto;
}
