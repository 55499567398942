@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

.tm2-deprecated-page-styles {
  .text-block {
    .react-datepicker-wrapper, .react-datepicker__input-container, .rc-time-picker {
      display: block;
    }
  }
  .react-datepicker {
    border: none !important;
    background-color: @TM2Charcoal !important;
    color: @TM2White !important;
  }
  .react-datepicker__header {
    background-color: @TM2Charcoal !important;
    border-bottom: 1px solid @separatorBorderColor !important;
  }
  .react-datepicker__month { background: @TM2Charcoal !important; }
  .react-datepicker__day--keyboard-selected {
    background-color: @TM2Gold !important;
    color: @TM2White !important;
  }
  .react-datepicker__day { color: @TM2White !important; }
  .react-datepicker__day:hover { background-color: @TM2Charcoal !important; }
  .react-datepicker__current-month, .react-datepicker__day-name { color: @TM2White !important; }
  .react-datepicker__day--outside-month { color: @TM2Grey3 !important; }
  .react-datepicker__navigation--previous { border-right-color: @TM2Gold !important; }
  .react-datepicker__navigation--previous:focus { outline: none !important; }
  .react-datepicker__navigation--next { border-left-color: @TM2Gold !important; }
  .react-datepicker__navigation--next:focus { outline: none !important; }
  .react-datepicker__day--selected { background-color: @TM2Gold !important; }
  .react-datepicker__day--selected:focus { outline: none !important; }
  .react-datepicker__day--in-range { background-color: @TM2Gold !important; }
  .react-datepicker__day--in-selecting-range {
    background-color: @TM2Gold !important;
    opacity: 0.6 !important;
  }
  .react-datepicker__day--keyboard-selected:hover { background-color: @TM2Gold !important; }
  .react-datepicker__day--selected, .react-datepicker__day--selected:hover { background-color: @TM2Gold !important; }
  .react-datepicker__day--disabled, .react-datepicker__day--disabled:hover {
    cursor: default !important;
    color: @TM2Grey1 !important;
    background-color: initial !important;
  }
  .react-datepicker__day--selecting-range-end:hover { background-color: @TM2Gold !important; }
  .react-datepicker__month-dropdown { color: @TM2Grey1 !important;}
  .react-datepicker__month-option {
    background-color: @TM2White !important;
    &:hover {
      background-color: @TM2Gold !important;
      color: @TM2White !important;
    }
  }
  .react-datepicker__year-dropdown {
    color: @TM2Grey1 !important;
  }
  .react-datepicker__year-option {
    background-color: @TM2White !important;
    &:hover {
      background-color: @TM2Gold !important;
      color: @TM2White !important;
    }
  }
}
