@import 'variables';

$bgColorLight: $TM2Grey5;
$bgColorDark: $TM2Grey;

.tm2-radio-box {
  background-color: transparent;

  .tm2-form-label {
    &:after { // fix for label line to hide field border during animation on fill/focus field
      background-color: $bgColorDark !important;
    }
  }

  &-dot-icon {
    width: 22px;
    height: 22px;
  }

  &-item {
    border-bottom-color: transparent !important;
    border-left: solid transparent 8px;
    background-color: $bgColorDark;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.ant-collapse-item-active {
      border-left: solid $primaryColor 8px;
    }

    &.ant-collapse-item-active .ant-collapse-header { // to not allow squash all accordions by header click on opened
      pointer-events: none;
    }

    .ant-collapse-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $TM2White !important;
      font-size: 16px;
    }

    .ant-collapse-content {
      background-color: transparent;

      .ant-collapse-content-box:empty {
        display: none;
      }
    }
  }
}

.tm2-light-theme .tm2-radio-box {
  .tm2-form-label {
    &:after { // fix for label line to hide field border during animation on fill/focus field
      background-color: $bgColorLight !important;
    }
  }

  &-item {
    background-color: $bgColorLight;

    .ant-collapse-header {
      color: $textColorDark !important;
    }

    .ant-collapse-content {
      .txt-sm {
        color: $textColorDark;
      }
    }
  }
}
