@import 'theme/variables';

.container {
  :global(.ant-select-selection-item) {
    background-color: transparent !important;
  }

  :global(.ant-select-selection-item-remove) {
    color: var(--text-color-primary) !important;
    display: flex;
    align-items: center;
  }

  :global(.ant-select-selection-overflow-item) {
    margin-top: 4px !important;
  }
}

.col {
  display: flex;
}
