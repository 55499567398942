@import 'variables';

.deleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $height-base;
  height: $height-base;
  min-width: $height-base;
  border: solid $formBorderColor $separatorBorderWidth;
  border-radius: 2px;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
  cursor: pointer;
  transition: border-color $transitionDuration, opacity $transitionDuration;

  &:hover {
    border-color: $primaryColor;

    .icon {
      stroke: $primaryColor;
    }
  }

  &:disabled {
    opacity: $formFieldDisabledOpacity;
    pointer-events: none;
  }
}

.icon {
  stroke: $formBorderColor;
  transition: stroke $transitionDuration;
}
