@import 'variables';
@import 'mixins';
@import '../style.variables';

.title {
  position: relative;
  width: 100%;
  height: $headerHeight;
  display: flex;
  flex-direction: row;
  border-bottom: solid $modalBorder 1px;
  font-size: 24px;
  line-height: 1.1em;
  color: $TM2Black;

  @include mMaxWidth($xs) {
    height: $headerHeightMobile;
    font-size: 19px;
  };
}

.content {
  width: calc(100% - #{$headerHeight});
  height: $headerHeight;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: $modalPadding;

  @include mMaxWidth($xs) {
    width: calc(100% - #{$headerHeightMobile});
    height: $headerHeightMobile;
    padding-left: $modalPaddingMobile;
  };
}

.close {
  width: $headerHeight;
  height: $headerHeight;
  display: flex;
  cursor: pointer;
  z-index: 10;

  @include mMaxWidth($xs) {
    width: $headerHeightMobile;
    height: $headerHeightMobile;
  };
}

.closeSvg {
  stroke: $TM2Black;
  width: calc(#{$headerHeight} / 4);
  height: calc(#{$headerHeight} / 4);
  margin: auto;

  @include mMaxWidth($xs) {
    width: calc(#{$headerHeightMobile} / 4);
    height: calc(#{$headerHeightMobile} / 4);
  };

  &:hover {
    stroke: $primaryColor;
  }
}

// below is dirty hacks to center tabs in modal title.
// We can't access some elements to style directly
.content {
  & > div,
  & > div > div {
    height: 100%;
  }

  & > div > div {
    display: flex;
  }
}

.tabHeaderWrapper {
  margin: auto 0 !important;

  & > div {
    bottom: -15px;

    @include mMaxWidth($xs) {
      bottom: -3px;
    };
  }
}

.tabHeaderItem {
  font-family: var(--text-h-font-family) !important;
  font-size: var(--text-h2-size) !important;
}
