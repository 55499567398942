@import 'variables';

.button {
  $buttonColor: $TM2Black;

  width: 80px !important;
  border-radius: 25px;
  transform: rotate(180deg);
  border-color: $buttonColor !important;

  & svg {
    fill: $buttonColor !important;
    width: 25px !important;
    transform: translate(-25%);
  }

  &:disabled {
    opacity: 0.4;
  }
}
