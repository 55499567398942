@import '../styles/shared';

.tableWrapper {
  width: 100%;
  background-color: $TM2Charcoal;
}

.tableRoot {
  overflow-x: auto;

  @include mHorizontalScrollbar();
  @include mVerticalScrollbar();

  @include mMinWidth($layout-lg) {
    // Magic to take into account page header and title.
    max-height: calc(100vh - 180px - var(--dynamic-page-top-banner-height));
  }
}

.tableHead {
  // To align with balance pane title.
  height: 61px;
}

.tableHeadCell {
  padding-left: 0 !important;
  text-align: left !important;
}

.tableBodyCell {
  padding-left: 0 !important;
  text-align: left !important;

  &:last-child {
    text-align: right !important;
  }
}

.tableHeadCellMultiRowContent {
  display: inline-flex;
  flex-direction: column;
}

.tableHeadCellSecondaryText {
  color: $TM2Grey1;
}

// Margin arrows so it seems like centered.
.tableHeadCellMultiRow:after {
  margin-top: 6px;
}
