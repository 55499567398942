@import '../../../../shared/styles/variables.antd';
@import '../../mixins';

@table-image-src: "../../../../../img/admin";

.tm2-deprecated-page-styles {
  table:not(.@{deprecatedStyleExclusiveMarker} table) {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 40px;
    th, td {
      vertical-align: middle;
      line-height: normal;
      border: none;
    }
    th {
      padding: 10px;
      font-weight: normal;
      text-align: center;
      position: relative;
    }
    td {
      padding: 8px 10px;
    }
    div[class*="col"] > &:last-child {
      margin-bottom: 0;
    }
  }
  .scroll-block { overflow: auto; }
  .table-wrapper {
    .mHorizontalScrollbar();
    padding: 0 10px 10px 10px;
    background-color: @TM2Charcoal;

    table:not(.@{deprecatedStyleExclusiveMarker} table) {
      margin-bottom: 0;
    }
  }
  .table-max-height {
    .mHorizontalScrollbar();
    .mVerticalScrollbar();
    padding-bottom: 150px; // otherwise infinite scroll will load only first page on large screens
    max-height: 700px;
    overflow: auto;

    @media all and (max-height: 960px) {
      max-height: 600px;
    }
    @media all and (max-height: 860px) {
      max-height: 500px;
    }
    @media all and (max-height: 768px) {
      max-height: 400px;
    }
    @media all and (max-height: 640px) {
      max-height: 300px;
    }
  }

  .default-table {
    > thead {
      > tr {
        position: relative;
        th {
          position: sticky;
          top: 0;
          padding: 15px 10px 11px 10px;
          box-shadow: inset 0 -1px 0 @separatorBorderColor;
          background-color: @TM2Charcoal;
          z-index: 1;
        }
        th + th:before {
          display: block;
          content: '';
          position: absolute;
          width: 1px;
          height: 80%;
          background-color: transparent;
          left: 0;
          top: 10%;
        }
        .icon--settings {
          width: 15px;
          height: 15px;
        }
      }
    }
    > tbody {
      > tr {
        border-left: solid 1px transparent;
        border-right: solid 1px transparent;
        &:last-child > td { border-bottom: 1px solid transparent; }

        td {
          background-color: @TM2Charcoal;
        }

        &:not(:first-child) td {
          border-color: @separatorBorderColor !important;
          transition: background-color @transitionDuration;
        }

        &:hover > td {
          background-color: @TM2Grey;
        }
      }
      > tr:nth-child(2n) {
        .linear-gradient(90deg, rgba(@TM2Grey1Rgb, 0.1), rgba(@TM2BlackRgb, 0.1));
      }
      > tr > td {
        padding: 12px 10px;
        border-top: 1px solid transparent;
        border-bottom: 0;
      }
    }
    th, td {
      .mMediaMaxWidth(@xl, {
        font-size: 12px;
      });
    }
  }

  .table-header-sorter {
    white-space: nowrap;
    cursor: pointer;
    &:after {
      display: inline-block;
      content: '';
      width: 15px;
      height: 15px;
      margin-left: 7px;
      vertical-align: text-top;
      .mBgContain;
      background-image: url("@{table-image-src}/controls/sort-arrows.svg");
      html.no-svg & {
        background-image: url("@{table-image-src}/controls/sort-arrows.png");
      }
      .mMediaMaxWidth(@xl, {
        margin-left: 6px;
      });
    }

    &.head-cell-multi:after {
      margin-top: 6px;
    }

    & .head-cell-multi-text {
      display: inline-flex;
      flex-direction: column;
    }

    &.table-header-sorter--asc:after {
      background-image: url("@{table-image-src}/controls/sort-arrow-up.svg");
      html.no-svg & {
        background-image: url("@{table-image-src}/controls/sort-arrow-up.png");
      }
    }
    &.table-header-sorter--desc:after {
      background-image: url("@{table-image-src}/controls/sort-arrow-down.svg");
      html.no-svg & {
        background-image: url("@{table-image-src}/controls/sort-arrow-down.png");
      }
    }
    &:hover {
      &:after {
        opacity: 0.7;
      }
    }
  }

  .simple-table {
    margin-bottom: 0;
    tr {
      td:first-child {
        padding-left: 0;
      }
      td:last-child {
        padding-right: 0;
        text-align: right;
      }
      td[colspan] {
        text-align: left;
      }
      td {
        padding-bottom: 15px;
        padding-top: 5px;
        vertical-align: top;
      }
      .td-top-border-blue {
        padding-top: 15px;
        border-top: solid 1px @TM2Gold;
      }
      .td-top-border-silver {
        padding-top: 15px;
        border-top: solid 1px @TM2Grey1;
      }
      h3 {
        margin-bottom: -12px;
      }
    }
  }
  .simple-td {
    width: 130px;
    text-align: right;
  }
  .small-table {
    tr {
      td {
        font-size: 12px;
        padding-bottom: 5px;
        padding-left: 0;
      }
    }
  }
  .small-table-2 {
    tr {
      td, th {
        font-size: 11px;
        padding-left: 12px;
        padding-right: 12px;

        .mMediaMaxWidth(@xss, {
          padding-left: 6px;
        });
      }
    }
    thead {
      th {
        padding-top: 12px;
        padding-bottom: 8px;
        color: @TM2Grey1;
      }
    }
    tbody {
      td {
        padding-top: 6px;
        padding-bottom: 6px;
      }
      tr:last-child {
        td {
          padding-bottom: 12px;
        }
      }
    }
  }
  .small-table-3 {
    tr {
      td, th {
        font-size: 10px;
        padding: 3px 0;
        opacity: 0.7;
      }
    }
    tr:hover {
      td {
        opacity: 1;
      }
    }
  }

  .td-controls {
    width: 10px;
    text-align: right;
  }

  .text-columns-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    .mMediaMaxWidth(@lg, {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    });
    p {
      margin: 0.2em 0 0.5em;
    }
  }

  .vertical-table tr td {
    padding: 0;
  }
  .vertical-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .pagination {
    margin: 30px 0;
    list-style: none;
    text-align: center;
    font-size: 0;
    li {
      display: inline-block;
      margin: 0 0 0 -1px;
      font-size: 12px;
      vertical-align: middle;
      a {
        text-decoration: none;
        display: block;
        line-height: 1em;
        padding: 10px 15px;
        border: solid 1px @TM2Grey1;
        color: @TM2Grey1;
        &:hover {
          color: @TM2Gold;
        }
        &.active {
          color: @TM2Gold;
          border-color: @TM2Gold !important;
          font-weight: 700;
        }
      }
      &.disabled {
        a, a:hover {
          pointer-events: none;
          color: @TM2Grey1;
          border-color: @formFieldBorderColor !important;
        }
      }
      &:before {
        display: none;
      }
      &.active + li {
        a {
          border-left-color: @TM2Gold;
          .mMediaMaxWidth(@lg, {
            border-left-color: @TM2Grey1;
          });
        }
      }
      &:first-child, &:nth-child(2) {
        margin-right: 15px;
        a {
          border-radius: 3px;
        }
      }
      &:last-child, &:nth-last-child(2) {
        margin-left: 15px;
        a {
          border-radius: 3px;
        }
      }
      &:nth-child(3) {
        a {
          border-radius: 3px 0 0 3px;
        }
      }
      &:nth-last-child(3) {
        a {
          border-radius: 0 3px 3px 0;
        }
      }
      .mMediaMaxWidth(@lg, {
        margin: 0 10px 10px 0 !important;
      });
    }
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    .pagination {
      li {
        a {
          &:hover {
            border-color: @textColorLight;
          }
        }
      }
    }
  }
}
