@import './variables';

@mixin mHorizontalMobile() {
  @media (max-height: 658px) { // 698px safari/ 659px chrome is maximum height for iPad horizontal
    @content;
  }
}

@mixin mMinWidth($minWidth) {
  @media (min-width: $minWidth) {
    @content;
  }
}

@mixin mMaxWidth($maxWidth) {
  @media (max-width: $maxWidth - 1px) {
    @content;
  }
}

@mixin mRangedWidth($minWidth, $maxWidth) {
  @media (min-width: $minWidth) and (max-width: $maxWidth - 1px) {
    @content;
  }
}

@mixin mHorizontalScrollbar($height: 5px) {
  scrollbar-height: thin !important; // rule for mozilla
  scrollbar-color: $primaryColor $separator-border-color !important; // rule for mozilla

  &::-webkit-scrollbar {
    height: $height !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryColor !important;
  }
}

@mixin mVerticalScrollbar($width: 5px) {
  scrollbar-width: thin !important; // rule for mozilla
  scrollbar-color: $primaryColor $separator-border-color !important; // rule for mozilla

  &::-webkit-scrollbar {
    width: $width !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryColor !important;
  }
}

// below is all logic for auto calc table height
// TODO replace whole logic below when pageContent will be ready to flex-grow: 1
@mixin mTableHeight($withTitle: true, $heightsList...) { // NOTE use this only when page has only table and simple actions row
  $heights: '- var(--dynamic-page-header-height) - var(--dynamic-page-top-banner-height) - var(--dynamic-page-content-top-indent-height) - var(--dynamic-page-content-bottom-indent-height) - var(--dynamic-page-footer-height)';

  @if $withTitle {
    $heights: $heights + ' - var(--experimental-for-table-title-height)';
  }

  @each $height in $heightsList {
    $heights: $heights + ' - #{$height}';
  }
  max-height: calc(100vh #{$heights}) !important;
}

$actionsRowDefaultHeight: var(--ant-field-height-base), calc(var(--spacing-base) * 3), calc(var(--spacing-base) * 3), var(--m-md);
html {
  --experimental-for-table-title-height: 49px;
}

@media (min-width: $xs) and (max-width: $md) {
  html {
    --experimental-for-table-title-height: 30px;
  }
}
@media (max-width: $xs) {
  html {
    --experimental-for-table-title-height: 15px;
  }
}
