@import 'variables';

.title {
  font-size: $fontSizeBig;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summaryLabel {
  width: 80px;
}

.summaryValue {
  width: calc(100% - 80px);
  text-align: right;
}

.dataLabel {
  width: 60%;
}

.dataValue {
  width: 40%;
  text-align: right;
}
