@import 'mixins';

$gridRow: 1070px;
$gridColumn: $md;

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include mMaxWidth($gridColumn) {
    flex-direction: column;
  }
}

.downloadCol {
  display: flex;
  margin-right: 15px;

  @include mMaxWidth($gridColumn) {
    width: 100% !important;
    margin-right: 0;
  }
}

.filtersCol {
  width: calc(100% - 64px - 15px);

  &:first-child:last-child {
    width: 100%;
  }

  @include mMaxWidth($gridColumn) {
    width: 100%;
  }
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include mMaxWidth($gridRow) {
    flex-wrap: wrap;
  }

  @include mMaxWidth($gridColumn) {
    flex-direction: column;
  }
}

.formItem {
  margin: 0 10px;

  &:first-child { // date
    min-width: 262px;
    margin-left: 0;
  }

  &:nth-child(2) { // amount
    min-width: 150px;
  }

  &:last-child { // symbol
    margin-right: 0;
  }

  @include mMaxWidth($gridRow) {
    &:first-child { // date
      min-width: unset;
      width: 100%;
      margin: 0 0 20px 0;
    }

    &:not(:first-child) {
      width: calc(50% - 10px);
      margin: 0;
    }
  }

  @include mMaxWidth($gridColumn) {
    &:nth-child(n) { // all
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
