.alert {
  color: var(--tm2-red);
}

.textSeparator {
  display: block;
  height: 0;
  visibility: hidden;
  content: '';
}
