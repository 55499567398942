@import 'variables';
@import 'mixins';

.portfolio {
  @include mVerticalScrollbar();
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-family: $fontFamilySecondary;
  font-size: 11px;
  overflow: auto;

  @include mMaxWidth($xs) {
    flex-direction: column;
  }

  @include mMaxWidth($sm) {
    height: auto !important;
  }
}

.block {
  width: 50%;

  @include mMaxWidth($sm) {
    @include mVerticalScrollbar();
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;

    &:first-child {
      margin-bottom: 15px;
    }
  }
}

.metalsSection {
  * {
    background-color: $bgColorGrey1;
  }
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
}

.cell {
  width: 50%;
  padding: 3px 0;

  &:last-child {
    text-align: right;
  }
}

.icon {
  width: 12px;
  height: 12px;
  background-size: 12px;
  display: inline-block;
  margin-right: 3px;
}
