@import 'variables';
@import 'mixins';

.product-selector {
  .dropdown-menu {
    cursor: default !important;

    .dropdown-item {
      cursor: default !important;

      .small-table-3 {
        cursor: pointer;
      }
    }
  }

  .dropdown-menu {
    position: relative;
    top: unset;
    left: unset;
    margin-top: 10px;
    border: solid 1px $TM2Gold;
    border-radius: 2px;
    padding: 0 15px;
    z-index: 300;
    min-width: 170px;
    white-space: nowrap;
    text-align: left;

    .dropdown-item {
      position: relative;
      padding: 11px 0;
      cursor: pointer;
      a {
        text-decoration: none;
      }
      .icon {
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
        background-size: 15px 15px;
      }
      .icon + span {
        padding-left: 26px;
        text-align: left;
        white-space: nowrap;
      }
      .form-label {
        margin: 0;
      }
    }
    .dropdown-item:last-child {
      border-bottom: none;
    }
    tr td:first-child & {
      right: auto;
      left: 0;
    }
  }

  .dropdown-menu {
    @include mVerticalScrollbar();
    border: solid $separator-border-color $separatorBorderWidth;
    background: $backingExternalDarkBg;
    max-height: 250px;
    overflow-y: auto;

    .dropdown-item {
      &:not(:last-child) {
        border-bottom: 1px solid $separator-border-color;
      }
      a, button {
        color: $textColorLight;
      }
    }
  }

  .bordered-field {
    .dropdown-menu {
      min-width: 100%;
    }
    .small-table-3 {
      margin-top: 2px;
    }
  }

  .dropdown-block {
    cursor: pointer;
  }
}
