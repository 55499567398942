@import 'theme/variables';

.tab {
  display: block;
  flex-direction: column;
  overflow-wrap: break-word;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divider {
  &:last-child {
    display: none;
  }
}

.item {
  display: block;
  width: auto;

  @media (min-width: $sm) {
    display: inline-block;
    width: 50%;
  }
}