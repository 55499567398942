@import 'variables';
@import 'mixins';

.form {
  max-width: 700px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.actions {
  @include mMaxWidth($md) {
    flex-direction: column;
  }
}

.saveButton {
  margin-left: auto;
  @include mMaxWidth($md) {
    width: 100%;
    margin-top: var(--m-md);
  }
}
