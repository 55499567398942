.pageWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.verticalFiller {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.headerCell {
  padding-top: 0;
  padding-bottom: 14px;
  padding-right: 10px;
}

.actionsTrigger {
  display: block;
  margin-left: 16px;
  padding-left: 7px;
  padding-right: 7px;
}
