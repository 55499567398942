.link {
  cursor: pointer;
}

.linkTitle {
  font-family: var(--text-h-font-family);
  font-size: var(--text-h3-size);
  line-height: var(--text-h3-line-height);
}

.linkText {
  font-family: var(--text-b-font-family);
  font-size: var(--text-b2-size);
  line-height: var(--text-b2-line-height);
}
