@import 'theme/variables';

.container {
  display: flex;
  justify-content: space-between;

  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.element {
  width: calc(50% - (var(--spacing-base) * 2));

  @media (max-width: $sm) {
    width: 100%;
  }
}