@import 'variables';

.wrapper {
  &:not(:last-child):after {
    display: block;
    width: 100%;
    height: 1px;
    margin: 5px 0;
    border-bottom: solid $separator-border-color 1px;
    content: '';
  }
}

.item {
  display: block;
  box-sizing: border-box;
  padding: 0 5px;
  color: $textColorLight;
  font-family: $fontFamilySecondary;
  transition: color $transitionDuration;
  cursor: pointer;

  &:hover {
    color: $primaryColor;
  }
}
