@import 'theme/variables';
@import 'variables';
@import 'mixins';
@import '../style.variables';

.tm2-modal {
  display: flex;
  overflow: hidden;
  z-index: map-get($layers, 'modal');
  --ant-separator-border-color: #{$TM2Grey2};

  .ant-modal-content {
    background-color: $TM2White;
    color: $TM2Black;
  }

  &-big {
    .ant-modal {
      width: calc(100vw - 10px) !important;
      max-width: 800px !important;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      max-height: calc(100vh - #{$phoneBarsHeight});
      padding: 0;
      border: solid $modalBorder 1px;
    }
  }
}

.ant-modal {
  top: unset;
  height: max-content;
  max-width: calc(100% - #{$space});
  max-height: calc(100vh - #{$phoneBarsHeight});
  margin: auto;
  padding-bottom: 0;

  @include mMaxWidth($xs) {
    width: 100%;
    max-width: calc(100% - #{$spaceMobile});
  };
}
