@import 'variables';

.buy-confirm-modal-live-price {
  &-changes {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;

    &.price-changes-up {
      color: $TM2Green;
    }

    &.price-changes-down {
      color: $TM2Red;
    }

    &.price-changes-up &-icon-svg {
      fill: $TM2Green;
    }

    &.price-changes-down &-icon-svg {
      fill: $TM2Red;
      transform: rotateX(180deg);
    }

    &-icon {
      width: 1em;
      height: 1em;
      position: relative;
      margin-left: 5px;

      &-svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
