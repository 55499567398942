/*Tabs elements styles (.tab-links, .tab-link) ...*/
@import "../../../../shared/styles/variables.antd";
@import "../../mixins";

@tab-link-padding-top: 6px;
@tab-link-padding-bottom: 12px;

.tm2-deprecated-page-styles {
  .tab-link {
    display: inline-block;
    position: relative;
    padding: @tab-link-padding-top 0 @tab-link-padding-bottom 0;
    white-space: nowrap;
    margin: 0 40px 25px 0;
    text-decoration: none;

    .mMediaMaxWidth(@xs, {
      margin-right: 10px;
    });

    &:last-child {
      margin-right: 0 !important;
    }

    &:hover {
      color: @TM2Gold;
      text-decoration: none;
    }
    &.active {
      color: @TM2Gold;
      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 5px;
        bottom: 0;
        left: 0;
        background-color: @TM2Gold;
      }
    }
  }
  .styles-for-red-point {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: @TM2Red;
    right: -8px;
    top: 3px;
    border-radius: 100%;
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    .tab-link {
      font-size: 24px;

      .mMediaMaxWidth(@xs, {
        font-size: 22px;
      });

      color: @TM2White;
      &:hover, &.active {
        color: @TM2Gold;
      }
    }
    .gradient-tab-blocks {
      padding: 25px;
      overflow: auto;
      max-height: 650px;
      @media all and (max-height: 768px) {
        max-height: 500px;
      }
      @media all and (max-height: 575px) {
        max-height: 350px;
      }
      .small-table {
        margin-bottom: 20px;
      }
    }
    label.tab-link {
      cursor: pointer;
    }
    .tab-control {
      display: none;
      & + .tab-link {
        color: @TM2White;
        position: relative;
        &::before {
          display: none;
        }
        &_with-warning {
          &::after {
            content: '';
            display: block;
            position: absolute;
            right: -8px;
            top: @tab-link-padding-top;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: @TM2Red;
          }
        }
      }
      & ~ .tab-block {
        display: none;
      }
      &:checked + .tab-link {
        color: @TM2Gold;
        &::before {
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          height: 5px;
          bottom: 0;
          left: 0;
          background-color: @TM2Gold;
        }
      }
    }
    @media (max-width: 360px) {
      .tab-blocks .tab-link {
        margin-right: 25px;
      }
    }
  }
}
