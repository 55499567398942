@import '../../shared/styles/variables.antd';

.mMediaHorizontalMobile(@rules) {
  @media (max-height: 658px) { // 698px safari/ 659px chrome is maximum height for iPad horizontal
    @rules();
  }
}

.mMediaMinWidth(@minWidth; @rules) {
  @media (min-width: @minWidth) {
    @rules();
  }
}

.mMediaMaxWidth(@maxWidth; @rules) {
  @media (max-width: @maxWidth - 1px) {
    @rules();
  }
}

.mMediaRangeWidth(@minWidth; @maxWidth; @rules) {
  @media (min-width: @minWidth) and (max-width: @maxWidth - 1px) {
    @rules();
  }
}

.mHorizontalScrollbar(@height: 5px) {
  scrollbar-height: thin !important; // rule for mozilla
  scrollbar-color: @primary-color @separatorBorderColor !important; // rule for mozilla

  &::-webkit-scrollbar {
    height: @height !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @primary-color !important;
  }
}

.mVerticalScrollbar(@width: 5px) {
  scrollbar-width: thin !important; // rule for mozilla
  scrollbar-color: @primary-color @separatorBorderColor !important; // rule for mozilla

  &::-webkit-scrollbar {
    width: @width !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @primary-color !important;
  }
}

.mBgCover() {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mBgContain() {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

// все, что ниже - нужно перебрать и удалить лишнее
.opacity(@opacity: 0.5) {
  -webkit-opacity: @opacity;
  -moz-opacity: @opacity;
  opacity: @opacity;
}

.rounded-corners(@radius:3px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  -khtml-border-radius: @radius;
  -o-border-radius: @radius;
  border-radius: @radius;
}
.rounded-corners-custom(@topleft: 3px, @topright: 3px, @bottomright: 3px, @bottomleft: 3px) {
  -webkit-border-radius: @topleft @topright @bottomright @bottomleft;
  -moz-border-radius: @topleft @topright @bottomright @bottomleft;
  -khtml-border-radius: @topleft @topright @bottomright @bottomleft;
  -o-border-radius: @topleft @topright @bottomright @bottomleft;
  border-radius: @topleft @topright @bottomright @bottomleft;
}

.box-shadow(@insert, @x: 0, @y: 0, @blur: 10px, @color: @TM2White) when (@insert = true) {
  -webkit-box-shadow: inset @x @y @blur @color;
  box-shadow: inset @x @y @blur @color;
}
.box-shadow(@insert, @x: 0, @y: 0, @blur: 10px, @color: @TM2White) when (@insert = false) {
  -webkit-box-shadow: @x @y @blur @color;
  box-shadow: @x @y @blur @color;
}
.text-shadow(@x: 1px, @y: 1px, @radius: 1px, @color: @TM2Black){
  -webkit-text-shadow: @arguments;
  -moz-text-shadow: @arguments;
  text-shadow: @arguments;
}
.rotate(@deg) {
  -moz-transform:rotate(@deg);
  -webkit-transform:rotate(@deg);
  -o-transform:rotate(@deg);
  -ms-transform:rotate(@deg);
  transform:rotate(@deg);
}
.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.linear-gradient( @dir: top, @start-color: @TM2Grey4, @end-color: @TM2Grey4 ) {
  background: -webkit-linear-gradient(@dir, @start-color 0%, @end-color 100%);
  background: -moz-linear-gradient(@dir, @start-color 0%, @end-color 100%);
  background: -ms-linear-gradient(@dir, @start-color 0%, @end-color 100%);
  background: -o-linear-gradient(@dir, @start-color 0%, @end-color 100%);
  background: linear-gradient(@dir, @start-color 0%, @end-color 100%);
}
