@import 'variables';

$checkboxSize: 22px;

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  :global(input[type=checkbox]),
  :global(.ant-checkbox-inner::before) {
    display: none !important;
  }

  :global(.ant-checkbox) {
    width: $checkboxSize;
    height: $checkboxSize;
  }

  :global(.ant-checkbox-inner) {
    width: 100%;
    height: 100%;
    margin-top: -3px; // hack for strange magic
  }

  :global(.ant-checkbox-inner::after) {
    left: 6px;
    transform: rotate(45deg) scale(1.3) translate(-50%, -50%);
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: var(--tm2-grey1);
    border-color: var(--tm2-grey1);
  }

  :global(.ant-checkbox-checked::after) {
    top: -3px; // hack for strange magic
  }

  & > :global(span) {
    line-height: 1;
  }

  &:global(.ant-checkbox-wrapper-disabled) {
    .label {
      opacity: 0.5;
    }
  }
}

.compact {
  height: max-content;
  margin-bottom: 0 !important;

  :global(.ant-form-item-control-input) {
    min-height: unset;
  }
}

.label {
  color: $textColorDark;
  font-family: var(--text-b-font-family);
  font-size: var(--text-b1-size);
  line-height: var(--text-b2-line-height);
}

.asterisk {
  color: $TM2Red;
}
