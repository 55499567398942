// ========================== paddings ==========================
@mixin mPtXxs() {
  padding-top: var(--p-xxs);
}

@mixin mPtXs() {
  padding-top: var(--p-xs);
}

@mixin mPtSm() {
  padding-top: var(--p-sm);
}

@mixin mPtMd() {
  padding-top: var(--p-md);
}

@mixin mPtLg() {
  padding-top: var(--p-lg);
}

@mixin mPtXl() {
  padding-top: var(--p-xl);
}

@mixin mPbXxs() {
  padding-bottom: var(--p-xxs);
}

@mixin mPbXs() {
  padding-bottom: var(--p-xs);
}

@mixin mPbSm() {
  padding-bottom: var(--p-sm);
}

@mixin mPbMd() {
  padding-bottom: var(--p-md);
}

@mixin mPbLg() {
  padding-bottom: var(--p-lg);
}

@mixin mPbXl() {
  padding-bottom: var(--p-xl);
}

@mixin mPlXxs() {
  padding-left: var(--p-xxs);
}

@mixin mPlXs() {
  padding-left: var(--p-xs);
}

@mixin mPlSm() {
  padding-left: var(--p-sm);
}

@mixin mPlMd() {
  padding-left: var(--p-md);
}

@mixin mPlLg() {
  padding-left: var(--p-lg);
}

@mixin mPlXl() {
  padding-left: var(--p-xl);
}

@mixin mPrXxs() {
  padding-right: var(--p-xxs);
}

@mixin mPrXs() {
  padding-right: var(--p-xs);
}

@mixin mPrSm() {
  padding-right: var(--p-sm);
}

@mixin mPrMd() {
  padding-right: var(--p-md);
}

@mixin mPrLg() {
  padding-right: var(--p-lg);
}

@mixin mPrXl() {
  padding-right: var(--p-xl);
}
// ========================== \paddings ==========================

// ========================== margins ==========================
@mixin mMtXxs() {
  margin-top: var(--m-xxs);
}

@mixin mMtXs() {
  margin-top: var(--m-xs);
}

@mixin mMtSm() {
  margin-top: var(--m-sm);
}

@mixin mMtMd() {
  margin-top: var(--m-md);
}

@mixin mMtLg() {
  margin-top: var(--m-lg);
}

@mixin mMtXl() {
  margin-top: var(--m-xl);
}

@mixin mMbXxs() {
  margin-bottom: var(--m-xxs);
}

@mixin mMbXs() {
  margin-bottom: var(--m-xs);
}

@mixin mMbSm() {
  margin-bottom: var(--m-sm);
}

@mixin mMbMd() {
  margin-bottom: var(--m-md);
}

@mixin mMbLg() {
  margin-bottom: var(--m-lg);
}

@mixin mMbXl() {
  margin-bottom: var(--m-xl);
}

@mixin mMlXxs() {
  margin-left: var(--m-xxs);
}

@mixin mMlXs() {
  margin-left: var(--m-xs);
}

@mixin mMlSm() {
  margin-left: var(--m-sm);
}

@mixin mMlMd() {
  margin-left: var(--m-md);
}

@mixin mMlLg() {
  margin-left: var(--m-lg);
}

@mixin mMlXl() {
  margin-left: var(--m-xl);
}

@mixin mMrXxs() {
  margin-right: var(--m-xxs);
}

@mixin mMrXs() {
  margin-right: var(--m-xs);
}

@mixin mMrSm() {
  margin-right: var(--m-sm);
}

@mixin mMrMd() {
  margin-right: var(--m-md);
}

@mixin mMrLg() {
  margin-right: var(--m-lg);
}

@mixin mMrXl() {
  margin-right: var(--m-xl);
}
// ========================== \margins ==========================
