@import 'variables';

.tm2-field-file {
  &-label {
    width: 100%;
    height: $height-base;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    padding: 0 11px;
    border: solid $TM2Grey2 1px;
    background-color: transparent !important;
    cursor: pointer;
    transition: border-color $transitionDuration;

    &.tm2-field-file-disabled {
      opacity: $formFieldDisabledOpacity;
      cursor: not-allowed;
    }

    &-placeholder {
      width: calc(100% - 1em);
      padding-right: 5px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      transition: color $transitionDuration;

      &:not(.tm2-field-file-label-placeholder-selected) {
        color: var(--tm2-grey1) !important;
      }
    }

    &-icon {
      position: relative;
      width: 1em;
      height: 1em;

      &-svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        stroke: $textColorLight;
      }
    }
  }
}

.tm2-light-theme .tm2-form-field {
  & .tm2-field-file {
    &-label {
      &-placeholder {
        color: $textColorDark;
      }

      &-icon {
        &-svg {
          stroke: $textColorDark;
        }
      }
    }
  }
}
