@import 'variables';
@import 'mixins';

.rowDirection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.directionWay {
  width: calc(50% - 40px);

  @include mMaxWidth($xs) {
    width: calc(50% - 25px);
  }
}

.wayGradient {
  border-radius: $radiusBase;
  padding: 10px !important;
  display: flex;
  justify-content: center;
  text-align: center;
}

.directionSwitch {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mMaxWidth($xs) {
    width: 50px;
  }
}

.switchButton {
  width: 40px !important;
  height: 40px !important;
  background-color: $primaryColor !important;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.formField {
  width: calc(50% - 70px - 30px);

  @include mMaxWidth($xs) {
    width: calc(50% - 58px - 10px);
  }
}

.fieldNone {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $height-base;
  border: solid $formBorderColor 1px;
  padding-left: 11px;
}

.noneInfo {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.formButton {
  width: 140px;

  @include mMaxWidth($xs) {
    width: 116px;
    :global(.tm2-button-preloader) {
      display: none;
    }
  }
}

.rowTotal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.totalText {
  width: 50%;
}

.totalValue {
  width: 50%;
  text-align: right;
}
