@import "../../../shared/styles/variables.antd";
@import "../mixins";

.tm2-deprecated-page-styles {
  .dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  &.tm-dark-theme {

    .form-narrow-col {
      max-width: 60%;
      .light-gradient-block {
        padding-right: 0;
      }
      .mMediaMaxWidth(@xl, {
        max-width: 70%;
      });
      .mMediaMaxWidth(@lg, {
        max-width: 100%;
      });
    }
  }
}
