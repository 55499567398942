.table {
  :global(td) {
    background-color: transparent !important;
  }
}

.verticalFiller {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}