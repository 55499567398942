@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

.tm2-deprecated-page-styles {
  .file-box {
    position: relative;
    input {
      visibility: hidden !important;
      width: 0 !important;
      height: 0 !important;
      position: absolute;
    }
    .link {
      display: inline-block;
      position: relative;
      font-weight: 400;
      text-decoration: none;
      background: none;
      padding-right: 15px;
      cursor: pointer;
    }
    label > span {
      display: inline-block;
      margin-top: 3px;
      margin-bottom: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      direction: rtl;
      vertical-align: middle;
      opacity: 0.8;
      position: relative;
      padding-left: 8px;
      .mMediaMaxWidth(@sm, {
        max-width: 80px;
      });
    }
    label {
      cursor: pointer;
      margin-bottom: 0;
    }
    .field-label.label-small + & {
      margin-top: 10px;
    }
    img {
      max-height: 30px;
      max-width: 120px;
    }
  }

  .upload-previews__item {
    display: inline-block;
    padding: 0 20px 0 20px;
    max-width: 200px;
    position: relative;
    vertical-align: top;
    text-align: center;
    .icon {
      width: 30px;
      height: 30px;
    }
  }
  .preview-item-title {
    margin-top: 20px;
    direction: rtl;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .preview-item-default {
    padding: 30px;
    border-radius: 4px;
    border: solid 1px @TM2Grey1;
  }

  .row-file-field {
    margin-top: 10px;
    .col-auto {
      padding-right: 0;
    }
    td & {
      margin-top: 0;
    }
  }
  .col-auto-dm-upload {
    position:relative;
    min-height:1px;
    padding: 10px;
    width: 100%;
  }
  .download-vm-s1 {
    padding: 0 20px 0 0;
    vertical-align: middle;
  }
  .download-vm-s2 {
    top: 4px;
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    .file-box {
      .link {
        border-color: @TM2Grey1;
        color: @TM2Grey1;
        &:hover, &:active, &:focus {
          border-color: @textColorLight;
          color: @textColorLight;
        }
      }
    }
  }
}
