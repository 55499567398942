@import 'theme/variables';

.actionsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: calc(var(--spacing-base) * 3);
  }

  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.noBanksText {
  @media (max-width: $sm) {
    margin-bottom: calc(var(--spacing-base) * 3);
  }
}

.addButton {
  &:first-child:last-child {
    margin-left: auto;
  }

  @media (max-width: $sm) {
    width: 100%;
  }
}
