@import 'theme/variables';

.button {
  display: none;
}

.desktop {
  @media (min-width: $sm + 1) {
    display: flex;
  }
}

.mobile {
  @media (max-width: $sm) {
    display: flex;
  }
}

.all {
  display: flex;
}
