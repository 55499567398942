@import 'variables';

.tooltip {
  max-width: 155px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.button {
  text-decoration: underline;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none !important;
}

.id {
  display: block;
  overflow: hidden;
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
