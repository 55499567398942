@import 'variables';
@import 'mixins';

.layout {
  position: fixed; // fix for mobile devices to remove white space bottom block after scroll
  width: 100%;
  height: 100%;
  font-family: $font-family;
  color: $textColorLight;
}

.stretchVertically {
  display: flex;
  flex-direction: column;
}
