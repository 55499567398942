.b1 {
  font-family: var(--text-b-font-family);
  font-size: var(--text-b1-size);
  line-height: var(--text-b1-line-height);
}

.b2 {
  font-family: var(--text-b-font-family);
  font-size: var(--text-b2-size);
  line-height: var(--text-b2-line-height);
}

.colorPrimary {
  color: var(--text-color-primary);
}

.colorSecondary  {
  color: var(--text-color-secondary);
}
