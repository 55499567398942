@import "../../../shared/styles/variables.antd";
@import "../mixins";

@tmp-markup-image-src: "../../../img/admin";

// тут только стили нужные для test.js

/* ---------TM2 Dark Layout---------- */
.tm2-deprecated-page-styles.tm-dark-theme {
  .filter-block__search {
    display: none;
  }
  .search-line {
    position: relative;
  }
  .search-line__top {
    position: relative;
    height: @formFieldHeightOld;
    border: solid 1px;
    border-radius: 2px;
    border-image-source: linear-gradient(90deg, @TM2Gold, @TM2Gold);
    border-image-slice: 1;
    .rounded-corners(2px);
  }
  .search-line__top-text {
    display: block;
    position: absolute;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 80px 0 15px;
  }
  .search-line__top-submit {
    width: 17px;
    right: 20px;
    opacity: 0.8;
    background-image: url("@{tmp-markup-image-src}/controls/search.svg");
    html.no-svg & {
      background-image: url("@{tmp-markup-image-src}/controls/search.png");
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .search-line__top-close {
    width: 0;
    overflow: hidden;
    right: 20px;
    padding: 0;
    font-style: normal;
    font-size: 32px;
    font-family: @fontFamilySecondary;
    opacity: 0;
  }
  .search-line__dropdown {
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 200%;
    left: 0;
    height: 0;
    overflow: hidden;
    z-index: -100;
  }
  .search-line__dropdown-content {
    margin-top: -1px;
    padding: 20px 20px 0;
    border: solid 1px;
    border-image-source: linear-gradient(90deg, @TM2Gold, @TM2Gold);
    border-image-slice: 1;

    .linear-gradient(90deg, @TM2Grey3, @TM2Charcoal);
  }
  .search-line__dropdown {
    .transition(0.3s);
    transition-property: top, opacity, height;
  }
  .search-line__top-submit, .search-line__top-close {
    .transition(0.3s);
    transition-property: right, opacity, width;
  }

  .search-line--active {
    .search-line__top {
      border-radius: 0;
    }
    .search-line__top-submit {
      right: 50px;
    }
    .search-line__top-close {
      opacity: 0.65;
      width: 17px;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    .search-line__dropdown {
      top: 100%;
      height: auto;
      opacity: 1;
      z-index: 2;
    }
  }
}
