@import 'variables';

.tm2-field-radio-single {
  $radioSize: 22px;

  &-item {
    &-label {
      color: $textColorGrey1;
      font-family: var(--text-b-font-family);
    }
  }

  &-no-label &-item-label {
    display: none;
  }

  &-no-label &-item {
    margin-bottom: calc(var(--spacing-base) * 2);
  }
}

.tm2-light-theme .tm2-form-field {
  & .tm2-field-radio-single {
    &-item {
      &-label {
        color: $textColorDark;
      }
    }
  }
}
