@import 'variables';

.header {
  padding: 0 $pagePdHor !important;
  border-bottom: solid $separator-border-color $separatorBorderWidth;
  font-family: $font-family;
  line-height: normal !important;
}

.headerInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
