.loaderContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.loaderBackdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: .8;

  // TODO: make configurable
  background-color: var(--bg2);
}

.loader {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
