@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

@image-src: "../../../../../../../img/admin";

.tm2-deprecated-page-styles {
  .radio-label {
    cursor: pointer;

    &-text {
      color: @TM2Grey1;
    }
  }

  input[type=radio]:checked + .radio-label-text {
    color: @TM2Gold !important;
  }

  // --------------- styles below is not checked for usage ---------------

  label {
    input[type=radio] {
      position: absolute;
      top: 5px;
      left: 5px;
      .opacity(0);
      z-index: 1;
      &:disabled + span {
        &:before {
          opacity: 0.5;
        }
        i {
          color: @TM2Grey1 !important;
        }
      }
    }

    input[type=radio] + span:before,
    input[type=radio] + input + span:before,
    input[type=radio] + label + span:before {
      display: inline-block;
      position: relative;
      content: '';
      width: 20px;
      height: 20px;
      text-align: left;
      vertical-align: middle;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 20px 40px;
      z-index: 2;
      float: left;
    }
  }

  input[type=radio] + span:before,
  input[type=radio] + input + span:before,
  input[type=radio] + label + span:before {
    background-image: url("@{image-src}/controls/form-radio.svg");
    html.no-svg & {
      background-image: url("@{image-src}/controls/form-radio.png");
    }
  }

  input[type=radio]:checked + span:before,
  input[type=radio]:checked + input + span:before,
  input[type=radio]:checked + label + span:before {
    background-position: bottom center;
    opacity: 1;
  }
}
