@import 'variables';
@import 'mixins';
@import '../../style.variables';

.icon {
  width: $headerHeight;
  height: $headerHeight;
  display: flex;
  background-color: $TM2Black;
  color: $TM2White;

  @include mMaxWidth($xs) {
    width: $headerHeightMobile;
    height: $headerHeightMobile;
  };
}

.svg {
  display: block;
  margin: auto;
}
