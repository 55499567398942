@import 'variables';

.bar {
  width: 100%;
  height: 24px;
  background-color: var(--bg3);
}

.progress {
  height: 100%;
  background-color: var(--palette-color-primary);
  padding-left: calc(var(--spacing-base) * 2);
  font-size: var(--text-b2-size);
  font-family: var(--text-b-font-family);
  line-height: 24px;
  color: var(--contrast-text-color-primary);
  transition: width $transitionDuration;
}

.alert {
  color: var(--tm2-red);
}

.verifyButton {
  margin-top: calc(var(--spacing-base) * 2);

  &:empty {
    display: none;
  }
}
