@import 'variables';

.market-page {
  .inline-circle {
    display: inline-block;
    border-radius: 50%;
    background-color: $TM2Gold;
  }

  .circle-size-5 {
    width: 5px;
    height: 5px;
  }

  .buy-metals-chart {
    z-index: 10;
    margin-top: 5px;
  }

  .bm-service-header-f {
    padding-bottom: 4px;
  }

  .bm-service-point-f {
    padding: 2px 5px 2px 0;
  }

  .bm-service-point-f-td {
    text-align: right;
    padding: 2px 0 2px 5px;
  }

  .gradient-border-block {
    position: relative;
    margin-bottom: 0 !important;
    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
    }
    h3 {
      font-size: 19px;
      margin-bottom: 0;
      line-height: 1em;
    }
    .tab-link {
      margin-bottom: 0;
      font-size: 19px;
      line-height: 1.1em;
      padding-top: 0;
      padding-bottom: 11px;
      &:before {
        height: 3px;
        z-index: 2;
      }
    }
  }

  .table-tr-hover-gradient {
    tr:hover {
      td {
        background-color: $bgColorGrey1;
        color: $TM2Gold;
      }
    }
  }

  .table-tr-hover-show-hidden {
    tr:hover {
      cursor: pointer;
    }
  }

  /* But metals - product chart */
  .chart-filtr {
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: solid 1px $separator-border-color;
  }
  .chart-filtr__title {
    display: inline-block;
    margin: 0 15px 5px 0;
  }

  .chart-wrapper {
    position: relative;
  }

  .chart-container {
    width: 100%;
  }

  .col-graph-absolute  {
    min-height: 120px;
    .chart-filtr {
      position: absolute;
      top: 0;
      width: 95%;
      font-size: 12px;
    }
    .form-label {
      margin-bottom: 0;
    }
    .chart-wrapper {
      position: absolute;
      width: 100%;
      height: 95%;
      top: 0;
      left: 0;
    }
    .chart-filtr + .chart-wrapper {
      padding-top: 50px;
    }
    .chart-container {
      height: 100%;
    }
  }

  .content-row {
    margin: 0 -7px;
    > div[class*="col"] {
      padding: 0 7px 15px;
      .light-gradient-block:last-child {
        margin-bottom: 0;
      }
    }
    .light-gradient-block {
      padding: 12px 15px;
      margin-bottom: 15px;
    }
    .scroll-block & {
      margin: 0;
    }
    > .col-12:last-child {
      padding-bottom: 0;
    }
    .big-text {
      line-height: 1.2;
    }
  }

  .color-link {
    color: $TM2Gold !important;
  }

  .small-table-2 {
    tbody {
      tr:last-child {
        td {
          padding-bottom: 6px;
        }
      }
    }
  }
}
