.title {
  display: block;
  padding: 0;
  margin-bottom: 24px;
  font-family: var(--text-h-font-family);
  font-size: var(--text-h2-size);
  line-height: var(--text-h2-line-height);
}

.link {
  padding-right: 24px;
  text-decoration: none !important;
}

.linkArrow {
  fill: var(--palette-color-primary) !important;
  transform: rotate(180deg);
}
