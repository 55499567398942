@import 'variables';

.wallet-currency-exchange {
  &-submit-button {
    width: 100%
  }

  &-currency-setting {
    &-input-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      & > * {
        width: calc(50% - 10px);
        min-height: 50px;
      }
    }

    &-exchange-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    &-exchange-rate-error {
      color: $TM2Red;
    }
  }

  &-confirmation {
    &-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      line-height: normal;
    }
  }

  &-delimiter {
    border-top-color: $TM2Grey3;
  }
}
