@import 'background';
@import 'spacing';
@import 'variables';

.theme {
  // Palette
  --palette-color-primary: #{$tm2-gold};

  // Background
  --bg1: #{$tm2-black};
  --bg2: #{$tm2-charcoal};
  --bg3: #{$tm2-grey6};

  // Text
  --text-h-font-family: Sequel, sans-serif;
  --text-b-font-family: Arial, sans-serif;
  --text-h1-size: 20px;
  --text-h1-line-height: 28px;
  --text-h2-size: 16px;
  --text-h2-line-height: 22px;
  --text-h3-size: 16px;
  --text-h3-line-height: 22px;
  --text-h4-size: 14px;
  --text-h4-line-height: 19px;
  --text-b1-size: 16px;
  --text-b1-line-height: 28px;
  --text-b2-size: 14px;
  --text-b2-line-height: 21px;

  --text-color-primary: #{$tm2-white};
  --text-color-secondary: #{$tm2-grey1};

  --link-color: #{$tm2-gold};
  --link-color-disabled: #{$tm2-grey1};

  // Border
  --border-width: 1px;
  --border-color: #{$tm2-grey5};

  // Divider
  --divider-width: 1px;
  --divider-color: #{$tm2-grey5};

  // Tabs
  --tabs-font-family: var(--text-h-font-family);
  --tabs-text-size: var(--text-h2-size);
  --tabs-border-width: var(--border-width);
  --tabs-border-width-selected: 3px;

  --tabs-text-color-base: #{$tm2-grey1};
  --tabs-text-color-selected: var(--text-color-primary);
  --tabs-border-color: var(--border-color);
  --tabs-border-color-selected: var(--palette-color-primary);

  // Popover/tooltip
  --popover-border-color: #{$tm2-grey1};

  // Spacing
  --spacing-base: 4px;

  // Switch
  --switch-color: var(--tm2-white);
  --switch-color-checked: var(--palette-color-primary);

  // Contrast
  --contrast-bg1: #{$tm2-white};
  --contrast-bg2: #{$tm2-grey7};
  --contrast-bg3: #{$tm2-grey4};

  --contrast-text-color-primary: #{$tm2-black};
  --contrast-text-color-secondary: #{$tm2-grey5};

  --contrast-link-color: #{$tm2-blue};

  --contrast-border-color: #{$tm2-grey1};
  --contrast-divider-color: #{$tm2-grey3};
  --contrast-switch-color: #{$tm2-grey5};

  --desktop-text-h1-size: 24px;
  --desktop-text-h1-line-height: 33px;
  --desktop-text-h2-size: 20px;
  --desktop-text-h2-line-height: 28px;
  --desktop-text-h3-size: 16px;
  --desktop-text-h3-line-height: 22px;
  --desktop-text-h4-size: 14px;
  --desktop-text-h4-line-height: 19px;

  --desktop-spacing-base: 8px;

  @media (min-width: $sm) {
    --text-h1-size: var(--desktop-text-h1-size);
    --text-h1-line-height: var(--desktop-text-h1-line-height);
    --text-h2-size: var(--desktop-text-h2-size);
    --text-h2-line-height: var(--desktop-text-h2-line-height);
    --text-h3-size: var(--desktop-text-h3-size);
    --text-h3-line-height: var(--desktop-text-h3-line-height);
    --text-h4-size: var(--desktop-text-h4-size);

    --spacing-base: var(--desktop-spacing-base);
  };

  // TODO: remove dynamic keys after refactoring layout
  // Following keys are dynamic and NOT supposed to be defined
  // along usual theme variables. These keys are necessary to compute
  // certain dynamic elements' positions across js/css code. To understand
  // how these vars used see @modules/layout.
  --dynamic-page-header-height: 45px;
  --dynamic-page-top-banner-height: 0px;
  --dynamic-page-footer-height: 0px;

  // Page content indentations are always present on page (since they are
  // parts of DarkLayout/LightLayout), thus, we inherit their values directly.
  --dynamic-page-content-top-indent-height: var(--pd-page-ver);
  --dynamic-page-content-bottom-indent-height: var(--pd-page-ver);
}

.themeContrast {
  --bg1: var(--contrast-bg1);
  --bg2: var(--contrast-bg2);
  --bg3: var(--contrast-bg3);

  --text-color-primary: var(--contrast-text-color-primary);
  --text-color-secondary: var(--contrast-text-color-secondary);

  --link-color: var(--contrast-link-color);

  --border-color: var(--contrast-border-color);
  --divider-color: var(--contrast-divider-color);
  --switch-color: var(--contrast-switch-color);
}

.themeContainer {
  height: 100%;
  width: 100%;
}