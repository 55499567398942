@use 'theme/variables' as theme;

.linkText {
  font-size: var(--text-b2-size);
  line-height: var(--text-b2-line-height);
}

.linkDecoration {
  color: var(--link-color);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.linkDisabled {
  color: var(--link-color-disabled);

  &:hover {
    text-decoration: underline;
  }
}
