@import 'variables';

.tm2-tooltip {
  &-overlay {
    border: 1px solid $separator-border-color;
    padding-top: 0 !important;
    background-color: $backingExternalDarkBg;
    z-index: 9999;
  }
}
