@import 'mixins';
@import 'theme/variables';

.helperHeader {
  display: flex;
  justify-content: space-between;
}

.helperFooter {
  display: flex;
  justify-content: space-between;

  @include mMaxWidth($lg) {
    flex-direction: column;
  }
}