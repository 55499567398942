@import 'variables';
@import 'mixins';
@import '../styles.mixins';

.buy-confirm-modal {
  @include mBuyMetalModal();

  &-row-description-field {
    min-height: unset;
    max-width: 150px;

    @include mMaxWidth($xs) {
      max-width: 100px;
    }

    .tm2-form-field-item {
      margin-bottom: 0 !important;
    }
  }
}
