$tm2-white: #ffffff;
$tm2-black: #1e1e1e;
$tm2-blue: #3d6e8c;
$tm2-charcoal: #2b2b2b;
$tm2-gold: #e0ae6f;
$tm2-grey: #555555;
$tm2-grey1: #848484;
$tm2-grey2: #b2b2b2;
$tm2-grey3: #cecece;
$tm2-grey4: #eaeaea;
$tm2-grey5: #666666;
$tm2-grey6: #373737;
$tm2-grey7: #f4f4f4;
$tm2-red: #eb5657;

$xs: 576px; // use only in rare cases
$sm: 768px;
$md: 992px;
$lg: 1200px;

$layers: (
  modal: 1000,
  table-tooltip: 900,
);
