@import 'variables';

.wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;

  &:not(.disabled):focus-within {
    & > .input {
      border-color: $primaryColor !important;
    }
  }

  &:not(.disabled):hover { // we can't concat hover and focus-within props. Otherwise will be glitches when error appears
    & > .input {
      border-color: $primaryColor;
    }
  }
}

.disabled {
  cursor: not-allowed;

  & > .input {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.input {
  width: calc(100% / 3);
  border: none;
  outline: none;
  background-color: transparent;
  -moz-appearance: textfield;
  transition: border-color $transitionDuration;

  &::placeholder {
    color: var(--tm2-grey2);
  }

  &:first-child {
    padding-left: 0;
  }

  &:not(:first-child) {
    padding-left: 11px;
  }

  &:nth-child(2) {
    border-left: solid $formBorderColor $separatorBorderWidth;
    border-right: solid $formBorderColor $separatorBorderWidth;
  }

  :global(.ant-form-item-has-error) & {
    border-color: $TM2Red !important;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
