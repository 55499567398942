@import 'variables';
@import 'mixins';

$gap: 25px;

.tm2-grid {
  display: grid;
  gap: 0 $gap;
  width: 100%;
}

.tm2-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  margin-left: calc(-#{$gap} / 2);
  margin-right: calc(-#{$gap} / 2);

  @include mMaxWidth($xs) {
    margin-left: -4px;
    margin-right: -4px;
  };

  & > .tm2-col {
    padding-left: calc(#{$gap} / 2);
    padding-right: calc(#{$gap} / 2);

    @include mMaxWidth($xs) {
      padding-left: 4px;
      padding-right: 4px;
    };

    @mixin mCols ($prefix, $columns, $total: $columns) {
      @if ($columns > 0) {
        &.tm2-#{$prefix}-#{$columns} {
          width: calc(100% * (#{$columns} / #{$total}));
        }
        @include mCols($prefix, $columns - 1, $total);
      }
    }

    @media (max-width: $xxs - 1px) {
      @include mCols(xxs, 12);
    }
    @media (min-width: $xxs) and (max-width: $xs - 1px) {
      @include mCols(xs, 12);
    }
    @media (min-width: $xs) and (max-width: $sm - 1px) {
      @include mCols(sm, 12);
    }
    @media (min-width: $sm) and (max-width: $md - 1px) {
      @include mCols(md, 12);
    }
    @media (min-width: $md) and (max-width: $lg - 1px) {
      @include mCols(lg, 12);
    }
    @media (min-width: $lg) and (max-width: $xl - 1px) {
      @include mCols(xl, 12);
    }
    @media (min-width: $xl) {
      @include mCols(xxl, 12);
    }
  }
}
