@import 'variables';
@import 'mixins';

.row {
  display: flex;
  flex-direction: row;

  @include mMaxWidth($md) {
    flex-direction: column;
  }
}

.item {
  width: 100%;
  max-width: 180px;
  margin-right: 20px;

  @include mMaxWidth($md) {
    max-width: unset;
    margin: 0 0 20px 0;
  }
}
