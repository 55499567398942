@import 'theme/variables';

.layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.actions {
  width: 100%;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filter {
  width: 100%;
  max-width: unset;
  margin-bottom: calc(var(--spacing-base) * 4) !important;
  margin-right: unset !important;

  @media (min-width: $sm) {
    width: calc((100% - 170px) / 2);
    max-width: 220px;
    margin-bottom: unset !important;
    margin-right: calc(var(--spacing-base) * 5) !important;
  }
}

.addFunds {
  margin-top: 0;
  margin-left: auto;

  @media (min-width: $sm) {
    margin-top: unset;
  }
}
