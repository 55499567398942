/**
 * all css variables are stored in src/assets/styles/variables.less
 * this need for ant design
 */
// ========================== figma colors ==========================
$deprecatedStyleExclusiveMarker: 'deprecated-style-exclusive-marker';

$TM2Copper: var(--tm2-copper);
$TM2Gold: var(--tm2-gold);
$TM2Gold1: var(--tm2-gold1);
$TM2Gold2: var(--tm2-gold2);
$TM2Gold3: var(--tm2-gold3);

$TM2Turquoise: var(--tm2-turquoise);
$TM2Blue: var(--tm2-blue);
$TM2Blue1: var(--tm2-blue1);
$TM2Blue2: var(--tm2-blue2);

$TM2Yellow: var(--tm2-yellow);
$TM2Green: var(--tm2-green);
$TM2Red: var(--tm2-red);

$TM2Black: var(--tm2-black);
$TM2Charcoal: var(--tm2-charcoal);

$TM2Grey: var(--tm2-grey);
$TM2Grey1: var(--tm2-grey1);
$TM2Grey2: var(--tm2-grey2);
$TM2Grey3: var(--tm2-grey3);
$TM2Grey4: var(--tm2-grey4);
$TM2Grey5: var(--tm2-grey5);
$TM2White: var(--tm2-white);

// $TM2GoldRgb: 224, 174 ,111; // TODO deprecated
// $TM2Grey1Rgb: 132,132,132; // TODO deprecated
// $TM2Grey3Rgb: 206,206,206; // TODO deprecated
// $TM2WhiteRgb: 255,255,255; // TODO deprecated
// $TM2CharcoalRgb: 43,43,43; // TODO deprecated
// $TM2BlackRgb: 30,30,30; // TODO deprecated
// ========================== \figma colors ==========================

// color settings
$primaryColor: $TM2Gold;
$textColorGrey1: $TM2Grey1;
$textColorGrey2: $TM2Grey2;
$textColorLight: $TM2White;
$textColorDark: $TM2Black;
$linkColor: $TM2Blue; // link color for light theme
$bgColorGrey1: $TM2Grey1; // TODO old variable

$height-base: var(--ant-field-height-base); // buttons and form fields

//$defaultBorderWidth: 2px;
//
$radiusBase: 25px;
//
$transitionDuration: 300ms;
//
//// text settings
$font-size-base: var(--ant-font-size-base);
$fontSizeSmall: var(--font-size-small);
$fontSizeBig: var(--font-size-big);
$font-family: var(--ant-font-family);
$fontFamilySecondary: var(--font-family-secondary);
//$text-color: $textColorLight;
//
//// all project authorized borders
$separatorBorderWidth: var(--separator-border-width);
$separator-border-color:var(--ant-separator-border-color);
//
//// divider
//$divider-color: $separatorBorderColor;

// authorized header
//$layout-header-height: var(--ant-layout-header-height);
//$layout-header-background: $TM2Black;
//$layout-header-color: $textColorGrey1;

//// authorized left sidebar
//$menu-bg: $TM2Black;
$menu-inline-submenu-bg: $TM2Black;
$menu-text-color: var(--ant-menu-text-color);
$menu-highlight-color: var(--ant-menu-highlight-color);
//$menu-item-active-bg: $menu-bg;
//$layout-trigger-background: $TM2Black;
//$menu-collapsed-width: 100%;
$sidebarWidth: var(--sidebar-width);
$sidebarCollapsedWidth: var(--sidebar-collapsed-width);
//$menu-item-active-border-width: 10px;

// authorized content
//$layout-body-background: $TM2Black;

// background base for notifications/modals/popups/etc
$backingExternalDarkBg: $TM2Black;
$backingExternalLightBg: $TM2White;

// buttons
$buttonHeightSm: 22px;
$buttonHeightMd: $height-base;
//$btn-border-width: $defaultBorderWidth;
$btn-border-radius-base: $radiusBase;

//// form
$formBorderColor: $TM2Grey2;
$formFieldHeight: $height-base;
$formFieldHeightOld: 40px; // TODO old variable
$formFieldDisabledOpacity: 0.7;
$formFieldTextFont: $font-family;
$formFieldLabelFont: $fontFamilySecondary;

// ============== breakpoints ==================
// note: this variables is used for media queries and can't be set as css variables
$xxs: 360px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$iPad: 769px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;
// ============== \breakpoints ==================

// =============== page content global spacings ===============
$pagePdHor: var(--pd-page-hor);
$pagePdVer: var(--pd-page-ver);

html {
  --pd-page-hor: 32px;
  --pd-page-ver: 32px;
}

@media (min-width: $xs) and (max-width: $md) {
  html {
    --pd-page-hor: 16px;
    --pd-page-ver: 16px;
  }
}
@media (max-width: $xs) {
  html {
    --pd-page-hor: 8px;
    --pd-page-ver: 8px;
  }
}
// =============== \page content global spacings ===============
