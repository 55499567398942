@import 'variables';

.compact {
  width: max-content;
}

.box {
  --form-field-label-stroke: var(--bg2);
  padding: calc(var(--spacing-base) * 2) calc(var(--spacing-base) * 3);
  background-color: var(--bg2);
}
