@import 'variables';
@import './button-default.scss';
@import './button-outline';
@import './button-icon.scss';
@import './button-text';

.tm2-button {
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: none;
  outline: none !important;
  opacity: 1;
  line-height: 1;
  text-transform: uppercase;
  transition: color $transitionDuration,
  background-color $transitionDuration,
  border-color $transitionDuration,
  opacity $transitionDuration;
  cursor: pointer;

  &:before {
    display: none !important;
  }
}
