@import 'variables';

@mixin mDateInput {
  .ant-picker-disabled {
    opacity: $formFieldDisabledOpacity;

    input {
      color: inherit;
    }
  }

  .ant-picker-suffix {
    color: $textColorGrey1 !important;
  }

  .ant-picker-clear {
    color: $textColorGrey1 !important;;
    background-color: $TM2Charcoal;
  }

  .tm2-light-theme & {
    input {
      color: $textColorDark !important;
    }

    .ant-picker-clear {
      background-color: $TM2White;
    }
  }
}

@mixin mDateCalendar() {
  .ant-picker-header button {
    color: $textColorGrey1;

    &:hover {
      filter: brightness(1.4);
    }
  }

  .ant-picker-content tr th {
    color: $textColorDark;
  }

  .ant-picker-cell {
    color: $textColorGrey1;

    &.ant-picker-cell-disabled {
      color: $textColorGrey2;
      opacity: $formFieldDisabledOpacity;

      &:before {
        display: none;
      }
    }
  }
}

@mixin mTimeCalendar() {
  .ant-picker-header-view {
    color: $textColorGrey1;
  }

  .ant-picker-time-panel-cell {
    &-inner {
      color: $textColorGrey1 !important;
    }

    &-selected > * {
      background-color: var(--tm2-gold) !important;
      color: var(--tm2-white) !important;
    }
  }
}
