@use 'theme/variables' as theme;

.centeredWrapper {
  display: flex;
  width: 100%;
  padding: {
    top: 10px;
    bottom: 10px;
  }
}

.centered {
  display: flex;
  justify-content: center;
}

.scrollable {
  // TODO-scrollbar-styling: make global
  $webkit-scrollbar-thickness: 6px;

  scrollbar-width: thin;
  scrollbar-color: theme.$tm2-grey1 theme.$tm2-grey;

  &::-webkit-scrollbar {
    height: $webkit-scrollbar-thickness;
    width: $webkit-scrollbar-thickness;
    background: theme.$tm2-grey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: theme.$tm2-grey1;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
