@import 'variables';

/**
 * $size - button height and width
 * $iconSize - button icon height and width
 * $border - button border width
 */
@mixin mButtonIconSize($size, $iconSize, $border) {
  min-width: $size;
  min-height: $size;
  border-width: $border;

  .tm2-button-icon-container {
    width: $iconSize;
    height: $iconSize;
  }
}

/**
 * $bgColor - button background color
 * $borderColor - button border color
 * $iconColor - icon color
 */
@mixin mButtonIconTheme($bgColor, $borderColor, $iconColor) {
  border-color: $borderColor;
  background-color: $bgColor;

  .tm2-icon-fill {
    fill: $iconColor;
  }

  .tm2-icon-stroke {
    stroke: $iconColor;
  }

  &:hover {
    opacity: 0.8;
  }
}

.tm2-button-icon-bordered,
.tm2-button-icon-pure {
  display: inline-flex !important;
  padding: 0;
  border-radius: 100%;

  .tm2-button-icon-container {
    position: relative;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:disabled {
    opacity: 0.3;
  }
}

.tm2-button-icon-bordered {
  &.tm2-button-middle {
    @include mButtonIconSize($buttonHeightMd, calc(#{$buttonHeightMd} / 2.2), 2px);
  }

  &.tm2-button-small {
    @include mButtonIconSize($buttonHeightSm, calc(#{$buttonHeightSm} / 2.2), 1px);
  }

  &.tm2-button-danger {
    @include mButtonIconTheme(transparent, $TM2Red, $TM2Red);
  }

  &.tm2-button-light {
    @include mButtonIconTheme(transparent, $textColorLight, $textColorLight);
  }
}

.tm2-button-icon-pure {
  &.tm2-button-large {
    @include mButtonIconSize(32px, 32px, 0);
  }

  &.tm2-button-middle {
    @include mButtonIconSize(16px, 16px, 0);
  }

  &.tm2-button-small {
    @include mButtonIconSize(10px, 10px, 0);
  }

  &.tm2-button-danger {
    @include mButtonIconTheme(transparent, $TM2Red, $TM2Red);
  }

  &.tm2-button-light {
    @include mButtonIconTheme(transparent, $textColorLight, $textColorLight);
  }

  &.tm2-button-primary {
    @include mButtonIconTheme(transparent, $TM2Gold, $TM2Gold);
  }
}
