@use 'theme/variables' as theme;

.compactAll {
  :global(.tm2-button-text) {
    display: none;
  }
}

.compactMobile {
  @media (max-width: theme.$sm) {
    :global(.tm2-button-text) {
      display: none;
    }
  }
}
