@import 'variables';

$space: 40px; // free space around modals (value will be divided on 2)
$headerHeight: 80px;
$footerHeight: $headerHeight;
$modalPadding: calc(((#{$headerHeight} - (#{$headerHeight} / 4)) / 2));

$spaceMobile: 10px; // free space around modals (value will be divided on 2)
$headerHeightMobile: 50px;
$footerHeightMobile: 50px;
$modalPaddingMobile: 10px;

$modalBorder: $TM2Grey1;
$phoneBarsHeight: 80px; // fix for small mobile devices. Exclude mobile footer and header bars height
