@import 'theme/variables';

$counterSize: 50px;

.step {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.counter {
  display: flex;
  width: $counterSize;
  height: $counterSize;
  min-width: $counterSize;
  margin-right: calc(var(--spacing-base) * 2);
  box-sizing: border-box;
  background-color: var(--tm2-grey3);
  color: var(--tm2-white);
  font-size: 35px;
  line-height: 35px;
}

.description {
  width: calc(100% - #{$counterSize} - (var(--spacing-base) * 2));
  text-align: left;
}

.icon {
  margin: auto;
}
