@import 'theme/variables';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $sm) {
    width: 60%;
  };
}

.textarea {
  min-height: 100px;
  border: var(--border-color) solid var(--border-width);
  padding: calc(var(--spacing-base) * 2);
  background: var(--bg2);

  &:focus {
    outline: 0;
  }
}

.infoFile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoFiles {
  display: flex;
  flex-direction: column;
}

.infoFileLabel {
  font-size: var(--text-b2-size);
  opacity: .6;
  cursor: pointer;
  margin-bottom: 0;
  padding-right: 40px;
}

.uploadButtonContainer {
  display: flex;
  align-items: center;
}

.removeButton {
  align-self: flex-start;
}

.errorContainer {
  position: relative;
}

.error {
  position: absolute;
  color: $tm2-red;
}
