@import 'variables';
@import 'mixins';

.tm2-authorized-sidebar {
  $iconSize: 16px;
  $borderSize: 4px;

  height: 100vh;
  padding-right: 1px;
  border-right: solid $separator-border-color $separatorBorderWidth;
  overflow-y: auto;
  font-family: $font-family;
  transition: all $transitionDuration linear;
  @include mVerticalScrollbar(2px);

  &-logo {
    $antPadding: 24px; // 24px is default non change ant padding for menu item
    width: 95px + $antPadding;
    height: 95px + $antPadding;
    padding: $antPadding;
    transition: all $transitionDuration linear;

    & > svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &.ant-layout-sider {
    &-collapsed {
      .ant-menu-item {
        border-right: none !important;
      }
      .ant-menu-item-selected {
        padding-left: 7.5px;
        border-right: none !important;
      }
    }
  }
  .ant-layout-sider-trigger {
    border-right: solid $separator-border-color $separatorBorderWidth;
    border-top: solid $separator-border-color $separatorBorderWidth;
    transition: all $transitionDuration linear;
  }

  &-menu {
    border-right: none;

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-left: $borderSize solid transparent;

      &::after {
        border-right: none !important;
      }

      .ant-menu-item-icon {
        position: relative;
        width: $iconSize;
        height: $iconSize;
        margin-left: -$borderSize !important;

        & > svg {
          position: absolute;
          fill: $menu-text-color;
          transition: fill $transitionDuration;
        }
      }

      .ant-menu-title-content {
        overflow: visible !important;
      }

      &.ant-menu-item-selected .ant-menu-item-icon > svg,
      &:hover .ant-menu-item-icon > svg {
        fill: $menu-highlight-color;
      }

      &.ant-menu-item-selected {
        border-left: $borderSize solid $TM2Gold;
      }
    }

    &-submenu {
      .ant-menu-submenu-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-left: $borderSize solid transparent;
        color: $menu-text-color;

        .ant-menu-item-icon {
          position: relative;
          width: $iconSize;
          height: $iconSize;
          margin-left: -$borderSize;

          & > svg {
            position: absolute;
            fill: $menu-text-color;
            transition: fill $transitionDuration;
          }
        }
      }

      &.ant-menu-submenu-active {
        .ant-menu-submenu-title {
          color: $menu-highlight-color;

          .ant-menu-item-icon > svg {
            fill: $menu-highlight-color;
          }
        }
      }

      &-item {
        border-left: $borderSize solid transparent;
        transition: color $transitionDuration, border-left $transitionDuration;

        &::after {
          border-right: none !important;
        }

        &.ant-menu-item-selected {
          border-left: $borderSize solid $TM2Gold;
        }

        &.ant-menu-item-blank {
          border-left: 4px solid transparent !important;
          color: $TM2Grey1;
          &:hover {
            color: $TM2White;
          }
        }
      }
    }
  }

  &.ant-layout-sider-collapsed {
    .tm2-authorized-sidebar {
      &-logo {
        width: $sidebarCollapsedWidth;
        height: $sidebarCollapsedWidth;
        padding: 10px;
      }

      &-menu {
        &-item {
          padding: 0 11.5px !important;
        }

        &-submenu {
          .ant-menu-submenu-title {
            padding: 0 11.5px !important;
            border-left: 0 solid transparent !important;
          }
        }
      }
    }
  }
}

.ant-menu-submenu-popup { // we can't encapsulate this styles due to collapsed menu subitems is outside menu element
   .ant-menu-sub {
     border: solid $separator-border-color $separatorBorderWidth;
     background-color: $menu-inline-submenu-bg !important;
   }

  .tm2-authorized-sidebar-menu-submenu-item {
    padding: 0 16px !important;
  }
}

// this styles is need for sidebar first level single menu item, when menu is collapsed
.ant-menu-inline-collapsed-tooltip {
  left: 34px !important;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    min-width: 160px;
    background-color: $TM2Black;
    padding: 12px 16px;
    border: solid $separator-border-color 1px;
    margin-right: -5px;

    .tm2-authorized-sidebar-menu-item {
      &-text {
        color: $menu-text-color !important;
      }

      &-text:hover {
        color: $TM2White !important;
      }
    }

    &:before {
      content: none !important;
    }

    .ant-menu-item-icon {
      display: none;
    }
  }
}
