@import 'variables';
@import 'theme/variables';
@import '../date-picker.mixin';

.tm2-field-date {
  @include mDateInput();
}

// эта часть стилей ниже живет в отдельном DOM узле
.tm2-field-picker {
  .ant-picker-footer {
    .ant-picker-now-btn {
      color: var(--tm2-gold) !important;
    }

    .ant-picker-ok {
      .ant-btn-primary {
        padding: 0 15px;
        border-radius: 15px;
        border: none !important;
        background-color: var(--tm2-gold);
        color: var(--tm2-black);
        text-transform: uppercase;
        font-size: 12px;
        line-height: 12px;

        &:disabled {
          opacity: 0.3;
        }
      }
    }
  }

  &#{&}-no-footer {
    .ant-picker-footer {
       display: none;
    }
  }
}

.tm2-field-date-dropdown,
.tm2-field-date-time-dropdown {
  @include mDateCalendar();
}

.tm2-field-date-time-dropdown {
  @include mTimeCalendar();

  .ant-picker-time-panel-column {
    // TODO-scrollbar-styling: make global
    $webkit-scrollbar-thickness: 6px;

    scrollbar-width: thin;
    scrollbar-color: $tm2-grey1 $tm2-grey4;

    &::-webkit-scrollbar {
      height: $webkit-scrollbar-thickness;
      width: $webkit-scrollbar-thickness;
      background: $tm2-grey4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $tm2-grey1;
    }
  }
}
