/* dashboard/admin-dashboard */
@import "../../../shared/styles/variables.antd";
@import "../mixins";

@delete-image-src: "../../../img/admin";

.tm2-deprecated-page-styles {
  .flex-menu-col-12 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
  }
  .flex-menu-col-12 > li:before {
    display: none;
  }
  .flex-menu-col-12 > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16.66%;
    border-radius: 2px;
    padding: 12px 0;
    height: 54px;
    margin: 0;
  }
  .flex-menu-col-12 > li input[type=radio] {
    display: none;
  }
  .flex-menu-col-12 > li input[type=radio]:checked + label {
    background: linear-gradient(270deg, @TM2Charcoal 0%, @TM2Grey3 100%);
    z-index: -1;
    border: 1px solid @TM2Gold;
    box-sizing: border-box;
  }
  .flex-menu-col-12 > li label:hover {
    background: linear-gradient(0deg, rgba(@TM2WhiteRgb, 0.02), rgba(@TM2WhiteRgb, 0.02)), linear-gradient(90deg, rgba(@TM2Grey1Rgb, 0.1) 0%, rgba(@TM2Grey1Rgb, 0.02) 100%);
    border: 1px solid @TM2Gold;
    box-sizing: border-box;
    cursor: pointer;
  }
  .flex-menu-col-12 > li input[type=radio]:checked + label:hover {
    border: none;
  }

  /* pages/send-metals-to-otc */
  .list-columns-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    li {
      break-inside: avoid-column;
    }
    .mMediaMaxWidth(@lg, {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    });
  }

  /* not used in project - from table.less*/
  .select-table {
    tr {
      cursor: pointer;
    }
  }
  .td-image {
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
      max-width: 80px;
    }
  }
  .td-metall {
    width: 65px;
    text-align: right;
  }
  .avoid-column {
    margin-bottom: 20px;
    break-inside: avoid-column;
  }

  /* not used in project - from tab.less*/
  .tab-links {
  }

  /* not used in project - from checkbox.less*/
  label.lock-label {
    margin-right: 0;
  }
  .lock-label {
    .form-elements div[class*="col"] > & {
      position: absolute;
      right: -10px;
      bottom: 28px;
    }
  }

  /* maybe not used in project */
  .tag-links {
    display: inline-block;
    position: relative;
    > a + a:before {
      display: inline-block;
      content: '';
      width: 1px;
      height: 12px;
      vertical-align: middle;
      background-color: @TM2Grey1;
      margin: 0 10px 0 8px;
    }
  }
  .row-pages {
    margin: 0;
    > div[class*="col"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .page-wrapper {
    height: 100%;
    width: 100%;
  }
  table:not(.@{deprecatedStyleExclusiveMarker} table).main-page {
    margin: 0;
    &, & td {
      height: 100%;
    }
    td {
      vertical-align: middle;
      text-align: center;
    }
  }
  .main-page--dark {
    .mBgCover();
    background-image: url("@{delete-image-src}/page-bg-dark.jpg");
    &, & h1, & h2 {
      color: @TM2White;
    }
    h1, h2 {
      text-transform: uppercase;
    }
    p {
      opacity: .8;
    }
  }
  // old send metals to otc
  .default-row {
    > div[class*="col"] {
      padding-bottom: 30px;
    }
  }
  .icon-middle {
    margin-right: 10px;
    vertical-align: bottom;
  }
  .mf-text-block_history__date {
    font-size: 14px;
    line-height: 16px;
    margin-top: 3px;
  }
  .mf-text-block_history__time {
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
  }
  .custody-fee-w {
    min-width: 953px;
    max-width: 69.64824121%;
  }
  .custody-fee-page-content {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    #scroll-block-trade-history { height: 284px; }
    #scroll-block-tabs { height: 108px; }
    #scroll-block-portfolio { height: 56px; }
    #scroll-block-order-book { height: 56px; }

    @media all and (min-height: 650px) {
      #scroll-block-trade-history { height: 308px; }
      #scroll-block-tabs { height: 132px; }
      #scroll-block-portfolio { height: 80px; }
      #scroll-block-order-book { height: 80px; }
    }
    @media all and (min-height: 730px) {
      #scroll-block-trade-history { height: 360px; }
      #scroll-block-tabs { height: 154px; }
      #scroll-block-portfolio { height: 106px; }
      #scroll-block-order-book { height: 106px; }
    }
    @media all and (min-height: 790px) {
      #scroll-block-trade-history { height: 423px; }
      #scroll-block-tabs { height: 181px; }
      #scroll-block-portfolio { height: 138px; }
      #scroll-block-order-book { height: 138px; }
    }
    @media all and (min-height: 840px) {
      #scroll-block-trade-history { height: 461px; }
      #scroll-block-tabs { height: 198px; }
      #scroll-block-portfolio { height: 157px; }
      #scroll-block-order-book { height: 157px; }
    }
    @media all and (min-height: 880px) {
      #scroll-block-trade-history { height: 539px; }
      #scroll-block-tabs { height: 231px; }
      #scroll-block-portfolio { height: 196px; }
      #scroll-block-order-book { height: 196px; }
    }

    // send metals to otc page =>
    .hr-gradient {
      margin: 30px 0;
      .linear-gradient(90deg, rgba(196, 196, 196, 0.6), rgba(0, 0, 0, 0));
    }
    // <= send metals to otc page

    /*--- News ---*/
    .row-news-item {
      .icon {
        width: 15px;
        height: 15px;
        background-size: 15px;
      }
      .icon-item__text {
        padding-left: 7px;
        vertical-align: text-bottom;
      }
    }
    .row-news-item__top, .row-news-item__date {
      padding-bottom: 15px;
    }
    .row-news-item__content {
      p:last-child {
        margin-bottom: 0;
      }
    }
    .row-news-item__controls {
      text-align: right;
      .link {
        margin: 10px 0 0 25px;
      }
    }
    //
    .content-heading {
      > div[class*="col"] {
        padding-bottom: 30px;
        padding-top: 12px;
      }
      h1 {
        margin:0;
        padding: 0;
      }
    }

    /* Investor */
    /* admin-investor-management-fee.html */

    .management-fee-page-title {
      margin-bottom: 0;
    }

    /*--- Buy&Sell ---*/
    .row-buy-sell-data {
      > div[class*="col"] {
        padding-bottom: 25px;
      }
    }
    .row-buy-sell-data__left {
      .link {
        .icon-item {
          border-bottom-style: dotted;
          border-bottom-width: 1px;
          border-bottom-color: inherit;
        }
      }
    }
    .row-week-range {
      display: none;
      div[class*="col"] {
        padding-bottom: 15px;
      }
    }
    .row-week-range__line {
      text-align: center;
      font-size: 16px;
      line-height: 1.3em;
    }
    .row-week-range__high {
      text-align: right;
    }
    .range-small {
      position: relative;
      height: 1.1em;
      line-height: 1.1em;
      .range-small__line {
        position: absolute;
        height: 4px;
        width: 100%;
        top: 50%;
        margin-top: -2px;
        left: 0;
        background-color: rgba(87, 87, 87, 0.9);
        border-radius: 2px;
      }
      .range-small__line__pointer {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        &:after {
          display: block;
          content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          right: 0;
          margin-right: -7px;
          top: 50%;
          margin-top: -7px;
          .mBgContain;
          background-image: url("@{delete-image-src}/controls/range-small.svg");
          html.no-svg & {
            background-image: url("@{delete-image-src}/controls/range-small.png");
          }
        }
      }
    }
    /* Footer-----------------------------------------------------------------------------*/
    .fixed-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: @TM2Black;
      border-top: solid 1px @TM2Gold;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    .limit-item {
      display: inline-block;
      width: 500px;
      text-align: left;
      padding: 15px 20px;
      vertical-align: top;
      .icon {
        margin-left: 10px;
        width: 15px;
        height: 15px;
        background-size: 15px;
        vertical-align: text-top;
      }
      &:nth-child(odd) {
        .limit-item__content:after {
          background-color: @TM2Gold;
          .mMediaMaxWidth(@md, {
            background-color: transparent;
          });
        }
      }
      .mMediaMaxWidth(@lg, {
        width: 45%;
      });
      .mMediaMaxWidth(@md, {
        width: 100%;
      });
    }
    .limit-item__content {
      position: relative;
      &:after {
        display: block;
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        top: 0;
        right: -20px;
      }
    }
    /* account-manager-invoices.html */
    .content_account-manager-invoices__title-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .content_account-manager-invoices .content__right-corner-button {
      z-index: 1;
      padding-top: 12px; // because h1 tag has such padding
    }

    .tab-blocks_investor-wallet {
      margin-top: 15px;
    }
    .limit-block {
      padding-bottom: 5px;
      margin-bottom: 25px;
      border-bottom: solid 1px @TM2Gold;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
      .form-elements {
        max-width: 380px;
        div[class*="col"] {
          padding-bottom: 20px;
        }
      }
    }
    .limit-block__field {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 5px;
      }
    }
    .activity-item {
      font-size: 12px;
      opacity: 0.8;
      line-height: 1.4em;
    }
    .activity-item:first-child {
      opacity: 1;
      color: @TM2Grey4;
    }
    .col-title-middle, .col-title-default {
      padding-bottom: 15px !important;
    }
    .col-title-middle {
      font-size: 16px;
    }
    .row-wallet-info {
      > div[class*="col"] {
        padding-bottom: 40px;
      }
      .light-gradient-block {
        margin: 0;
      }
    }
    .col-wallet-key {
      text-align: right;
      min-width: 0;
      max-width: 100%;
      padding-top: 14px;
      padding-bottom: 20px;
      .big-text { margin-left: 10px;}
    }
    .row-balance__value {
      .op-text {
        display: inline-block;
        margin-right: 2em;
      }
      .icon-item {
        font-weight: 700;
      }
    }
    .height-90 {
      height: 90px;
    }
    .col-border-left:after {
      left:0;
    }
  }
}
