@import 'variables';
@import 'mixins';

.avatar {
  font-size: 23px;
}

.menu {
  width: 200px;
  border: solid $textColorLight $separatorBorderWidth;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;

  &:hover .itemIcon {
    fill: $menu-highlight-color;
  }

  &:hover .itemText {
    color: $TM2White !important;
  }
}

.itemText {
  margin-right: auto;
}

.itemIcon {
  fill: $menu-text-color;
  transition: fill $transitionDuration;
}

.divider {
  height: 0.5px;
  border: none;
  background-color: $separator-border-color;
}

.profileLayout {
  display: flex;
  align-items: center;
  cursor: pointer;

  // colors to be inherited by children elements
  color: var(--text-color-primary);
  border-color: var(--text-color-primary);

  // transition for both children elements
  transition: all $transitionDuration;

  &:hover {
    // colors to be inherited by children elements
    color: var(--palette-color-primary);
    border-color: var(--palette-color-primary);
  }
}

.profileText {
  color: inherit;

  @include mMaxWidth($md) {
    display: none;
  }
}

.profileButton {
  width: $height-base;
  height: $height-base;
  padding: 0;
  background-color: transparent !important;

  // interrupting antd styles
  border-color: inherit !important;
  color: inherit !important;
  transition: none !important;
}

.verification {
  margin: 0 12px 12px 12px;
}
