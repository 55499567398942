@import 'variables';

/**
 * $height - button height
 * $padding - button global paddings left and right
 * $border - button border width
 * $radius - button radius
 * $font - button text font size
 * $iconSize - button icon size (loading icon from left and icon from right of text, except arrow icon)
 * $icArrMl - arrow icon margin left
 * $icLoadMr - loading icon margin right
 * $icOtherM - right icon full margin
 */
@mixin mButtonOutlineSize($height, $padding, $border, $radius, $font, $iconSize, $icArrMl, $icLoadMr, $icOtherM) {
  height: $height;
  padding-left: $padding;
  padding-right: $padding;
  border-width: $border;
  border-radius: $radius;

  .tm2-button-container:not(.tm2-icon-arrow) {
    width: $iconSize;
    height: $iconSize;
  }

  .tm2-button-container {
    &.tm2-icon-arrow {
      margin-left: $icArrMl;
    }

    &.tm2-icon-none {
      margin-left: $icLoadMr;
    }

    &.tm2-icon-other {
      margin: $icOtherM;
    }

    &.tm2-button-preloader {
      margin-right: $icLoadMr;
    }
  }

  .tm2-button-text {
    max-width: calc(100% - #{$padding} - #{$padding});
    font-size: $font;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/**
 * $bgColor - button background color
 * $borderColor - button border color
 * $textColor - text all icons color
 */
@mixin mButtonOutlineTheme($bgColor, $borderColor, $textColor) {
  border-color: $borderColor;
  background-color: $bgColor;
  color: $textColor;

  .tm2-button {
    &-container {
      .tm2-icon-fill {
        fill: $textColor;
      }

      .tm2-icon-stroke {
        stroke: $textColor;
      }
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.tm2-button-outline {
  max-width: 100%;
  overflow: hidden;

  .tm2-button-container {
    display: flex;
    align-items: center;

    &.tm2-icon-other svg,
    &.tm2-button-preloader svg {
      width: 100%;
      height: 100%;
    }
  }

  .tm2-button-text {
    margin: auto;
  }

  &.tm2-button-small {
    @include mButtonOutlineSize($buttonHeightSm, 10px, 2px, 13.5px, 10px, 1em, 10px, 5px, 0 3px 0 5px);
  }
  &.tm2-button-middle {
    @include mButtonOutlineSize($buttonHeightMd, 10px, 2px, 20px, 14px, 1.1em, 15px, 3px, 0 10px 0 10px);
  }

  &.tm2-button-primary {
    @include mButtonOutlineTheme(transparent, $primaryColor, $primaryColor);
  }

  &.tm2-button-dark {
    @include mButtonOutlineTheme(transparent, $textColorDark, $textColorDark);
  }
  &.tm2-button-light {
    @include mButtonOutlineTheme(transparent, $textColorLight, $textColorLight);
  }
}

/**
 * fix for light theme buttons
 */
.tm2-light-theme {
  .tm2-button-light {
    border-color: $TM2Black;
    background-color: transparent;
    color: $textColorDark;
  }
}
