@import 'theme/variables';

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: unset;
  margin-bottom: calc(var(--spacing-base) * 4) !important;

  @media (min-width: $sm) {
    width: calc((100% - 170px) / 2);
    max-width: 280px;
    margin-bottom: unset !important;
    margin-right: calc(var(--spacing-base) * 5);
  }
}