@import 'variables';
@import 'mixins';

.market-page {
  .order-table-header-price {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include mMaxWidth($xs) {
      flex-direction: column;
    }

    &-text {
      margin-right: 4px;

      @include mMaxWidth($xs) {
        margin-right: 0;
      }
    }
  }

  @include mMaxWidth($sm) {
    width: 100%;
    overflow-x: hidden;

    &-content {
      max-width: 100%;
      margin: 0 !important;

      & > .col {
        padding: 0 !important;
      }

      &-row {
        max-width: 100%;

        &-col {
          max-width: 100%;
          min-width: unset !important;
          width: 100%;
        }
      }

      &-trade-history {
        max-width: 100%;
        min-width: unset !important;

        .scroll-block {
          @include mHorizontalScrollbar();
        }
      }
    }

    .col-graph-absolute {
      .chart-filtr {
        position: unset !important;
      }
      .chart-wrapper {
        position: unset !important;
        padding-top: 0 !important;
      }
    }

    .product-selector {
      &-container.col-auto {
        max-width: 100%;
      }

      .row {
        display: flex;
        flex-direction: column;
        align-items: baseline !important;

        .col-auto {
          margin-bottom: 10px;
        }
      }
    }

    &-info {
      max-width: 100%;

      .tab-links {
        .link {
          margin-bottom: 5px !important;
        }
      }

      .tab-blocks {
        .scroll-block {
          height: auto !important;
          overflow-x: hidden;
        }
      }
    }

    &-order-book {
      .scroll-block {
        @include mHorizontalScrollbar();
        height: auto !important;
      }
    }

    &-trade-history {
      .scroll-block {
        height: auto !important;
      }
    }
  }
}
