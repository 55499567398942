@import 'variables';

/**
 * $height - link height
 * $padding - link global paddings left and right
 * $border - link border width
 * $radius - link radius
 * $font - link text font size
 * $txtMl - text margin left
 * $icArrMl - arrow icon margin left
 */
@mixin mLinkButtonSize($height, $padding, $border, $radius, $font, $txtMl, $icArrMl) {
  height: $height;
  padding-left: $padding;
  padding-right: $padding;
  border-width: $border;
  border-radius: $radius;

  .tm2-link-container {
    margin-left: $icArrMl;
  }

  .tm2-link-text {
    margin-left: $txtMl;
    font-size: $font;
  }
}

/**
 * $bgColor - link background color
 * $borderColor - link border color
 * $textColor - text all icons color
 */
@mixin mLinkButtonTheme($bgColor, $borderColor, $textColor) {
  border-color: $borderColor;
  background-color: $bgColor;
  color: $textColor !important;

  .tm2-link {
    &-text {
      color: $textColor !important;
    }

    &-container {
      .tm2-icon-fill {
        fill: $textColor;
      }

      .tm2-icon-stroke {
        stroke: $textColor;
      }
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.tm2-link-button {
  border-style: solid !important;
  text-decoration: none !important;

  .tm2-link-container {
    display: flex;
    align-items: center;
  }

  .tm2-link-text {
    margin: auto;
  }

  &.tm2-link-middle {
    @include mLinkButtonSize($buttonHeightMd, 10px, 2px, 20px, 14px, 15px, 15px);
  }

  &.tm2-link-primary {
    @include mLinkButtonTheme($primaryColor, $textColorDark, $textColorDark);
  }
}
