/**
 * this file is used to define base variables for antd (see craco.config.js)
 * and css variables for project styles
 */
// ---------figma colors---------
@deprecatedStyleExclusiveMarker: deprecated-style-exclusive-marker;

html {
  --tm2-copper: rgb(204,149,88);
  --tm2-gold: rgb(224,174,111); // TM2Gold: Ссылка Количество новых нотификаций Обводка аватара Back arrow
  --tm2-gold1: rgb(242,198,144);
  --tm2-gold2: rgb(244,215,184);
  --tm2-gold3: rgb(249,232,217);

  --tm2-turquoise: rgb(21,61,73);
  --tm2-blue: rgb(61,110,140); // TMBlue: Статус нотификации
  --tm2-blue1: rgb(87,165,196);
  --tm2-blue2: rgb(133,195,214);

  --tm2-yellow: rgb(255,238,0);
  --tm2-green: rgb(127,255,125); // TM2Green: положительное увеличение баланса; положительное увеличение на диаграмме; заапрувленный статус инвойса
  --tm2-red: rgb(235,86,87); // TM2Red: Статус

  --tm2-black: rgb(30,30,30); // TM2Black: Background Action Menu Background tooltip Шрифт в модалке
  --tm2-charcoal: rgb(43,43,43); // TM2Charcoal: Background основных элементов

  --tm2-grey: rgb(85,85,85);
  --tm2-grey1: rgb(132,132,132); // TM2Grey1: Icon off Dropdown arrow Шрифт в модалке Разделительные линии. Не активный шрифт Не активная иконка Разделительная линия в таблице Label в нерекдактируемом input Background tooltip в графике Шрифт в графиках
  --tm2-grey2: rgb(178,178,178); // TM2Grey2: Шрифт заголовков Выбор даты Иконка сортировки Label input Border input Иконки действий на графике Линия графика
  --tm2-grey3: rgb(206,206,206); // TM2Grey: scroll разделительные линии нотификаций Background выбранного прайса
  --tm2-grey4: rgb(234,234,234); // TM2Grey4: Выбранный элемент в Dropdown
  --tm2-grey5: rgb(244,244,244); // TM2Grey5: Цвет фона в light layout
  --tm2-white: rgb(255,255,255); // TM2White: Шрифт, radio button inactive, фон модалок, кнопки, активный пункт в меню, шрифт Top Navigation Background tooltip
}

@TM2Copper: var(--tm2-copper);
@TM2Gold: var(--tm2-gold);
@TM2Gold1: var(--tm2-gold1);
@TM2Gold2: var(--tm2-gold2);
@TM2Gold3: var(--tm2-gold3);

@TM2Turquoise: var(--tm2-turquoise);
@TM2Blue: var(--tm2-blue);
@TM2Blue1: var(--tm2-blue1);
@TM2Blue2: var(--tm2-blue2);

@TM2Yellow: var(--tm2-yellow);
@TM2Green: var(--tm2-green);
@TM2Red: var(--tm2-red);

@TM2Black: var(--tm2-black);
@TM2Charcoal: var(--tm2-charcoal);

@TM2Grey: var(--tm2-grey);
@TM2Grey1: var(--tm2-grey1);
@TM2Grey2: var(--tm2-grey2);
@TM2Grey3: var(--tm2-grey3);
@TM2Grey4: var(--tm2-grey4);
@TM2Grey5: var(--tm2-grey5);
@TM2White: var(--tm2-white);

@TM2GoldRgb: 224,174,111; // TODO deprecated
@TM2Grey1Rgb: 132,132,132; // TODO deprecated
@TM2Grey3Rgb: 206,206,206; // TODO deprecated
@TM2WhiteRgb: 255,255,255; // TODO deprecated
@TM2CharcoalRgb: 43,43,43; // TODO deprecated
@TM2BlackRgb: 30,30,30; // TODO deprecated
// ---------\figma colors---------

// ---------color settings---------
@primary-color: rgb(224,174,111); // TODO antd is not ready for css variables https://github.com/ant-design/ant-design/pull/31496
@textColorGrey1: @TM2Grey1;
@textColorGrey2: @TM2Grey2;
@textColorLight: @TM2White;
@textColorDark: @TM2Black;
@linkColor: @TM2Blue; // link color for light theme
@bgColorGrey1: @TM2Grey1; // TODO old variable
// ---------\color settings---------

html {
  --ant-font-family: Sequel, sans-serif;
  --font-family-secondary: 'Trebuchet MS', Arial, Helvetica, sans-serif;

  --sidebar-width: 280px; // also used in react component in js
  --sidebar-collapsed-width: 40px;
  --ant-layout-header-height: 45px;

  --separator-border-width: 1px;
  --ant-separator-border-color: #666666;

  --font-size-small: 12px;
  --font-size-big: 16px; // TODO: refactor after adding theme variables
  --ant-font-size-base: 14px;
  --ant-menu-highlight-color: @textColorLight;
  --ant-field-height-base: 32px;
  --ant-radius-base: 25px;
  --ant-menu-text-color: @textColorGrey1;
}

@height-base: 32px; // --ant-field-height-base antd is not ready for css variables

@defaultBorderWidth: 2px;

@radiusBase: var(--ant-radius-base);

@transitionDuration: 300ms;

// text settings
@font-size-base: 14px; // --ant-font-size-base antd is not ready for css variables
@font-family: var(--ant-font-family);
@fontFamilySecondary: var(--font-family-secondary);
@text-color: @textColorLight;

// all project authorized borders
@separatorBorderWidth: var(--separator-border-width);
@separatorBorderColor: var(--ant-separator-border-color);

// divider
@divider-color: @separatorBorderColor;

// // title TODO not used right now
// @heading-1-size: ceil(@font-size-base * 2.71);
// @heading-2-size: ceil(@font-size-base * 2.14);
// @heading-3-size: ceil(@font-size-base * 1.71);
// @heading-4-size: ceil(@font-size-base * 1.42);
// @heading-5-size: ceil(@font-size-base * 1.14);

// authorized header
@layout-header-height: 45px;
@layout-header-background: @TM2Black;
@layout-header-color: @textColorGrey1;

// authorized left sidebar
@menu-bg: @TM2Black;
@menu-inline-submenu-bg: @TM2Black;
@menu-item-color: var(--ant-menu-text-color);
@menu-highlight-color: var(--ant-menu-highlight-color);
@menu-item-active-bg: @menu-bg;
@layout-trigger-background: @TM2Black;
@menu-collapsed-width: 100%;
@menu-item-active-border-width: 10px;

// authorized content
@layout-body-background: @TM2Black;

// background base for notifications/modals/popups/etc
@backingExternalDarkBg: @TM2Black;

// buttons
@buttonHeightSm: 22px;
@buttonHeightMd: @height-base;
@btn-border-width: @defaultBorderWidth;
@btn-border-radius-base: @radiusBase;

// form
@formFieldHeightOld: 40px; // TODO old variable
@formFieldBorderColor: @TM2Grey1; // TODO old variable

// switch
@switch-height: 28px;
@switch-sm-height: 28px;
@switch-min-width: 60px;
@switch-sm-min-width: 60px;
@switch-disabled-opacity: 0.3;
@switch-padding: 2px;

// ============== breakpoints ==================
// note: this variables is used for media queries and can't be set as css variables
@xss: 360px;
@xs: 480px;
@sm: 576px;
@md: 768px;
@iPad: 769px;
@lg: 992px;
@xl: 1200px;
@xxl: 1600px;
