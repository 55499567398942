// List (ul, ol, li) ...
@import "../../../../shared/styles/variables.antd";
@import "../../mixins";

.tm2-deprecated-page-styles {
  ul {
    list-style: none;
    margin-bottom: 1em;
    padding: 0;
    ul {
      margin: 1em 0;
    }
    & > li {
      display: table;
      margin: 0.4em 0;
    }
    & > li:before {
      display: table-cell;
      content: '•';
      font-size: 1.1em;
      width: 27px;
      color: @TM2Gold;
    }
  }
  ol {
    list-style-type: none;
    counter-reset: item;
    padding: 0;
    ul { margin-bottom: 5px; }
    & > li {
      display: table;
      counter-increment: item;
      margin: 0.4em 0 0.5em;
    }
    & > li:before {
      content: counters(item, ". ") ". ";
      display: table-cell;
      padding-right: 0.7em;
      white-space: nowrap;
    }
    & li & > li:before {
      content: counters(item, ". ") " ";
    }
    & li:first-child { margin-top: 0.6em; }
    & li:last-child { margin-bottom: 0; }
  }
}
