@import 'variables';

$configIconSize: 16px;

.wrapper {
  position: relative;
}

.input {
  position: relative;
  width: 100%;
  padding-left: 0;
  padding-right: $configIconSize + 10px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background: transparent;
  z-index: 5;
}

.config {
  $configIconSize: 18px;
  position: absolute;
  top: calc(50% - (#{$configIconSize} / 2));
  right: 0;
  width: $configIconSize;
  height: $configIconSize;
  cursor: pointer;
  z-index: 10;
}

.configIcon {
  position: absolute;
  left: 0;
  top: -1px; // enabled icon has broken shift
  width: 100%;
  height: 100%;
  fill: var(--tm2-white);
  transition: fill $transitionDuration;

  &:hover {
    fill: var(--tm2-gold);
  }
}
