@import 'theme/variables';

.container {
  width: 450px;
}

.title {
  font-family: var(--text-h-font-family);
  color: var(--text-color-primary);
  font-size: 24px;
  line-height: 33px;
  margin-bottom: calc(var(--spacing-base) * 6);
}

.description {
  margin-bottom: calc(var(--spacing-base) * 6);
  font-family: var(--text-h-font-family);
  font-size: 14px;
  line-height: 20px;
}

.descriptionSeparator {
  display: block;
  height: 0;
  visibility: hidden;
  content: '';
}

.instructionsContainer {
  margin-bottom: calc(var(--spacing-base) * 6);
}

.instructionsHeader {
  margin-bottom: calc(var(--spacing-base) * 2);
  font-family: var(--text-h-font-family);
  font-size: 16px;
  line-height: 22px;
}

.instructionsOption {
  display: flex;
  font-family: var(--text-b-font-family);
  font-size: var(--text-b1-size);
  line-height: var(--text-b1-line-height);
}

.instructionIndex {
  width: 24px;
}

.divider {
  margin-bottom: calc(var(--spacing-base) * 6);
  border-color: var(--divider-color);
}

.continueButton {
  margin-bottom: calc(var(--spacing-base) * 6);
}

.skipButton {
  padding: {
    right: 15px !important;
    left: 15px !important;
  }

  svg {
    fill: var(--text-color-primary) !important;
  }
}

@media (min-width: $sm) {
  .title {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: calc(var(--spacing-base) * 3);
  }

  .description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: calc(var(--spacing-base) * 3);
  }

  .instructionsContainer {
    margin-bottom: calc(var(--spacing-base) * 3);
  }

  .instructionsHeader {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: calc(var(--spacing-base));
  }

  .continueButton {
    margin-bottom: calc(var(--spacing-base) * 3);
  }

  .divider {
    margin-bottom: calc(var(--spacing-base) * 3);
  }
}
