@import 'variables';

.tm2-field-phone {
  input {
    height: $formFieldHeight;
    padding: 0 11px;
    border-width: 1px;
    border-style: solid;

    &:disabled {
      opacity: $formFieldDisabledOpacity;
      cursor: not-allowed;
    }
  }
}

.tm2-light-theme .tm2-field-phone {
  input {
    border-color: $formBorderColor;
    color: $textColorDark;
  }
}
