@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

@checkbox-image-src: "../../../../../../../img/admin";

.tm2-deprecated-page-styles {
  label {
    input[type=checkbox] {
      position: absolute;
      top: 5px;
      left: 5px;
      .opacity(0);
      z-index: 1;
      &:disabled + span {
        &:before {
          opacity: 0.5;
        }
        i {
          color: @TM2Grey1 !important;
        }
      }
    } /* to hide the checkbox itself */
    input[type=checkbox] + span:before, input[type=checkbox] + input + span:before, input[type=checkbox] + label + span:before
    {
      display: inline-block;
      position: relative;
      content: '';
      width: 20px;
      height: 20px;
      text-align: left;
      vertical-align: middle;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 20px 40px;
      z-index: 2;
      float: left;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      display: block;
      overflow: hidden;
      font-style: normal;
      padding-left: 12px;
      div {
        margin-bottom: 0.3em;
      }
      .label-img {
        float: right;
        max-height: 80px;
        max-width: 80px;
        margin: 0 0 15px 15px;
      }
    }
  }

  input[type=checkbox] + span:before, input[type=checkbox] + input + span:before, input[type=checkbox] + label + span:before {
    .transition(0.3s);
    transition-property: opacity;
    background-image: url("@{checkbox-image-src}/controls/form-checkbox.svg");
    html.no-svg & {
      background-image: url("@{checkbox-image-src}/controls/form-checkbox.png");
    }
    .lock-label & {
      opacity: 0.5;
      background-image: url("@{checkbox-image-src}/controls/lock.svg");
      html.no-svg & {
        background-image: url("@{checkbox-image-src}/controls/lock.png");
      }
    }
    .lock-label:hover & {
      opacity: 0.75;
    }
  }
  input[type=checkbox]:checked + span:before, input[type=checkbox]:checked + input + span:before, input[type=checkbox]:checked + label + span:before {
    background-position: bottom center;
    opacity: 1;
  }
}
