.h1, .h2, .h3, .h4 {
  font-family: var(--text-h-font-family);
}

.h1 {
  font-size: var(--text-h1-size);
  line-height: var(--text-h1-line-height);
}

.h2 {
  font-size: var(--text-h2-size);
  line-height: var(--text-h2-line-height);
}

.h3 {
  font-size: var(--text-h3-size);
  line-height: var(--text-h3-line-height);
}

.h4 {
  font-size: var(--text-h4-size);
  line-height: var(--text-h4-line-height);
}

.colorPrimary {
  color: var(--text-color-primary);
}

.colorSecondary  {
  color: var(--text-color-secondary);
}
