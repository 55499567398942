.wrapper {
  width: 100%;
  box-shadow: inset 0 -1px 0 0 var(--border-color);
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
}
