@import 'theme/variables';

.box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $sm) {
        flex-direction: column;
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $sm) {
        flex-direction: column;
    }
}

.filter {
    width: calc((100% - 170px) / 2);
    max-width: 280px;

    &:first-child {
        margin-right: 20px;
    }

    @media (max-width: $sm) {
        width: 100%;
        max-width: unset;
        margin: 0 0 15px 0 !important;
    }
}

