@import 'theme/variables';

$filter-width: 320px;
$filter-width-lg: 200px;

.container {
  height: 100%;
}

.verticalFiller {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.topSection {
  display: flex;
}

.filtersPane {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.downloadReportButton {
  display: block;
}

.filterLoaderContainer {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formLg {
  display: none;
}

.filter {
  width: $filter-width;
  margin-bottom: calc(var(--spacing-base) * 4);
}

.dateFilter {
  width: $filter-width;
  margin-bottom: calc(var(--spacing-base) * 4);
}

.filterModalButton {
  cursor: pointer;
  margin-left: auto;
}

.modalFooter {
  display: flex;
  justify-content: space-around;
}

@media (min-width: $sm) {
  $filter-margin-lg: calc(var(--spacing-base) * 2);

  .filtersPane {
    padding-bottom: 0;
  }

  .filterLoaderContainer {
    justify-content: center;
  }

  .formLg {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .filter {
    width: $filter-width-lg;
    margin-right: calc(var(--spacing-base) * 3);
    margin-bottom: $filter-margin-lg;

    &:last-child {
      margin-right: 0;
    }
  }

  .dateFilter {
    width: 250px;
    margin-right: calc(var(--spacing-base) * 3);
    margin-bottom: $filter-margin-lg;
  }

  .filterModalButton {
    display: none;
  }
}
