@import 'variables';
@import 'mixins';
@import '../light.variables';

.footer {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $pagePdHor;
  color: $textColorGrey1;
  text-align: center;

  @include mMaxWidth($md) {
    flex-direction: column;
    font-size: 0.9em;

    & > *:first-child {
      margin-top: auto;
    }
    & > *:last-child {
      margin-bottom: auto;
    }
  }

  @include mMinWidth($md) {
    & > *:first-child {
      margin-left: auto;
    }
    & > *:last-child {
      margin-right: auto;
    }
  }

  @media (min-width: $xs) and (max-width: $md) {
    height: 65px;
  }

  @media (max-width: $xs) {
    height: 65px;
  }
}
