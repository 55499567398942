@import 'variables';
@import 'mixins';

.notifications-component .notification-container--bottom-right {
  position: fixed;
}

.notifications-component .notification {
  $colorTitleDark: $TM2White;
  $colorTextDark: $TM2White;
  $bgNotifyDark: $backingExternalDarkBg;
  $bgTimerAllDark: $TM2Black;
  $bgTimerLeftDark: $TM2Grey2;

  $colorTitleLight: $TM2Black;
  $colorTextLight: $textColorGrey2;
  $bgNotifyLight: $TM2White;
  $bgTimerAllLight: $TM2Grey4;
  $bgTimerLeftLight: $TM2Gold;

  $paddingHor: 24px;
  $paddingVer: 16px;

  margin-bottom: 16px;
  font-family: $font-family;
  transition: height linear 250ms !important;

  .tm2-light-theme & {
    .notification {
      &__item {
        background: $bgNotifyLight;

        .message-container {
          color: $colorTextLight;
        }
      }

      &__title {
        color: $colorTitleLight;
      }

      &__timer {
        background-color: $bgTimerAllLight;

        &-filler {
          background-color: $bgTimerLeftLight;
        }
      }
    }
  }

  .tm2-dark-theme & {
    border: solid $separator-border-color $separatorBorderWidth;
    background: $backingExternalDarkBg;

    .notification {
      &__item {
        background: $bgNotifyDark;

        .message-container {
          color: $colorTextDark;
        }
      }

      &__title {
        color: $colorTitleDark;
      }

      &__timer {
        background-color: $bgTimerAllDark;

        &-filler {
          background-color: $bgTimerLeftDark;
        }
      }
    }
  }

  .notification {
    &__item {
      border-left: none;
      opacity: 0.95;
      box-shadow: 0 0 25px $TM2Charcoal;
      border-radius: 2px;

      &--danger .message-container-text:before {
        background: linear-gradient(180deg, $TM2Red 0%, $TM2Red 53.12%, $TM2Red 99.99%, transparent 100%, $TM2Red 100%);
      }

      &--default .message-container-text:before {
        background: linear-gradient(180deg, $TM2Gold 0%, $TM2Gold 53.12%, $TM2Gold 99.99%, transparent 100%, $TM2Red 100%);
      }

      &--info .message-container-text:before {
        background: linear-gradient(180deg, $TM2Blue 0%, $TM2Blue 53.12%, $TM2Blue 99.99%, transparent 100%, $TM2Red 100%);
      }

      &--success .message-container-text:before {
        background: linear-gradient(180deg, $TM2Green 0%, $TM2Green 53.12%, $TM2Green 99.98%, $TM2Green 99.99%, transparent 100%, $TM2Green 100%, $TM2Green 100%);
      }

      &--warning .message-container-text:before {
        background: linear-gradient(180deg, $TM2Yellow 0%, $TM2Yellow 53.12%, $TM2Yellow 99.98%, $TM2Red 99.99%, transparent 100%, $TM2Gold 100%);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: $paddingVer 0;
    }

    &__title {
      margin: 0;
      padding: 0 $paddingHor 0 $paddingHor;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
    }

    &__message {
      max-width: 100%;
      margin: 0 0 16px 0;

      .message-container {
        position: relative;
        margin: 0 0 0 0;
        font-size: 10px;
        line-height: 12px;
        font-weight: normal;

        &-text {
          max-height: 42px; // height for 3 lines of text
          position: relative;
          margin: 0 0 0 0;
          padding: 5px $paddingHor;
          overflow: hidden;
          transition: max-height linear 250ms;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            content: '';
            display: block;
          }
        }

        &-info {
          display: flex;
          flex-direction: row;
          padding: 0 $paddingHor;

          &-time {

          }
        }
      }
    }

    &__timer {
      width: calc(100% - #{$paddingHor} - #{$paddingHor});
      margin: 0 $paddingHor 0 $paddingHor;

      &-filler {

      }
    }
  }
}
