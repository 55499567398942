@import 'variables';

.container {
  :global(.ant-popover-inner) {
    border: solid 1px;
  }
}

.themeDark {
  :global(.ant-popover-inner) {
    background-color: $TM2Black;
    border-color: $separator-border-color;
  }

  :global(.ant-popover-arrow-content) {
    background-color: $separator-border-color;
  }
}

.themeLight {
  :global(.ant-popover-inner) {
    background-color: $TM2White;
    border-color: $TM2White;
  }

  :global(.ant-popover-arrow-content) {
    background-color: $TM2White;
  }
}
