@import 'variables';
@import 'mixins';

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.stepIndex {
  width: 48px;
  height: 48px;
  font-size: 24px;
  background-color: $TM2Grey5;
  color: $textColorDark;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 36px 19px;

  @include mMaxWidth($xs) {
    margin: 0 0 19px;
  }
}

.stepFilled {
  background-color: $TM2Black;
  color: $textColorLight;
}

.label {
  font-size: 12px;
  max-width: 120px;
  text-align: center;
}

.line {
  width: 105px;
  height: 0;
  border: 1px solid $separator-border-color;
  margin-top: 24px;
}
