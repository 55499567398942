@use 'theme/variables' as theme;

// Padding values should be in sync with ./constants.ts vars.
$cell-padding-x: 5px;
$header-cell-padding-top: 14px;
$header-cell-padding-bottom: 14px;
$row-cell-padding-top: 10px;
$row-cell-padding-bottom: 10px;
$tooltip-arrow-size: 10px;

.table {
  display: flex;
  flex-direction: column;
}

.headerInner {
  display: flex;
}

.headerOuter {
  overflow-x: hidden;
  flex-shrink: 0;
  box-shadow: inset 0 -1px 0 var(--border-color);
}

.headCellBox {
  display: flex;
  align-items: center;
  padding: {
    top: $header-cell-padding-top;
    bottom: $header-cell-padding-bottom;
  }
  font-family: var(--text-h-font-family);
  font-size: var(--text-h4-size);
  line-height: var(--text-h4-line-height);
}

.rowCellBoxOuter {
  display: flex;
  align-items: center;
  justify-content: stretch;
  font-family: var(--text-b-font-family);
  font-size: var(--text-b2-size);
  line-height: var(--text-b2-line-height);

  // Use !important because left/right paddings are
  // added to style based on alignment.
  &:last-child {
    padding-right: $cell-padding-x !important;
  }
}

.headCellSorter {
  border: none;
  padding: 0;
  background-color: transparent;
  outline: none !important;
}

.headCellSorterActive {
  cursor: pointer;
}

.headCellSorterNumber {
  font-size: 10px;
}

.headCellArrowContainer {
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  padding: {
    top: 1px;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }
}

.headCellSorterArrow {
  fill: theme.$tm2-grey3;

  &:first-child {
    margin-top: 3px;
    margin-bottom: 1px;
  }
}

.headCellSorterArrowSelected {
  fill: var(--palette-color-primary);
}

.headCellSorterArrowHidden {
  fill: transparent;
}

.rowCellBoxInner {
  // Inherit width so we can easily check overflow in nested block.
  width: inherit;

  padding: {
    top: $row-cell-padding-top;
    bottom: $row-cell-padding-bottom;
  };
}

.rowBox {
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 var(--border-color);
}

.scrollable {
  flex-grow: 1;
  flex-shrink: 1;
}

.tooltipArrow {
  position: absolute;
  width: 2 * $tooltip-arrow-size;
  height: $tooltip-arrow-size;
}

.tooltipArrowOuter {
  position: absolute;
  z-index: 20;
}

.tooltipArrowInner {
  position: absolute;
  z-index: 25;
}

.tooltip {
  max-width: 300px;
  padding: 15px;
  border-radius: 2px;
  border: 1px solid var(--border-color);
  z-index: 10;
  font-family: var(--text-b-font-family);
  font-size: var(--text-b2-size);
  line-height: var(--text-b2-line-height);
  word-break: break-word;
  background-color: var(--bg1);
  filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 1));

  &[data-popper-placement^='top'] .tooltipArrow {
    bottom: -$tooltip-arrow-size;

    .tooltipArrowOuter {
      top: 0;
      width: 0;
      height: 0;
      border-left: $tooltip-arrow-size solid transparent;
      border-right: $tooltip-arrow-size solid transparent;
      border-top: $tooltip-arrow-size solid var(--border-color);
    }

    .tooltipArrowInner {
      content: '';
      top: 0;
      width: 0;
      height: 0;
      border-left: $tooltip-arrow-size solid transparent;
      border-right: $tooltip-arrow-size solid transparent;
      border-top: $tooltip-arrow-size solid var(--bg1);
      transform: translate(0, -1px);
    }
  }

  &[data-popper-placement^='bottom'] .tooltipArrow {
    top: -$tooltip-arrow-size;

    .tooltipArrowOuter {
      top: 0;
      width: 0;
      height: 0;
      border-left: $tooltip-arrow-size solid transparent;
      border-right: $tooltip-arrow-size solid transparent;
      border-bottom: $tooltip-arrow-size solid var(--border-color);
    }

    .tooltipArrowInner {
      content: '';
      top: 0;
      width: 0;
      height: 0;
      border-left: $tooltip-arrow-size solid transparent;
      border-right: $tooltip-arrow-size solid transparent;
      border-bottom: $tooltip-arrow-size solid var(--bg1);
      transform: translate(0, 1px);
    }
  }
}
