@import 'theme/variables';

.balancesRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--spacing-base);

  @media (min-width: $sm) {
    width: calc(50% - (var(--spacing-base) / 2));
  }
}
