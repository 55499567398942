.links {
  display: flex;
  flex-direction: column;
}

.imageBox {
  width: 100%;
  display: flex;
}

.image {
  width: 100%;
  max-width: 900px !important;
  height: auto !important;
  margin: 0 auto;
}

.list {
  padding: 0 !important;
  font-family: var(--text-b-font-family);
  font-size: var(--text-b1-size);
  line-height: var(--text-b1-line-height);
}
