@import 'variables';

.tm2-field-radio-group {
  $radioSize: 22px;

  &-item {
    &-label {
      color: $textColorGrey1;
      font-family: var(--text-b-font-family);
    }

    &-box {
      $gap: 25px;
      width: 100%;
      display: grid;
      gap: 8px 25px;

      .ant-radio-wrapper {
        align-items: center;

        & > span:not([class]) {
          position: relative;
          display: block;
          font-family: var(--text-b-font-family);
          font-size: 16px;
          line-height: 1em;
          padding-left: 12px;
          padding-right: 0;
        }

        .ant-radio {
          position: relative;
          width: $radioSize;
          height: $radioSize;
          top: unset;

          &-input {
            opacity: 0;
          }

          &-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: solid $TM2White 1px;
            background-color: transparent;

            &:before {
              display: none;
            }

            &:after {
              display: none;
            }
          }

          &-checked {
            .ant-radio-inner {
              background-color: $TM2White;

              &:before {
                $pointSize: 10px;

                position: absolute;
                left: calc(50% - (#{$pointSize} / 2));
                top: calc(50% - (#{$pointSize} / 2));
                display: block;
                width: $pointSize;
                height: $pointSize;
                border-radius: 100%;
                background: $TM2Grey1;
              }

              .ant-radio-checked &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// TODO: перевести стили на модули scss
.tm2-light-theme .tm2-field-radio-group {
  &-item {

    &-box {
      span {
        color: $textColorDark;
        font-family: var(--text-b-font-family) !important;
        font-size: 14px !important;
      }

      .ant-radio-inner {
        border: 1px solid $TM2Grey;
      }

      .ant-radio-checked {
        .ant-radio-inner {
          border: 6px solid $TM2Grey1;
          background-color: $TM2White;
        }
      }
    }
  }
}
