.flagCH {
  background-image: url("../../assets/img/admin/icons/flags/switzerland.svg");
}

.flagUS {
  background-image: url("../../assets/img/admin/icons/flags/usa.svg");
}

.flagEU {
  background-image: url("../../assets/img/admin/icons/flags/european-union.svg");
}

.flagGB {
  background-image: url("../../assets/img/admin/icons/flags/united-kingdom.svg");
}

.flagCA {
  background-image: url("../../assets/img/admin/icons/flags/canada.svg");
}

.flagCN {
  background-image: url("../../assets/img/admin/icons/flags/china.svg");
}
