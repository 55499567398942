@import 'variables';
@import 'mixins';
@import '../date-picker.mixin';

.tm2-field-date-range {
  @include mDateInput();

  .ant-picker-range-separator {
    color: $formBorderColor;
  }

  &-calendar {
    @include mDateCalendar();

    @include mMaxWidth($sm) { // show only one calendar column on small screens
      .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
        visibility: visible !important;
      }

      .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
        visibility: visible !important;
      }

      .ant-picker-panels > *:last-child {
        display: none;
      }

      .ant-picker-panel-container, .ant-picker-footer {
        width: 280px !important;
      }

      .ant-picker-footer-extra > div {
        flex-wrap: wrap !important;
      }
    }

    .ant-picker-ranges {
      .ant-tag { // range button class
        padding: 2px 12px;
        border-radius: $btn-border-radius-base;
        background-color: $primaryColor;
        color: $textColorDark;
      }
    }
  }
}
