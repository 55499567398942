@import 'theme/variables';

.container {
  display: flex;
  align-items: center;
  transition: background .3s;
  box-shadow: inset 0 -1px 0 0 var(--border-color);

  // TODO: theme row-hover-color?
  &:hover {
    background: $tm2-grey6;
  }
}
