@import './variables';
@import './mixins';

html {
  --m-xxs: 4px;
  --m-xs: 8px;
  --m-sm: 16px;
  --m-md: 24px;
  --m-lg: 32px;
  --m-xl: 40px;

  --p-xxs: 4px;
  --p-xs: 8px;
  --p-sm: 16px;
  --p-md: 24px;
  --p-lg: 32px;
  --p-xl: 40px;
}

@include mMaxWidth($md) {
  html {
    --m-xxs: 4px;
    --m-xs: 5px;
    --m-sm: 10px;
    --m-md: 15px;
    --m-lg: 20px;
    --m-xl: 25px;

    --p-xxs: 4px;
    --p-xs: 5px;
    --p-sm: 10px;
    --p-md: 15px;
    --p-lg: 20px;
    --p-xl: 25px;
  }
}

$O: 0;
$auto: auto;

/* ======================= margins ====================== */
.mx-0 {
  margin-left: $O !important;
  margin-right: $O !important;
}
.my-0 {
  margin-top: $O !important;
  margin-bottom: $O !important;
}
.mxy-0 {
  margin: $O !important;
}
.mx-xss {
  margin-left: var(--m-xxs) !important;
  margin-right: var(--m-xxs) !important;
}
.my-xss {
  margin-top: var(--m-xxs) !important;
  margin-bottom: var(--m-xxs) !important;
}
.mxy-xss {
  margin: var(--m-xxs) !important;
}
.mx-xs {
  margin-left: var(--m-xs) !important;
  margin-right: var(--m-xs) !important;
}
.my-xs {
  margin-top: var(--m-xs) !important;
  margin-bottom: var(--m-xs) !important;
}
.mxy-xs {
  margin: var(--m-xs) !important;
}
.mx-sm {
  margin-left: var(--m-sm) !important;
  margin-right: var(--m-sm) !important;
}
.my-sm {
  margin-top: var(--m-sm) !important;
  margin-bottom: var(--m-sm) !important;
}
.mxy-sm {
  margin: var(--m-sm) !important;
}
.mx-md {
  margin-left: var(--m-md) !important;
  margin-right: var(--m-md) !important;
}
.my-md {
  margin-top: var(--m-md) !important;
  margin-bottom: var(--m-md) !important;
}
.mxy-md {
  margin: var(--m-md) !important;
}
.mx-lg {
  margin-left: var(--m-lg) !important;
  margin-right: var(--m-lg) !important;
}
.my-lg {
  margin-top: var(--m-lg) !important;
  margin-bottom: var(--m-lg) !important;
}
.mxy-lg {
  margin: var(--m-lg) !important;
}
.mx-xl {
  margin-left: var(--m-xl) !important;
  margin-right: var(--m-xl) !important;
}
.my-xl {
  margin-top: var(--m-xl) !important;
  margin-bottom: var(--m-xl) !important;
}
.mxy-xl {
  margin: var(--m-xl) !important;
}
.mx-auto {
  margin-left: $auto !important;
  margin-right: $auto !important;
}
.my-auto {
  margin-top: $auto !important;
  margin-bottom: $auto !important;
}
.mxy-auto {
  margin: auto !important;
}
.mt-auto {
  margin-top: $auto !important;
}
.ml-auto {
  margin-left: $auto !important;
}
.mr-auto {
  margin-right: $auto !important;
}
.mb-auto {
  margin-bottom: $auto !important;
}
.mt-0 {
  margin-top: $O !important;
}
.ml-0 {
  margin-left: $O !important;
}
.mr-0 {
  margin-right: $O !important;
}
.mb-0 {
  margin-bottom: $O !important;
}
.mt-xss {
  margin-top: var(--m-xxs) !important;
}
.mt-xs {
  margin-top: var(--m-xs) !important;
}
.mt-sm {
  margin-top: var(--m-sm) !important;
}
.mt-md {
  margin-top: var(--m-md) !important;
}
.mt-lg {
  margin-top: var(--m-lg) !important;
}
.mt-xl {
  margin-top: var(--m-xl) !important;
}
.mr-xss {
  margin-right: var(--m-xxs) !important;
}
.mr-xs {
  margin-right: var(--m-xs) !important;
}
.mr-sm {
  margin-right: var(--m-sm) !important;
}
.mr-md {
  margin-right: var(--m-md) !important;
}
.mr-lg {
  margin-right: var(--m-md) !important;
}
.mr-xl {
  margin-right: var(--m-lg) !important;
}
.mb-xss {
  margin-bottom: var(--m-xxs) !important;
}
.mb-xs {
  margin-bottom: var(--m-xs) !important;
}
.mb-sm {
  margin-bottom: var(--m-sm) !important;
}
.mb-md {
  margin-bottom: var(--m-md) !important;
}
.mb-lg {
  margin-bottom: var(--m-lg) !important;
}
.mb-xl {
  margin-bottom: var(--m-xl) !important;
}
.ml-xss {
  margin-left: var(--m-xxs) !important;
}
.ml-xs {
  margin-left: var(--m-xs) !important;
}
.ml-sm {
  margin-left: var(--m-sm) !important;
}
.ml-md {
  margin-left: var(--m-md) !important;
}
.ml-lg {
  margin-left: var(--m-lg) !important;
}
.ml-xl {
  margin-left: var(--m-xl) !important;
}

.-mt-xss {
  margin-top: calc(var(--m-xxs) * -1) !important;
}
.-mt-xs {
  margin-top: calc(var(--m-xs) * -1) !important;
}
.-mt-sm {
  margin-top: calc(var(--m-sm) * -1) !important;
}
.-mt-md {
  margin-top: calc(var(--m-md) * -1) !important;
}
.-mt-lg {
  margin-top: calc(var(--m-lg) * -1) !important;
}
.-mt-xl {
  margin-top: calc(var(--m-xl) * -1) !important;
}
.-mr-xss {
  margin-right: calc(var(--m-xxs) * -1) !important;
}
.-mr-xs {
  margin-right: calc(var(--m-xs) * -1) !important;
}
.-mr-sm {
  margin-right: calc(var(--m-sm) * -1) !important;
}
.-mr-md {
  margin-right: calc(var(--m-md) * -1) !important;
}
.-mr-lg {
  margin-right: calc(var(--m-md) * -1) !important;
}
.-mr-xl {
  margin-right: calc(var(--m-lg) * -1) !important;
}
.-mb-xss {
  margin-bottom: calc(var(--m-xxs) * -1) !important;
}
.-mb-xs {
  margin-bottom: calc(var(--m-xs) * -1) !important;
}
.-mb-sm {
  margin-bottom: calc(var(--m-sm) * -1) !important;
}
.-mb-md {
  margin-bottom: calc(var(--m-md) * -1) !important;
}
.-mb-lg {
  margin-bottom: calc(var(--m-lg) * -1) !important;
}
.-mb-xl {
  margin-bottom: calc(var(--m-xl) * -1) !important;
}
.-ml-xss {
  margin-left: calc(var(--m-xxs) * -1) !important;
}
.-ml-xs {
  margin-left: calc(var(--m-xs) * -1) !important;
}
.-ml-sm {
  margin-left: calc(var(--m-sm) * -1) !important;
}
.-ml-md {
  margin-left: calc(var(--m-md) * -1) !important;
}
.-ml-lg {
  margin-left: calc(var(--m-lg) * -1) !important;
}
.-ml-xl {
  margin-left: calc(var(--m-xl) * -1) !important;
}

/* ======================= paddings ====================== */
.px-0 {
  padding-left: $O !important;
  padding-right: $O !important;
}
.py-0 {
  padding-top: $O !important;
  padding-bottom: $O !important;
}
.pxy-0 {
  padding: $O !important;
}
.px-xss {
  padding-left: var(--p-xxs) !important;
  padding-right: var(--p-xxs) !important;
}
.py-xss {
  padding-top: var(--p-xxs) !important;
  padding-bottom: var(--p-xxs) !important;
}
.pxy-xss {
  padding: var(--p-xxs) !important;
}
.px-xs {
  padding-left: var(--p-xs) !important;
  padding-right: var(--p-xs) !important;
}
.py-xs {
  padding-top: var(--p-xs) !important;
  padding-bottom: var(--p-xs) !important;
}
.pxy-xs {
  padding: var(--p-xs) !important;
}
.px-sm {
  padding-left: var(--p-sm) !important;
  padding-right: var(--p-sm) !important;
}
.py-sm {
  padding-top: var(--p-sm) !important;
  padding-bottom: var(--p-sm) !important;
}
.pxy-sm {
  padding: var(--p-sm) !important;
}
.px-md {
  padding-left: var(--p-md) !important;
  padding-right: var(--p-md) !important;
}
.py-md {
  padding-top: var(--p-md) !important;
  padding-bottom: var(--p-md) !important;
}
.pxy-md {
  padding: var(--p-md) !important;
}
.px-lg {
  padding-left: var(--p-lg) !important;
  padding-right: var(--p-lg) !important;
}
.py-lg {
  padding-top: var(--p-lg) !important;
  padding-bottom: var(--p-lg) !important;
}
.pxy-lg {
  padding: var(--p-lg) !important;
}
.px-xl {
  padding-left: var(--m-xl) !important;
  padding-right: var(--m-xl) !important;
}
.py-xl {
  padding-top: var(--m-xl) !important;
  padding-bottom: var(--m-xl) !important;
}
.pxy-xl {
  padding: var(--m-xl) !important;
}
.pt-auto {
  padding-top: $auto !important;
}
.pl-auto {
  padding-left: $auto !important;
}
.pr-auto {
  padding-right: $auto !important;
}
.pb-auto {
  padding-bottom: $auto !important;
}
.pt-0 {
  padding-top: $O !important;
}
.pl-0 {
  padding-left: $O !important;
}
.pr-0 {
  padding-right: $O !important;
}
.pb-0 {
  padding-bottom: $O !important;
}
.pt-xss {
  padding-top: var(--p-xxs) !important;
}
.pt-xs {
  padding-top: var(--p-xs) !important;
}
.pt-sm {
  padding-top: var(--p-sm) !important;
}
.pt-md {
  padding-top: var(--p-md) !important;
}
.pt-lg {
  padding-top: var(--p-lg) !important;
}
.pt-xl {
  padding-top: var(--p-xl) !important;
}
.pr-xss {
  padding-right: var(--p-xxs) !important;
}
.pr-xs {
  padding-right: var(--p-xs) !important;
}
.pr-sm {
  padding-right: var(--p-sm) !important;
}
.pr-md {
  padding-right: var(--p-md) !important;
}
.pr-lg {
  padding-right: var(--p-md) !important;
}
.pr-xl {
  padding-right: var(--p-lg) !important;
}
.pb-xss {
  padding-bottom: var(--p-xxs) !important;
}
.pb-xs {
  padding-bottom: var(--p-xs) !important;
}
.pb-sm {
  padding-bottom: var(--p-sm) !important;
}
.pb-md {
  padding-bottom: var(--p-md) !important;
}
.pb-lg {
  padding-bottom: var(--p-lg) !important;
}
.pb-xl {
  padding-bottom: var(--p-xl) !important;
}
.pl-xss {
  padding-left: var(--p-xxs) !important;
}
.pl-xs {
  padding-left: var(--p-xs) !important;
}
.pl-sm {
  padding-left: var(--p-sm) !important;
}
.pl-md {
  padding-left: var(--p-md) !important;
}
.pl-lg {
  padding-left: var(--p-lg) !important;
}
.pl-xl {
  padding-left: var(--p-xl) !important;
}
