@import 'variables';
@import 'mixins';

$gridColumn: $md;

.actionsWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include mMaxWidth($gridColumn) {
    flex-direction: column;
  }
}

.addSymbol {
  margin-left: auto;

  @include mMaxWidth($gridColumn) {
    margin-top: 0;
  }
}
