@import 'variables';
@import 'mixins';
@import '../style.variables';

.tm2-modal-content {
  max-height: calc(100vh - #{$phoneBarsHeight} - #{$headerHeight} - #{$footerHeight});
  position: relative;
  padding: $modalPadding $modalPadding 0 $modalPadding;
  color: $textColorDark;
  overflow-y: auto;
  overflow-x: hidden; // otherwise negative horizontal margins from rows will cause horizontal scroll
  @include mVerticalScrollbar();

  @include mMaxWidth($xs) {
    max-height: calc(100vh - #{$phoneBarsHeight} - #{$headerHeightMobile} - #{$footerHeightMobile});
    padding: $modalPaddingMobile $modalPaddingMobile 0 $modalPaddingMobile;
  };

  &:last-child { // fix padding when modal has no footer
    padding: $modalPadding;

    @include mMaxWidth($xs) {
      max-height: calc(100vh - #{$phoneBarsHeight} - #{$headerHeightMobile});
      padding: $modalPaddingMobile;
    };
  }
}
