@import 'variables';
@import './link-outline';
@import './link-button';

.tm2-link {
  display: inline-flex;
  align-items: center;
  opacity: 1;
  line-height: 1;
  color: $primaryColor !important;
  transition: color $transitionDuration,
              background-color $transitionDuration,
              border-color $transitionDuration,
              opacity $transitionDuration;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &.is-disabled {
    color: $TM2Grey1 !important;
  }
}
