@import 'variables';

.tm2-field-number {
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }

  .ant-input-number-input-wrap input {
    font-family: var(--text-b-font-family) !important;
  }

  .ant-input-number-disabled {
    opacity: $formFieldDisabledOpacity;
  }
}

.tm2-light-theme .tm2-form-field {
  &.tm2-field-number {
    .ant-input-number-input-wrap input {
      color: $textColorDark;
    }
  }
}
