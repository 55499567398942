@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

.tm2-deprecated-page-styles {
  .text-block {
    position: relative;
    input, textarea, .input {
      display: block;
      width: 100%;
      padding: 0;
      .transition(0.3s);
      transition-property: border-color;
      &:hover {
        border-color: @TM2Gold;
      }
      &:disabled {
        border-color: @formFieldBorderColor !important;
        color: @TM2Grey1 !important;
      }
    }
    .input { border: none; }
    input, .input {
      height: @formFieldHeightOld;
      background: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: solid 1px @formFieldBorderColor;
    }
    &.bordered-field {
      input, .input {
        padding: 0 12px;
        margin-top: 0;
        border: solid 1px @formFieldBorderColor;
      }
      .icon {
        left: 12px;
        & + input, & + .input {
          padding-left: 42px;
        }
      }
    }
    .input, .tip {
      line-height: @formFieldHeightOld;
      /*border-bottom: none;*/
    }
    textarea {
      height: 100px;
      padding: 10px;
      border: solid 1px @formFieldBorderColor;
    }
    &.align-center {
      input, .input {
        text-align: center;
      }
    }
  }
  .text-block--big {
    input, .input {
      font-size: 20px;
      text-align: center;
      height: 60px;
      line-height: 60px;
    }
  }
  .textarea--big {
    textarea {
      height: 150px;
    }
  }
  .text-block-transparent {
    height: 100%;
    input, textarea {
      border: none;
      height: 100%;
      margin-top: 0 !important;
    }
  }
  .text-value-big {
    font-size: 20px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-bottom: solid 1px @formFieldBorderColor;
  }
  .text-block--border {
    input, textarea, .input {
      border: solid 1px @formFieldBorderColor;
      border-radius: 1px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .text-ellipsis {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    span& {
      display: inline-block;
      vertical-align: middle;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
  }
  @keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }
  @-webkit-keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    input, label, textarea {
      font-family: @fontFamilySecondary;
    }

    .text-block {
      input, textarea, .input {
        &:focus {
          border-color: @TM2White;
        }
      }
      textarea {
        .linear-gradient(90deg, @TM2Grey3, @TM2Charcoal);
      }
    }

    ::placeholder {
      color: @TM2Grey1;
      opacity: 1;
      font-style: normal;
      font-size: 14px;
    }
    :-ms-input-placeholder {
      color: @TM2Grey1;
      font-style: normal;
      font-size: 14px;
    }
    ::-ms-input-placeholder {
      color: @TM2Grey1;
      font-style: normal;
      font-size: 14px;
    }

    .input-number-wrapper {
      position: relative;
      padding: 0 30px;
      margin: 0 auto;
      max-width: 200px;
      .op-text + & {
        margin-top: 5px;
      }
    }
    .input-number {
      position: relative;
      input {
        display: block;
        width: 100%;
        padding: 0 15px;
        text-align: center;
        font-size: 20px;
        transition-property: border-color;
        height: 50px;
        border: solid 1px @formFieldBorderColor;
        .linear-gradient(90deg, @TM2Grey3, @TM2Charcoal);
        &:hover {
          border-color: @TM2Gold;
        }
        &:focus {
          border-color: @TM2White;
        }
        &:disabled {
          border-color: @formFieldBorderColor !important;
          color: @TM2Grey1 !important;
        }
      }
      .field-tip {
        position: absolute;
        top: 100%;
        margin-top: 3px;
        left: 0;
      }
    }
    .input-number-left-align input {
      text-align: left;
    }
    .input-number__text {
      position: absolute;
      width: auto;
      max-width: 50%;
      top: 0;
      right: 0;
      margin: 0;
      td {
        height: 50px;
        vertical-align: middle;
        font-size: 12px;
        padding: 0 12px 0 5px;
        text-align: right;
      }
      & + input {
        padding-right: 52%;
      }
    }
  }
}
