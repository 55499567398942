@import 'theme/variables';

.compactAll {
  :global(.tm2-button-text) {
    display: none;
  }
}

.compactMobile {
  @media (max-width: $sm) {
    :global(.tm2-button-text) {
      display: none;
    }
  }
}
