.alignLeft {
  :global(tr th) {
    text-align: left;
  }
}

.noPadding {
  padding-bottom: 0 !important;
}

.noData {
  width: 100%;
  padding: 50px 0 42px 0;
  text-align: center;
}
