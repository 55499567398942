.removeRedeemOptionRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.removeButtonIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 1px solid var(--text-color-secondary);
  opacity: .9;
}

.icon {
  stroke: var(--text-color-primary);
  transition: stroke .3s;
}
