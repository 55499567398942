@import 'theme/variables';

$baseInfoTabletWidth: 240px;
$baseInfoNotebookWidth: 270px;
$baseInfoDesktopWidth: 320px;

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--page-content-max-width);

  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.baseInfo {
  width: 100%;
  margin-bottom: calc(var(--spacing-base) * 3);

  @media (min-width: $sm) {
    max-width: $baseInfoTabletWidth;
    margin-right: var(--spacing-base);
    margin-bottom: 0;
  }

  @media (min-width: $md) {
    max-width: $baseInfoNotebookWidth;
  }

  @media (min-width: $lg) {
    max-width: $baseInfoDesktopWidth;
  }
}

.extraInfo {
  width: 100%;

  @media (min-width: $sm) {
    max-width: calc(100% - #{$baseInfoTabletWidth} - var(--spacing-base));
  }

  @media (min-width: $md) {
    max-width: calc(100% - #{$baseInfoNotebookWidth} - var(--spacing-base));
  }

  @media (min-width: $lg) {
    max-width: calc(100% - #{$baseInfoDesktopWidth} - var(--spacing-base));
  }
}
