@import 'theme/variables';

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row {
  display: block;

  @media (min-width: $sm) {
    display: flex;
    justify-content: space-between;
  }
}

.control {
  width: 100%;

  @media (min-width: $sm) {
    width: 50%;
    &:first-child {
      width: calc(50% - var(--spacing-base));
    }
  }
}