@import 'variables';
@import 'mixins';

.add-funds-modal {
  &-radio-credit-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &-icon {
      width: 50px;
      height: 40px;

      @include mMaxWidth($xs) {
        display: none;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
