.tm2-conversion-report {
  &-table-cell {
    &:last-child {
      text-align: right;
    }
  }

  &-table-head {
    &:last-child {
      text-align: right;
    }
  }
}
