@import 'variables';
@import 'mixins';

$gridRow: 900px;
$gridCol: $md;

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include mMaxWidth($gridRow) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.item {
  width: 100%;
  max-width: 180px;
  margin-right: 20px;

  @include mMaxWidth($gridRow) {
    max-width: unset;
    margin-right: 0;

    &:nth-child(1),
    &:nth-child(2) {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }

    &:nth-child(3) {
      width: 100%;
      margin-right: 0;
    }
  }

  @include mMaxWidth($gridCol) {
    width: 100% !important;
  }
}

.placeOffer {
  margin-left: auto;
}
