/*Icon elements styles (.icon-item, .icon-item__text) ...*/
@import "../../../../shared/styles/variables.antd";
@import "../../mixins";

@icon-image-src: "../../../../../img/admin";

.tm2-deprecated-page-styles {
  .icon-item {
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    .row {
      margin: 0;
    }
    .icon-currency {
      margin-left: 5px;
      background-size: 11px 20px !important;
    }
    a& {
      text-decoration: none;
      color: @textColorLight;
    }
  }
  .icon, .icon-item__text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }
  .icon + .icon-item__text {
    padding-left: 10px;
    .mMediaMaxWidth(@lg, {
      padding-left: 8px;
    });
  }
  .icon-item__text {
    line-height: 1em;
    padding: 0;

  }
  .icon {
    width: 20px;
    height: 20px;
    padding: 0;
    .mBgContain;
    background-position: top center;
  }
  .icon-metal {
    width: 30px;
    height: 30px;
  }
  // TODO возможно перенести в форму?
  .icon + input, .icon + select {
    padding-left: 30px;
  }
  .icon-item-bg-top {
    .icon {
      background-position: top center !important;
    }
  }
  .icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    background-size: 14px !important;
  }
  .icon-line {
    padding-bottom: 20px;
    .icon {
      background-size: 20px;
    }
  }
  .success-message-icon {
    background-image: url("@{icon-image-src}/icons/buy-success.svg");
    width: 129px;
    height: 116px;
    margin: 10px auto 40px auto;
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    .icon--settings {
      background-image: url("@{icon-image-src}/icons/settings.svg");
    }
    .icon--document {
      background-image: url("@{icon-image-src}/icons/document.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/document.png");
      }
    }
    .icon--user {
      background-image: url("@{icon-image-src}/icons/user.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/user.png");
      }
    }
    .icon--instructions {
      background-image: url("@{icon-image-src}/icons/instructions.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/instructions.png");
      }
    }
    .icon--password {
      background-image: url("@{icon-image-src}/icons/password.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/password.png");
      }
    }
    .icon--email {
      background-image: url("@{icon-image-src}/icons/email.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/email.png");
      }
    }
    .icon--company {
      background-image: url("@{icon-image-src}/icons/company.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/company.png");
      }
    }
    .icon--country {
      background-image: url("@{icon-image-src}/icons/country.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/country.png");
      }
    }
    .icon--address {
      background-image: url("@{icon-image-src}/icons/address.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/address.png");
      }
    }
    .icon--phone {
      background-image: url("@{icon-image-src}/icons/phone.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/phone.png");
      }
    }

    .icon--main {
      background-image: url("@{icon-image-src}/icons/main.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/main.png");
      }
    }
    .icon--users {
      background-image: url("@{icon-image-src}/icons/users.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/users.png");
      }
    }
    .icon--accounts, .icon--wallets, .icon--wallet {
      background-image: url("@{icon-image-src}/icons/wallet.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/wallet.png");
      }
    }
    .icon--coins {
      background-image: url("@{icon-image-src}/icons/coins.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/coins.png");
      }
    }
    .icon--metals {
      background-image: url("@{icon-image-src}/icons/metals.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/metals.png");
      }
    }
    .icon--transactions {
      background-image: url("@{icon-image-src}/icons/transactions.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/transactions.png");
      }
    }
    .icon--fees {
      background-image: url("@{icon-image-src}/icons/fees.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/fees.png");
      }
    }
    .icon--reports {
      background-image: url("@{icon-image-src}/icons/reports.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/reports.png");
      }
    }
    .icon--vaults {
      background-image: url("@{icon-image-src}/icons/vaults.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/vaults.png");
      }
    }
    .icon--supplies {
      background-image: url("@{icon-image-src}/icons/supplies.svg");
    }
    .icon--news {
      background-image: url("@{icon-image-src}/icons/news.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/news.png");
      }
    }
    .icon--members {
      background-image: url("@{icon-image-src}/icons/members.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/members.png");
      }
    }
    .icon--buy-sell, .icon--bids {
      background-image: url("@{icon-image-src}/icons/buy-sell.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/buy-sell.png");
      }
    }

    .icon--dollar, .icon--dollar-blue {
      width: 11px;
    }
    .icon--dollar {
      background-image: url("@{icon-image-src}/icons/currencies/dollar.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/currencies/dollar.png");
      }
    }
    .icon--dollar-blue {
      background-image: url("@{icon-image-src}/icons/currencies/dollar-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/currencies/dollar-blue.png");
      }
    }
    .icon--logout {
      background-image: url("@{icon-image-src}/icons/logout.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/logout.png");
      }
    }
    .icon--up-green, .icon--down-red {
      width: 15px;
      height: 15px;
      & + .icon-item__text {
        min-width: 50px;
        padding-left: 5px;
      }
    }
    .icon--up-green {
      background-image: url("@{icon-image-src}/icons/arrow-up-green.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/arrow-up-green.png");
      }
    }
    .icon--down-red {
      background-image: url("@{icon-image-src}/icons/arrow-down-red.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/arrow-down-red.png");
      }
    }
    .icon--block-red {
      background-image: url("@{icon-image-src}/icons/block-red.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/block-red.png");
      }
    }
    .icon--remove-red {
      background-image: url("@{icon-image-src}/icons/remove-red.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/remove-red.png");
      }
    }
    .icon--approve-green {
      background-image: url("@{icon-image-src}/icons/approve-green.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/approve-green.png");
      }
    }
    .icon--decline, .icon--cancel {
      background-image: url("@{icon-image-src}/icons/decline.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/decline.png");
      }
    }
    .icon--add-smt-account {
      margin-left: -1px; // to align items in user actions block
      background-image: url("@{icon-image-src}/icons/add-smt-account.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/add-smt-account.png");
      }
    }
    .icon--decline-red {
      background-image: url("@{icon-image-src}/icons/decline-red.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/decline-red.png");
      }
    }
    .icon--burn-yellow {
      background-image: url("@{icon-image-src}/icons/burn-yellow.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/burn-yellow.png");
      }
    }
    .icon--edit {
      background-image: url("@{icon-image-src}/icons/edit.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/edit.png");
      }
    }
    .icon--close-red {
      background-image: url("@{icon-image-src}/icons/close-red.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/close-red.png");
      }
    }
    .icon--start-green {
      background-image: url("@{icon-image-src}/icons/start-green.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/start-green.png");
      }
    }
    .icon--stop-red {
      background-image: url("@{icon-image-src}/icons/stop-red.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/stop-red.png");
      }
    }
    .icon--profile {
      background-image: url("@{icon-image-src}/icons/profile.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/profile.png");
      }
    }
    .icon--profile-edit {
      background-image: url("@{icon-image-src}/icons/profile-edit.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/profile-edit.png");
      }
    }
    .icon--history {
      background-image: url("@{icon-image-src}/icons/history.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/history.png");
      }
    }
    .icon--balance {
      background-image: url("@{icon-image-src}/icons/balance.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/balance.png");
      }
    }

    .icon--user-blue {
      background-image: url("@{icon-image-src}/icons/user-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/user-blue.png");
      }
    }
    .icon--email-blue {
      background-image: url("@{icon-image-src}/icons/email-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/email-blue.png");
      }
    }
    .icon--phone-blue {
      background-image: url("@{icon-image-src}/icons/phone-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/phone-blue.png");
      }
    }
    .icon--country-blue {
      background-image: url("@{icon-image-src}/icons/country-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/country-blue.png");
      }
    }
    .icon--company-blue {
      background-image: url("@{icon-image-src}/icons/company-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/company-blue.png");
      }
    }
    .icon--address-blue {
      background-image: url("@{icon-image-src}/icons/address-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/address-blue.png");
      }
    }
    .icon--wallet-blue {
      background-image: url("@{icon-image-src}/icons/wallet-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/wallet-blue.png");
      }
    }
    .icon--doc-blue {
      background-image: url("@{icon-image-src}/icons/doc-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/doc-blue.png");
      }
    }
    .icon--add {
      background-image: url("@{icon-image-src}/icons/add.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/add.png");
      }
      .color-blue-dodger & {
        background-image: url("@{icon-image-src}/icons/add-blue-dodger.svg");
        html.no-svg & {
          background-image: url("@{icon-image-src}/icons/add-blue-dodger.png");
        }
      }
    }
    .icon--confirm {
      background-image: url("@{icon-image-src}/icons/confirm.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/confirm.png");
      }
      .color-blue-dodger & {
        background-image: url("@{icon-image-src}/icons/confirm-blue-dodger.svg");
        html.no-svg & {
          background-image: url("@{icon-image-src}/icons/confirm-blue-dodger.png");
        }
      }
    }
    .icon--confirm-blue-dodger {
      background-image: url("@{icon-image-src}/icons/confirm-blue-dodger.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/confirm-blue-dodger.png");
      }
    }
    .icon--token-blue-dodger {
      background-image: url("@{icon-image-src}/icons/token-blue-dodger.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/token-blue-dodger.png");
      }
    }
    .icon--token-red {
      background-image: url("@{icon-image-src}/icons/token-red.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/token-red.png");
      }
    }
    .icon--date {
      background-image: url("@{icon-image-src}/icons/date.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/date.png");
      }
    }
    .icon--download {
      background-image: url("@{icon-image-src}/icons/download-blue.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/download-blue.png");
      }
    }
    .icon--attachment {
      background-image: url("@{icon-image-src}/icons/attachment.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/attachment.png");
      }
    }
    .icon--question {
      background-image: url("@{icon-image-src}/icons/question.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/question.png");
      }
    }

    .icon--bar {
      background-image: url("@{icon-image-src}/metal/bar.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/metal/bar.png");
      }
    }
    .icon--coin {
      background-image: url("@{icon-image-src}/metal/coin.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/metal/coin.png");
      }
    }
    .icon--round {
      background-image: url("@{icon-image-src}/metal/round.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/metal/round.png");
      }
    }
    .icon--pdf {
      background-image: url("@{icon-image-src}/icons/pdf.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/pdf.png");
      }
    }
    .icon--invoices {
      background-image: url("@{icon-image-src}/icons/invoices.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/invoices.png");
      }
    }
    .icon--refunds {
      background-image: url("@{icon-image-src}/icons/refunds.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/refunds.png");
      }
    }
    .icon--redeem-requests {
      background-image: url("@{icon-image-src}/icons/redeem-requests.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/redeem-requests.png");
      }
    }
    .icon--management-fee {
      background-image: url("@{icon-image-src}/icons/management-fee.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/management-fee.png");
      }
    }
    .icon--otc-send {
      background-image: url("@{icon-image-src}/icons/otc-send.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/otc-send.png");
      }
    }
    .icon--refunded {
      background-image: url("@{icon-image-src}/icons/refunded.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/refunded.png");
      }
    }
    .icon--paid {
      background-image: url("@{icon-image-src}/icons/paid.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/paid.png");
      }
    }
    .icon--partly-paid {
      background-image: url("@{icon-image-src}/icons/partly-paid.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/partly-paid.png");
      }
    }
    .icon--ready {
      background-image: url("@{icon-image-src}/icons/ready.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/ready.png");
      }
    }
    .icon--lang-en, .icon--lang-cn {
      background-size: 20px !important;
    }
    .icon--lang-en {
      background-image: url("@{icon-image-src}/icons/lang-en.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/lang-en.png");
      }
    }
    .icon--lang-cn {
      background-image: url("@{icon-image-src}/icons/lang-cn.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/lang-cn.png");
      }
    }
    .icon--success {
      width: 123px;
      height: 115px;
      background-image: url("@{icon-image-src}/icons/success.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/success.png");
      }

    }
    .icon--warning {
      width: 126px;
      height: 115px;
      background-image: url("@{icon-image-src}/icons/warning.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/warning.png");
      }

    }
    .icon--buy-success {
      width: 108px;
      height: 102px;
      background-image: url("@{icon-image-src}/icons/buy-success.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/buy-success.png");
      }

    }

    .icon--windows {
      background-image: url("@{icon-image-src}/icons/app-windows.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/app-windows.png");
      }
    }
    .icon--apple {
      background-image: url("@{icon-image-src}/icons/app-apple.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/app-apple.png");
      }
    }
    .icon--linux {
      background-image: url("@{icon-image-src}/icons/app-linux.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/app-linux.png");
      }
    }
    .icon--search-white {
      background-image: url("@{icon-image-src}/icons/search-white.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/search-white.png");
      }
    }
    .icon--swap-vertical {
      background-image: url("@{icon-image-src}/icons/swap-vertical.svg");
      html.no-svg & {
        background-image: url("@{icon-image-src}/icons/swap-vertical.png");
      }
    }
    .icon--profile-preview {
      width: 60px;
      height: 60px;
      background-image: url("@{icon-image-src}/profile-preview.svg");
    }
    html.no-svg .icon--profile-preview {
      background-image: url("@{icon-image-src}/profile-preview.png");
    }
    .icon--send {
      width: 15px;
      height: 15px;
      background-image: url("@{icon-image-src}/icons/send.svg");
    }
    .icon--send-fiat {
      width: 15px;
      height: 15px;
      background-image: url("@{icon-image-src}/icons/send-fiat.svg");
    }
    .icon--statements {
      background-image: url("@{icon-image-src}/icons/statements.svg");
    }
    .icon--settlements-button {
      background-image: url("@{icon-image-src}/icons/settlements-button.svg");
    }
    .icon--right-arrow-big {
      width: 16px;
      height: 12px;
      background-size: 16px;
      background-image: url("@{icon-image-src}/icons/right-arrow-big.svg");
    }
    .icon--coming-soon-big {
      display: inline-block;
      width: 28px;
      height: 28px;
      background-size: 28px;
      background-image: url("@{icon-image-src}/icons/coming-soon-big.svg");
    }
    .icon--right-arrow-big-white {
      width: 16px;
      height: 12px;
      background-size: 16px;
      background-image: url("@{icon-image-src}/icons/right-arrow-big-white.svg");
    }
  }
}
