@import 'theme/variables';

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (min-width: $xs) {
    flex-direction: row;
  }
}

.infoField {
  width: 100%;

  @media (min-width: $xs) {
    width: calc(50% - var(--spacing-base));
  }

  @media (min-width: $sm) {
    width: calc((100% / 3) - var(--spacing-base));
  }
}
