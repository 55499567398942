@import 'variables';

.button {
  height: 14px;
  width: 14px;
  display: flex;
  transition: transform $transitionDuration;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.expanded {
  transform: scale(-1, -1)  !important;
}

.svg {
  margin: auto;
  stroke: $textColorLight;
}
