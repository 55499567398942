@import 'theme/variables';
@import 'mixins';

.table {
  @include mTableHeight(true);
  padding: 0 !important;
}

.table tr td {
  font-size: var(--text-b2-size) !important;
}

.head {
  text-align: left !important;
}

.cell {
  padding: 0 10px !important;
  line-height: 36px !important;
  height: 41px;
}

.symbolCell {
  width: 20%;

  @media (max-width: $sm) {
    max-width: 100px;
  }
}

.metalCell {
  width: 20%;

  @media (max-width: $sm) {
    max-width: 100px;
  }
}

.priceCell {
  width: 60%;

  @media (max-width: $sm) {
    padding: 10px !important;
    max-width: 200px;
  }
}

.link {
  line-height: 16px !important;
  transition: none;
  border: none !important;
  min-height: 25px !important;

  @media (max-width: $sm) {
    :global(.tm2-link-container) {
      display: none;
    }
  }
}

.buttonLink {
  font-size: var(--text-b2-size) !important;
  height: 20px !important;
  padding: {
    top: 0 !important;
    right: 12px !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  @media (max-width: $sm) {
    height: initial !important;
  }
}

.buttonLink :global(.tm2-link-text) {
  margin: {
    top: 5px !important;
    bottom: 5px !important;
  }
}

.divider {
  @media (max-width: $sm) {
    display: block;
    width: 100%;
    height: 1px;
    opacity: 0;
  }
  margin: {
    left: 10px;
    right: 10px;
  }
}

.title {
  @media (max-width: $sm) {
    margin-top: calc(var(--spacing-base) * 6);
  }
}