@import 'variables';
@import 'theme/variables';

.bank {
  padding: calc(var(--spacing-base) * 2) calc(var(--spacing-base) * 4);
  background-color: var(--bg3);
}

.content {
  overflow: hidden;
  transition: height $transitionDuration;
}

.short {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.box {
  display: flex;
  flex-direction: column;
  background-color: var(--bg2);
  padding: calc(var(--spacing-base) * 4) calc(var(--spacing-base) * 4) calc(var(--spacing-base) * 4) 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: $md) {
    flex-direction: column;
  }
}

.item {
  width: 50%;
  max-width: 350px;
  padding-left: calc(var(--spacing-base) * 4);

  &:not(:last-child) {
    margin-bottom: calc(var(--spacing-base) * 2);
  }

  &:last-child:nth-child(2n + 1) {
    width: 100%;
  }

  @media (max-width: $md) {
    width: 100%;
    max-width: unset;
  }
}
