@import 'variables';

// TODO: tm2-2958
html {
  --light-header-height: 100px;
  --light-footer-height: 60px;
}
@media (min-width: $xs) and (max-width: $md) {
  html {
    --light-header-height: 80px;
    --light-footer-height: 65px;
  }
}
@media (max-width: $xs) {
  html {
    --light-header-height: 50px;
    --light-footer-height: 65px;
  }
}

$headerHeightLight: var(--dynamic-page-header-height);
$footerHeightLight: var(--dynamic-page-footer-height);
$contentHeaderLight: calc(
  100% -
  var(--dynamic-page-header-height) -
  var(--dynamic-page-top-banner-height) -
  var(--dynamic-page-footer-height)
);
