@import 'variables';

.field {
  :global(.ant-form-item-has-error .ant-input-suffix) {
    border-color: $TM2Red !important;
  }
}

.instance {
  height: $height-base;
  padding: 0 !important;

  :global(.ant-input) {
    width: calc(100% - 11px - 30px - 1px);
    margin: 4px 0 4px 11px;
  }

  :global(.ant-input-suffix) {
    width: 30px;
    height: 30px;
    display: flex;
    margin: 0;
    border-left: solid $formBorderColor 1px;
    transition: border-color $transitionDuration;
  }

  &:hover :global(.ant-input-suffix) {
    border-color: $primaryColor; // we can't update with focus-within. Errored field will have ugly color
  }

  &:focus-within :global(.ant-input-suffix) {
    border-color: $primaryColor !important;
  }

  :global(.ant-input-password-icon) {
    width: 20px;
    height: 20px;
    margin: auto;
  }
}
