@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fontface/subset-Roboto-Regular.eot');
  src: url('../../assets/fontface/subset-Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fontface/subset-Roboto-Regular.woff2') format('woff2'),
  url('../../assets/fontface/subset-Roboto-Regular.woff') format('woff'),
  url('../../assets/fontface/subset-Roboto-Regular.ttf') format('truetype'),
  url('../../assets/fontface/subset-Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fontface/subset-Roboto-Medium.eot');
  src: url('../../assets/fontface/subset-Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fontface/subset-Roboto-Medium.woff2') format('woff2'),
  url('../../assets/fontface/subset-Roboto-Medium.woff') format('woff'),
  url('../../assets/fontface/subset-Roboto-Medium.ttf') format('truetype'),
  url('../../assets/fontface/subset-Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fontface/subset-Roboto-Bold.eot');
  src: url('../../assets/fontface/subset-Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fontface/subset-Roboto-Bold.woff2') format('woff2'),
  url('../../assets/fontface/subset-Roboto-Bold.woff') format('woff'),
  url('../../assets/fontface/subset-Roboto-Bold.ttf') format('truetype'),
  url('../../assets/fontface/subset-Roboto-Bold.svg#Roboto-Medium') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Sequel';
  src: url('../../assets/fontface/Sequel-100-Wide-45.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
