/*Form styles (.text-block, .form-elements) ...
error.less - скорее всего не нужен, эти стили нужно разместить внутри formless
datepicker, input, checkbox, range, select и подобные - это все внутри form less*/

.tm2-deprecated-page-styles {
  .form-elements {
    > div[class*="col"] {
      padding-bottom: 24px;
    }
    > .col-12:last-child {
      padding-bottom: 10px;
    }
    .col-12 {
      h3:last-child {
        margin-bottom: -10px;
      }
      .label-small:last-child {
        margin-bottom: -20px;
      }
    }
    > div[class*="col"] > .row {
      margin-right: -8px;
      margin-left: -8px;
      > div[class*="col"] {
        padding-right: 8px;
        padding-left: 8px;
      }
      + .row {
        margin-top: 8px;
        margin-bottom: 4px;
      }
    }
  }
  .text-block, .select-block {
    .icon {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
  .field-tip {
    display: inline-block;
    margin-top: 2px;
  }
  &.tm2-light-theme {
    .field-tip {
      color: @textColorGrey1 !important;
    }
  }
}

// form components
@import "text-fields";
@import "file";
@import "label";
@import "radio";
@import "checkbox";
@import "datepicker";
@import "select";
@import "dropdown";
@import "error";
