@import 'theme/variables';

.widget {
  margin-bottom: calc(var(--spacing-base) * 3);

  @media (min-width: $sm) {
    margin-bottom: var(--spacing-base);
  }
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.steps {
  display: flex;
  flex-direction: row;

  @media (max-width: $sm) {
    flex-direction: column;
    align-items: center;
  };
}

.separator {
  width: calc((100% - 360px) / 2);
  height: 2px;
  margin: 23px auto 0 auto;
  background: var(--tm2-grey1);

  @media (max-width: $sm) {
    display: none;
  };
}

.portal {
  width: 100%;
}
