@import 'variables';
@import 'mixins';
@import '../style.variables';

.tm2-modal.tm2-light-theme {
  .tab-links {
    font-size: 24px;
  }

  .gradient-block-small {
    padding: 10px 15px;
    margin: 0;

    td {
      padding-bottom: 5px !important;
      padding-top: 5px;
    }
  }

  .light-gradient-block {
    background: linear-gradient(90deg, rgba(132,132,132, 0.2) 0%, rgba(43,43,43, 0.02) 100%);
  }

  .icon--swap-vertical {
    background-image: url("../../../../../assets/img/admin/icons/swap-vertical-dark.svg");
  }

  .field-label {
    color: $textColorDark;
  }

  .color--grey {
    color: $textColorGrey2 !important;
  }

  label, textarea {
    color: $textColorGrey1 !important;
    font-family: $fontFamilySecondary;
  }

  .col-hypohen {
    position: absolute;
    right: -10px;
    bottom: 60px;
    display: inline-block;
    width: 20px;
    text-align: center;
  }

  .input-number {
    position: relative;
  }

  .col-5.align-self-end {
    align-self: unset !important;
  }

  .input-number__text {
    position: absolute;
    width: auto;
    max-width: 50%;
    top: 0;
    right: 0;
    margin: 0;

    td {
      height: 50px;
      vertical-align: middle;
      font-size: 12px;
      padding: 0 5px;
      text-align: right;
    }

    & + input {
      text-align: left;
      display: block;
      width: 100%;
      padding: 0 50% 0 15px;
      font-size: 20px;
      transition-property: border-color;
      height: 50px;
      border: 1px solid $textColorGrey2;
      background-color: $TM2Grey4;
      font-family: $fontFamilySecondary;
    }
  }
}
