@import "../../../../shared/styles/variables.antd";
@import "../../mixins";

/* ---------TM2 Dark Layout---------- */
.tm2-deprecated-page-styles.tm-dark-theme {
  .filter-block {
    > div[class*="col"] {
      padding-bottom: 15px;
    }
  }
}
