.actionsHeadCell {
  min-width: 60px;
  pointer-events: none;

  &:after {
    display: none !important;
  }

  &:before {
    display: none;
  }
}

.walletActions {
  padding-top: 8px;
}
