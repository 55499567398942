@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

@error-image-src: "../../../../../../../img/admin";

.tm2-deprecated-page-styles {
  .block-error {
    input, textarea, select {
      border-color: @TM2Red !important;
      &::placeholder {
        color: @TM2Red;
      }
    }
    input, textarea, .form-label& {
      padding-right: 30px;
    }
    select {
      padding-right: 40px;
    }
    .file-box& input {
      padding-right: 0;
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 18px;
      height: 15px;
      top: 30%;
      right: 5px;
      margin-top: -7px;
      .mBgContain;
      background-image: url("@{error-image-src}/controls/icon-error.svg");
      html.no-svg & {
        background-image: url("@{error-image-src}/controls/icon-error.png");
      }
      .select-block& {
        right: 15px;
      }
    }
    & + .field-tip {
      margin-top: 16px;
    }
  }
  span.error {
    color: @TM2Red;
    font-size: 12px;
    line-height: 1.1em;
    text-transform: none;
    .text-block &, .select-block &, .form-label &, .file-box &, .upload-previews__item & {
      position: absolute;
      left: 0;
      top: 100%;
      margin: 3px 0 0 0;
    }
    .form-label & {
      margin-left: 30px;
    }
  }
}
