@import 'variables';

.tm2-field-select {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    opacity: $formFieldDisabledOpacity;
    color: $textColorLight;
  }

  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border-color: $TM2Red !important;
  }

  .ant-select-arrow {
    color: $textColorLight;
  }

  .ant-select-selection-search {
    color: $textColorDark;
  }

  .ant-select-selection-item {
    font-family: var(--text-b-font-family);
  }

  &-dropdown {
    border: solid $TM2Grey2 1px;
    background-color: $backingExternalLightBg !important;
    z-index: 3000;

    &-option {
      color: $textColorDark;
      background-color: transparent !important;
      transition: background-color $transitionDuration, color $transitionDuration !important;

      &:hover {
        background-color: $primaryColor !important;
        color: $textColorDark;
      }

      &.ant-select-item-option-selected {
        color: $primaryColor;
        background-color: transparent;
      }

      .ant-select-item-option-content {
        font-family: var(--text-b-font-family);

        span {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &.ant-select-item-option-disabled {
        opacity: 0.3;
        color: $textColorDark !important;
      }
    }
  }
}

.tm2-light-theme .tm2-form-field {
  &.tm2-field-select {
    .ant-select-selection-item {
      color: $textColorDark;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: $textColorDark;
    }

    .ant-select-arrow {
      color: $textColorDark;
    }
  }
}
