@import 'theme/variables';

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.field {
  width: calc(50% - var(--spacing-base));

  @media (max-width: $sm) {
    width: 100%;
  }
}

.address {
  &:nth-child(2n + 1) {
    width: 100%;
  }
}
