$spacing-prefixes: (p: padding, m: margin);
$spacing-directions: (t: top, r: right, b: bottom, l: left);
$spacing-multiplier-min: 1;
$spacing-multiplier-max: 8;

@for $multiplier from $spacing-multiplier-min through $spacing-multiplier-max {
  @each $prefix, $prefixProp in $spacing-prefixes {
    :global(.#{$prefix}#{$multiplier}) {
      #{$prefixProp}: calc(var(--spacing-base) * #{$multiplier});
    }
  }
}

@for $multiplier from $spacing-multiplier-min through $spacing-multiplier-max {
  @each $prefix, $prefixProp in $spacing-prefixes {
    @each $direction, $directionProp in $spacing-directions {
      :global(.#{$prefix}#{$direction}#{$multiplier}) {
        #{$prefixProp}-#{$directionProp}: calc(var(--spacing-base) * #{$multiplier});
      }
    }
  }
}
