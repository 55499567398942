@import 'theme/index.module';

.card {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
}

.avatar {
  display: flex;
  width: 160px;
  height: 160px;
  border: solid #FFFFFF 2px;
  border-radius: 100%;
  margin: 0 auto calc(var(--spacing-base) * 3) auto;

  @media (max-width: $md) {
    width: 130px;
    height: 130px;
  }

  @media (max-width: $sm) {
    width: 100px;
    height: 100px;
  }
}

.image {
  width: 68px;
  margin: auto;

  @media (max-width: $md) {
    width: 54px;
  }

  @media (max-width: $sm) {
    width: 42px;
  }
}

.box {
  padding: var(--spacing-base) calc(var(--spacing-base) * 2);
  background-color: var(--bg3);
  font-family: var(--text-h-font-family);
  font-size: var(--text-b2-size);
  line-height: var(--text-b2-line-height);
  color: var(--text-color-primary);
}
