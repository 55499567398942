@use 'theme/variables' as theme;

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: unset;

  @media (min-width: theme.$sm) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.field {
  width: 100%;

  @media (min-width: theme.$sm) {
    width: calc(50% - (var(--spacing-base) * 2));
  }
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: calc(var(--spacing-base) * 3);

  @media (min-width: theme.$sm) {
    margin-top: var(--spacing-base);
    margin-bottom: unset;
  }
}

.unmatchedMessage {
  color: theme.$tm2-red;
}

.accountInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.accountItem {
  width: 100%;

  @media (min-width: theme.$sm) {
    width: calc(50% - var(--spacing-base));
  }
}

.comments {
  width: 100%;
}

.modal {
  min-height: 100vh;
}

.footer {
  display: flex;
  align-items: flex-start;
  min-height: 120px;

  @media (min-width: theme.$sm) {
    display: flex;
    align-items: flex-end;
    min-height: unset;
  }
}