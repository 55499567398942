@import 'theme/variables';

.description {
  margin-right: calc(var(--spacing-base) * 2);

  @media (max-width: $sm) {
    margin-right: 0;
    margin-bottom: calc(var(--spacing-base) * 3);
  }
}

.step {
  color: var(--palette-color-primary);
}
