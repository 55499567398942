@import 'variables';

.tm2-form-field { // general class for all form fields
  position: relative;
  min-height: 60px; // 32 input height + 24 margin-bottom + validation error height
  width: 100%;
  display: block;

  .ant-form-item-extra {
    min-height: max-content;
    order: 2; // 1 is input element
    font-size: 12px;
    line-height: 1.2;
  }

  .ant-form-item-explain {
    font-size: 12px !important;
    line-height: 1.2 !important;
  }

  .ant-form-item-explain-error { // on same element as .ant-form-item-explain
    order: 3; // need to show validation error after extra field description text
    color: $TM2Red;
    margin-bottom: 3px;
    font-family: var(--text-b-font-family) !important;
  }

  &.tm2-form-field-extra {
    min-height: 72px;
    font-family: var(--text-b-font-family) !important;
  }

  &.tm2-form-field-compact {
    min-height: unset;
  }

  &-item { // general class for all form fields
    &.ant-form-item-has-error &-instance {
      border-color: $TM2Red !important;
    }

    &:not(.ant-form-item-has-error) &-instance:not(:disabled):not(.ant-input-number-disabled):not(.ant-input-disabled):not(.ant-picker-disabled):not(.tm2-field-file-uploader-disabled):hover {
      // note: this is multi rule for text/number/select/file-uploader/date fields
      // for new fields additional unique class is required
      border-color: $primaryColor !important;
    }

    .tm2-form-field-compact & {
      margin-bottom: 0;
    }

    &-instance { // general class for all form fields
      width: 100% !important;
      background-color: transparent !important;
      border-color: $formBorderColor !important;
      font-family: var(--text-b-font-family) !important;

      .ant-input {
        background-color: transparent !important;
      }

      input {
        font-family: var(--text-b-font-family) !important;
      }

      &:not(:disabled):focus-within {
        border-color: $primaryColor !important;
      }
    }
  }
}
