@import 'variables';

/**
 * $font - button text font size
 * $iconSize - button icon size (loading icon from left and icon from right of text, except arrow icon)
 * $$iconMl - right from text icon margin left
 * $icLoadMr - loading icon margin right
 * $arrScale - transform scale factor for arrow
 */
@mixin mButtonTextSize($font, $iconSize, $iconMl, $icLoadMr, $arrScale) {
  .tm2-button-container:not(.tm2-icon-arrow) {
    width: $iconSize;
    height: $iconSize;
  }

  .tm2-button-container {
    &.tm2-icon-arrow {
      margin-left: $iconMl;
      transform: scale($arrScale);
    }

    &.tm2-icon-none {
      margin-left: $icLoadMr;
    }

    &.tm2-icon-other {
      margin-left: $iconMl;
    }

    &.tm2-button-preloader {
      margin-right: $icLoadMr;
    }
  }

  .tm2-button-text {
    font-size: $font;
  }
}

/**
 * $font - button text font size
 * $iconSize - button icon size (loading icon from right and icon from right of text)
 * $textMr - right from text margin
 */
@mixin mButtonTextIconBigSize($font, $iconSize, $textMr) {
  .tm2-button-text {
    margin-right: $textMr;
    font-size: $font;
  }

  .tm2-button-container {
    width: $iconSize;
    height: $iconSize;
    display: flex;

    .tm2-button-icon {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

}

/**
 * $color - text and all icons color
 */
@mixin mButtonsTheme($color) {
  color: $color;

  .tm2-button {
    &-container {
      .tm2-icon-fill {
        fill: $color;
      }

      .tm2-icon-stroke {
        stroke: $color;
      }
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.tm2-button-text-icon-big {
  padding: 0;
  background-color: transparent !important;
  border: none;
  box-shadow: none;

  &::after {
    display: none !important;
  }

  .tm2-button-container {
    display: flex;

    .tm2-button-icon {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .tm2-button-preloader {
      margin: auto;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.tm2-button-small {
    @include mButtonTextIconBigSize(5px, $buttonHeightMd, 8px);
  }

  &.tm2-button-middle {
    @include mButtonTextIconBigSize(14px, $buttonHeightMd, 8px);
  }

  &.tm2-button-light {
    @include mButtonsTheme($textColorLight);
  }
}

.tm2-button-text {
  height: max-content !important;
  padding: 0 !important;
  border: none !important;

  .tm2-button-container {
    display: flex;
    align-items: center;

    &.tm2-icon-other svg,
    &.tm2-button-preloader svg {
      width: 100%;
      height: 100%;
    }
  }

  .tm2-button-text {
    margin: auto;
  }

  &.tm2-button-small {
    @include mButtonTextSize(10px, 1em, 0, 5px, 0.65);
  }
  &.tm2-button-middle {
    @include mButtonTextSize(14px, 1.3em, 10px, 7px, 1);
  }

  &.tm2-button-dark {
    @include mButtonsTheme($textColorDark);
  }
  &.tm2-button-light {
    @include mButtonsTheme($textColorLight);
  }
}

/**
 * fix for light theme buttons
 */
.tm2-light-theme {
  .tm2-button-light {
    color: $textColorDark;

    .tm2-icon-stroke {
      stroke: $TM2Black !important;
    }
  }
}
