@import 'variables';

.tm2-form-label {
  position: absolute;
  left: 5px;
  top: 4px;
  padding: 0 3px;
  color: $textColorGrey1;
  font-size: 1em;
  transition: top $transitionDuration, left $transitionDuration, font-size $transitionDuration;
  font-family: var(--text-b-font-family) !important;

  &:after {
    display: block;
    position: absolute;
    left: 0;
    top: 7px;
    width: 100%;
    height: 6px;
    content: '';
    background-color: var(--form-field-label-stroke, transparent);
    z-index: -1;
  }

  &-asterisk {
    margin-top: -5px;
    color: $primaryColor;
  }

  &.tm2-form-label-shifted {
    left: 5px;
    top: -9px;
    font-size: 0.85em;
    z-index: 10;
  }
}

.tm2-light-theme .tm2-form-field {
  .tm2-form-label {
    color: $textColorGrey2;

    &:after {
      background-color: $backingExternalLightBg;
    }
  }
}
