@import 'variables';

$height: 22px; // equal to line height for now

.button {
  height: $height;
  width: max-content;
  padding: 0 !important;
  background: transparent;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  font-family: var(--text-b-font-family);
  line-height: $height;
  cursor: pointer;
  color: var(--tm2-white);
  opacity: 1;
  transition: opacity $transitionDuration, height $transitionDuration, margin-bottom $transitionDuration, margin-top $transitionDuration;

  &:disabled {
    opacity: 0;
    height: 0;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}
