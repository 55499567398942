@import '../../styles/shared';

.pane {
  padding: 24px;
  background-color: $TM2Charcoal;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  line-height: 28px;
  font-size: 20px;
  color: $TM2White;

  & > :first-child {
    margin-right: auto;
  }
}

.secondaryHeader {
  display: block;
  margin-bottom: 8px;
  line-height: 19px;
  font-size: 14px;
  color: $TM2Grey1;
}

.section {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.sectionRecord {
  display: block;
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.sectionRecordNegative {
  color: $TM2Red;
}
