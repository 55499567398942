@import 'theme/variables';

.statements-page {

  .long-item-w-link {
    border-radius: 2px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    width: 40%;
    color: $tm2-white;

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  .long-item-w-link:hover {
    border: calc(var(--border-width) / 2) solid $tm2-gold;
  }

  .long-item-w-link:hover i {
    background-image: url("../../../assets/img/admin/icons/right-arrow-big.svg");
  }

  a {
    text-decoration: none !important;
  }
}
