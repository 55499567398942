@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

@select-image-src: "../../../../../../../img/admin";

.tm2-deprecated-page-styles {
  select {
    height: @formFieldHeightOld;

    .mHorizontalScrollbar();
    .mVerticalScrollbar();

    border: 1px solid @separatorBorderColor;

    &:hover {
      border-color: @TM2Gold;
    }
  }

  .select-block {
    position: relative;
    background: none;
    height: @formFieldHeightOld;
    select {
      width: 100%;
      height: inherit;
      /*position: absolute;
      top: 0;
      left: 0;*/
      padding: 0 30px 0 0;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none !important;
      background: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: solid 1px @formFieldBorderColor;
      &:hover {
        border-color: @TM2Gold;
      }
      &:disabled {
        border-color: @formFieldBorderColor !important;
        color: @TM2Grey1 !important;
      }
    }
    select:invalid {
      color: @TM2Grey1;
    }
    select::-ms-expand {
      /* for IE 11 */
      display: none;
    }
    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 10px;
      height: 100%;
      top: 0;
      right: 0;
      background: transparent url("@{select-image-src}/controls/dropdown.svg") no-repeat center center;
      z-index: 1;
      html.no-svg & {
        background-image: url("@{select-image-src}/controls/dropdown.png");
      }
    }
    select:focus + span.arr { background-position: right center; }
    .input {
      padding-right: 30px;
    }
    &.bordered-field {
      height: 50px;
      select {
        padding: 0 30px 0 12px;
      }
      .input, select {
        height: 50px;
        line-height: 30px;
        padding-right: 30px;
        z-index: 2;
        position: relative;
        border: solid 1px @formFieldBorderColor;
      }
      &:before {
        right: 10px;
      }
      &:hover {
        .input, select { border-color: @TM2Gold; }
      }
      .field-label {
        position: absolute;
        top: 28px;
        left: 12px;
        z-index: 1;
        & + .input, & + select {
          padding-top: 8px;
          margin-top: 0;
        }
      }
    }
  }
  .select-block-small {
    display: inline-block;
    height: auto;
    select {
      height: auto;
      text-align: center;
      text-align-last: center;
      padding: 0 15px 0 6px;
      margin-top: 0 !important;
    }
  }
  .select-big-arrow {
    &:before {
      width: 15px
    }
    select {
      padding-right: 28px;
    }
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    .select-block {
      select {
        option {
          background-color: @TM2Charcoal;
        }
        &:focus {
          border-color: @TM2White;
        }
      }
    }
  }
}
