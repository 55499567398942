@import 'variables';
@import 'mixins';
@import '../style.variables';

.tm2-modal-footer {
  position: relative;
  width: 100%;
  height: $footerHeight;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 $modalPadding $modalPadding $modalPadding;

  @include mMaxWidth($xs) {
    height: $footerHeightMobile;
    padding: 0 $modalPaddingMobile $modalPaddingMobile $modalPaddingMobile;
  };
}
