@import 'variables';
@import 'mixins';
@import 'spacing-mixins';

$imageWidth: 205px;
$descrValWidth: 120px;

.modal {
  [class=ant-modal] {
    width: 650px !important;
  }
}

.info {
  @include mMbMd();
  display: flex;
  flex-direction: row;
}

.infoImage {
  width: $imageWidth;
  padding-right: 15px;
}

.infoDescription {
  width: calc(100% - #{$imageWidth});
}

.descriptionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.descriptionText {
  width: calc(100% - #{$descrValWidth});
  color: $textColorGrey1;
}

.descriptionValue {
  width: $descrValWidth;
  padding-left: 8px;
}

.noImageDescription {
  $descrValWidth: 150px;

  width: 100%;

  .descriptionText {
    width: calc(100% - #{$descrValWidth});
  }

  .descriptionValue {
    width: $descrValWidth;
  }
}

@include mMaxWidth($sm) {
  .info {
    flex-direction: column;
  }

  .infoImage {
    @include mMbMd();
    width: 100%;
    padding-right: 0;
  }

  .infoDescription {
    width: 100%;
  }
}

.formRow {
  @include mMbSm();
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.colAmount {
  width: 65%;
  padding-right: 15px;
}

.colType {
  width: 35%;
}

@include mMaxWidth($sm) {
  .colAmount {
    width: 55%;
  }

  .colType {
    width: 45%;
  }
}

.textPrimary {
  color: $primaryColor;
}

.label {
  color: $textColorGrey1;
  font-family: $font-family !important;
}

.labelSmall {
  font-size: 0.9em;
}