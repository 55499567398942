.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.verticalFiller {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}