@import 'variables';
@import 'mixins';

.wallet-page {
  &-tab {
    display: flex;
    flex-direction: row;

    @include mMaxWidth($iPad) {
      flex-direction: column;
    };

    &-balances {
      width: 240px;
      margin-right: 10px;

      @include mMaxWidth($iPad) {
        width: 100%;
        margin-right: 0;
      };
    }

    &-table {
      height: max-content;
      width: calc(100% - 240px - 10px);
      background-color: $TM2Charcoal; // for horizontal scroll
      overflow-x: auto;
      @include mHorizontalScrollbar();
      @include mVerticalScrollbar();

      @include mMaxWidth($iPad) {
        width: 100%;
      };

      @include mMinWidth($iPad) {
        max-height: calc(100vh - 180px - var(--dynamic-page-top-banner-height));
      };
    }
  }
}
