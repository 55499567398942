@import 'variables';
@import 'mixins';
@import '../style.variables';

.title {
  position: relative;
  width: 100%;
  height: $headerHeight;
  display: flex;
  flex-direction: row;
  border-bottom: solid $modalBorder 1px;
  font-size: 24px;
  line-height: 1.1em;
  color: $TM2Black;

  @include mMaxWidth($xs) {
    height: $headerHeightMobile;
    font-size: 19px;
  };
}

.icon {
  width: $headerHeight;
  height: $headerHeight;

  @include mMaxWidth($xs) {
    width: $headerHeightMobile;
    height: $headerHeightMobile;
  };
}

.content {
  width: calc(100% - #{$headerHeight});
  height: $headerHeight;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: $modalPadding;

  @include mMaxWidth($xs) {
    width: calc(100% - #{$headerHeightMobile});
    height: $headerHeightMobile;
    padding-left: $modalPaddingMobile;
  };
}

.withIcon {
  width: calc(100% - #{$headerHeight} - #{$headerHeight});
  padding-left: 16px;

  @include mMaxWidth($xs) {
    width: calc(100% - #{$headerHeightMobile} - #{$headerHeightMobile});
  };
}

.back {
  $buttonColor: $TM2Black;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  transform: rotate(180deg);
  background-color: transparent;
  cursor: pointer;

  & svg {
    fill: $buttonColor !important;
    width: 30px !important;
    transition: fill $transitionDuration;
  }

  &:hover svg {
    fill: $primaryColor !important;
  }
}

.close {
  width: $headerHeight;
  height: $headerHeight;
  display: flex;
  cursor: pointer;
  z-index: 10;

  @include mMaxWidth($xs) {
    width: $headerHeightMobile;
    height: $headerHeightMobile;
  };
}

.closeSvg {
  stroke: $TM2Black;
  width: calc(#{$headerHeight} / 4);
  height: calc(#{$headerHeight} / 4);
  margin: auto;

  @include mMaxWidth($xs) {
    width: calc(#{$headerHeightMobile} / 4);
    height: calc(#{$headerHeightMobile} / 4);
  };

  &:hover {
    stroke: $primaryColor;
  }
}
