@import 'theme/variables';

.verticalFiller {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.searchText {
  width: 100%;
  margin-bottom: calc(var(--spacing-base));

  @media (min-width: $sm) {
    width: 280px;
    margin-right: calc(var(--spacing-base));
  }
}

.searchDate {
  width: 100%;
  margin-bottom: calc(var(--spacing-base)) !important;

  @media (min-width: $sm) {
    width: 280px;
  }
}

.clear {
  margin-left: auto;
  margin-bottom: calc(var(--spacing-base));
}

.negativeBalanceText {
  color: var(--tm2-red);
}

