@import 'variables';
@import 'mixins';

.tm2-preloader-line {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &-track {
    position: absolute;
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
    background-color: $TM2Grey1;
    overflow: hidden;

    &-runner {
      background: linear-gradient(270deg, $TM2Gold 0%, $TM2Gold 100%);

      &:before {
        content: '';
        position: absolute;
        background-color: inherit;
        background-image: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        -webkit-animation-delay: 1.15s;
        animation-delay: 1.15s;
      }
    }
  }

  @-webkit-keyframes indeterminate {
    0% {
      left:-35%;
      right:100%
    }
    60% {
      left:100%;
      right:-90%
    }
    100% {
      left:100%;
      right:-90%
    }
  }

  @-webkit-keyframes indeterminate-short {
    0% {
      left:-200%;
      right:100%
    }
    60% {
      left:107%;
      right:-8%
    }
    100% {
      left:107%;
      right:-8%
    }
  }
}
