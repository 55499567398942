@import 'theme/variables';

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: $sm) {
    flex-direction: column;
  };
}
