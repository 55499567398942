@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

@dropdown-image-src: "../../../../../../../img/admin";

.tm2-deprecated-page-styles {
  .dropdown-arrow {
    display: inline-block;
    content: '';
    width: 10px;
    height: 6px;
    vertical-align: middle;
    margin-left: 8px;
    .mBgContain;
    background-image: url("@{dropdown-image-src}/controls/dropdown.svg");
    html.no-svg & {
      background-image: url("@{dropdown-image-src}/controls/dropdown.png");
    }
  }
}
