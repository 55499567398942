@import 'variables';
@import 'mixins';

.tm2-language-selector {
  min-width: max-content;
  width: 100%;
  max-width: 150px;
  padding: 0 10px !important;
  text-transform: uppercase;

  @include mMaxWidth($md) {
    max-width: max-content;
  };

  &-icon-left {
    position: relative;
    width: 1.4em;
    height: 1.4em;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.ant-btn {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;

    &::after {
      display: none !important;
    }

    &:focus {
      border-color: transparent !important;
    }

    span {
      font-family: $font-family;
    }

    svg {
      path {
        transition: stroke $transitionDuration;
      }
    }

    &.tm2-dark {
      border-color: $textColorLight;

      &:hover {
        border-color: $primaryColor;

        svg {
          path {
            stroke: $primaryColor;
          }
        }
      }

      &:focus {
        svg {
          path {
            stroke: $primaryColor;
          }
        }
      }
    }

    &.tm2-light {
      border-color: transparent;
      box-shadow: none;

      &.ant-btn {
        color: $textColorDark;
      }

      svg {
        path {
          stroke: $textColorDark;
        }
      }
    }
  }

  &-menu {
    overflow: hidden;

    &-item {
      width: 100%;
      margin: 0 !important;
      padding: 6px 15px;
      text-transform: uppercase;

      &::before {
        display: none !important;
      }

      .tm2-light & {
        background-color: $TM2White !important;
      }
    }

    &-divider {
      height: 0.5px;
      width: 100%;
      margin: 0 !important;
      border: none;
      background-color: $separator-border-color;

      &::before {
        display: none !important;
      }
    }
  }

  &-menu.tm2-dark {
    &.ant-dropdown-menu {
      border: solid $textColorLight $separatorBorderWidth;
    }
  }

  &-menu.tm2-light {
    border: solid $textColorDark 2px;
    background: transparent;
    padding: 0 !important;
  }

  .tm2-light &-menu-item {
    color: $textColorDark;
    background-color: transparent;

    &:hover {
      background-color: $TM2Grey4 !important;
    }
  }
}
