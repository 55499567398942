@import "variables";

.grid, .accountDetails {
  font-family: $fontFamilySecondary;
}

.col:nth-child(2) {
  text-align: right !important;
}

.textSmall {
  font-size: $fontSizeSmall;
  color: $textColorDark;
  font-family: $fontFamilySecondary;
}
