@import 'variables';
@import 'mixins';
@import '../light.variables';

.header {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $pagePdHor;

  @media (min-width: $xs) and (max-width: $md) {
    height: 80px;
  }

  @media (max-width: $xs) {
    height: 50px;
  }
}

.logo {
  width: 170px;
  height: 65px;
  margin-right: auto;

  @include mMaxWidth($xs) {
    width: 35px;
    height: 35px;
  }
}

.logoImage {
  width: 100%;
  height: 100%;


  @include mMinWidth($xs) {
    &:first-child {
      display: none;
    }
  }

  @include mMaxWidth($xs) {
    &:last-child {
      display: none;
    }
  }
}

.language {
  max-width: 136px;
  margin-right: 20px;

  & > * {
    font-size: 0.9em;
  }

  @include mMaxWidth($xs) {
    margin-right: 5px;
  }
}

.logout {
  & > span { // text
    margin-right: 5px !important;
    font-size: 0.9em !important;
  }
  & > div { // icon
    width: 20px !important;
    height: 20px !important;
  }
}
