@import 'theme/variables';

.centeredWrapper {
  display: flex;
  width: 100%;
  padding: {
    top: 10px;
    bottom: 10px;
  }
}

.centered {
  display: flex;
  justify-content: center;
}

.scrollable {
  // TODO: make global
  $webkit-scrollbar-thickness: 6px;

  scrollbar-width: thin;
  scrollbar-color: $tm2-grey1 $tm2-grey;

  &::-webkit-scrollbar {
    height: $webkit-scrollbar-thickness;
    width: $webkit-scrollbar-thickness;
    background: $tm2-grey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $tm2-grey1;
  }
}
