@import "../../../../shared/styles/variables.antd";
@import "../../mixins";

@standard-image-src: "../../../../../img/admin";

.is-disabled {
  opacity: 0.7;
  pointer-events: none;
}

.is-blurred {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  pointer-events: none;
}

.txt-left {
  text-align: left;
}

.txt-center {
  text-align: center;
}

.is-no-events {
  pointer-events: none;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt-sm {
  font-size: 0.75rem;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

// все, что ниже - перебрать и удалить лишнее
.tm2-deprecated-page-styles {
  .hidden {
    display: none !important;
    visibility: hidden;
  }

  .invisible { visibility: hidden !important; }
  .nowrap { white-space:nowrap; }
  .align-left { text-align: left !important; }
  .align-center { text-align: center !important; }
  .align-right { text-align: right !important; }
  .border-none { border: none !important; }
  .bold { font-weight: 700; }
  .no-underline, .no-underline:hover { text-decoration: none; }
  .underline { text-decoration: underline !important; }

  .color-black { color: @TM2Black !important; }
  .color-green { color: @TM2Green !important; }
  .color-yellow { color: var(--tm2-copper) !important; } // tmp deprecated
  .color-red { color: @TM2Red !important; }
  .color-blue { color: @TM2Gold !important; }
  .color-blue-light {
    color: @TM2Gold !important;
    filter: brightness(1.1);
  }
  .color-blue-dodger { color: @TM2Gold !important; }

  .bg-color-gray { background-color: @TM2Grey1 !important; }
  .bg-color-green { background-color: @TM2Green !important; }
  .bg-color-red { background-color: @TM2Red !important; }
  .bg-color-blue { background-color: @TM2Gold !important; }
  .bg-color-emperor { background-color: @bgColorGrey1 !important;}

  .fix-width-90 { width: 90px; }
  .padding-15 { padding: 15px !important; }
  .padding-0 { padding: 0 !important; }
  .padding-bottom-0 {padding-bottom: 0 !important;}
  .padding-bottom-10 {padding-bottom: 10px !important;}
  .padding-right-0 {padding-right: 0 !important;}
  .width-100 {width: 100px;}
  .width-300 {width: 300px;}
  .min-width-180 {min-width: 180px;}
  .min-width-300 {min-width: 300px;}
  .font-weight-500 {font-weight: 500;}
  .max-width-1000 {max-width: 1000px;}
  .max-height-100-per {max-height: 100%;}
  .min-width-16-per {min-width: 16.6666666667%;}

  .disable-hover, .disable-hover * {
    pointer-events: none !important;
  }

  .small-text {font-size: 90%;}
  .middle-text {font-size: 16px;}
  .big-text {font-size: 18px;}

  .relative {position: relative;}
  .cursor-default {cursor: default;}

  .block-blurred {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  .font-size-12 {font-size: 12px;}
  .display-inline-class {
    display: inline;
  }
  .cursor-pointer-style {
    cursor: pointer;
  }
  .color--grey {
    color: @TM2White;
    opacity: 0.7;
  }
  .no-padding {
    padding: 0;
  }
  .reverse--90-deg {
    transform: scale(1, -1);
  }
  .margin--2em-bottom {
    margin-bottom: 2em;
  }
  .font-size-16 {
    font-size: 16px;
  }
  .flex-space-between { justify-content: space-between; }
  .no-margin { margin: 0 !important; }
  .font-size-20 { font-size: 20px; }
  .display-flex {
    display: flex;
    align-items: center;
  }
  .margin-left-16 { margin-left: 16px; }
  .color-white { color: @TM2White; }
  .top-margin-40 { margin-top: 40px }
  .top-margin-30 { margin-top: 30px; }
  .m-25px { margin: 25px; }
  .width-100-percent { width: 100% !important; }
  .fs-24 { font-size: 24px; }
  .fs-16 { font-size: 16px; }
  .mt-40 { margin-top: 40px; }

  // op means 'opacity'
  .op-link {
    .transition(0.5s);
    transition-property: opacity;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      .opacity(0.8);
    }
  }
  .op-text {
    .opacity(0.7);
  }
  .op-control {
    .opacity(0.7);
    .transition(0.5s);
    transition-property: opacity;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      .opacity(0.9);
    }
  }

  &.tm-dark-theme {
    background-color: transparent;

    .light-gradient-block {
      position: relative;
      padding: 20px;
      .linear-gradient(90deg, rgba(@TM2Grey1Rgb, 0.1), rgba(@TM2CharcoalRgb, 0.02));
      margin: 0 0 20px 0;
      h3:first-child {
        padding-top: 0;
        font-weight: 500;
      }
      table:not(.@{deprecatedStyleExclusiveMarker} table) {
        margin-bottom: 0;
      }
    }
    .gradient-block-small {
      padding: 10px 15px;
      margin: 0;
      td {
        padding-bottom: 5px !important;
      }
    }
    .back-arrow {
      background-image: url("@{standard-image-src}/arrow-left.svg");
    }
  }
}
