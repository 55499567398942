@import 'theme/variables';

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: calc(var(--spacing-base));
}

.searchBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: $xs) {
    width: max-content;
  }
}

.search {
  width: 100%;
  margin-bottom: 0;

  @media (min-width: $sm) {
    width: 300px;
  }
}

.clear {
  margin-left: auto;
  margin-top: calc(var(--spacing-base));

  @media (min-width: $sm) {
    margin-top: 0;
  }
}