@import 'theme/variables';

.form {
  display: flex;
  flex-direction: column;
}

.switchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: calc(4 * var(--spacing-base));
}

.switchLabel {
  flex-grow: 1;
  white-space: nowrap;
}

.switch {
  min-height: initial;

  &:global(.tm2-form-field) {
    width: initial;
  }

  & > :global(.tm2-form-field-item) {
    margin-bottom: 0 !important;
  }
}

.textInput {
  margin-bottom: calc(4 * var(--spacing-base));
}

.saveButton {
  margin-bottom: calc(4 * var(--spacing-base));
}

@media (min-width: $sm) {
  .form {
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--spacing-base);
  }

  .switchLabel {
    margin-right: 15px;
  }

  .switchContainer {
    max-width: 270px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .textInput {
    margin-right: 20px;
    margin-bottom: 0;

    :global(.ant-form-item-explain) {
      position: absolute;
      width: inherit;
      bottom: 0;
      transform: translate(0, 100%);
    }
  }

  .saveButton {
    min-width: 178px;
    margin-bottom: 0;
  }
}

@media (min-width: $lg) {
  .switchContainer {
    max-width: 270px;
    margin-right: 78px;
  }

  .textInput {
    margin-right: 78px;
  }
}
