@import 'variables';

.title {
  font-family: var(--text-h-font-family);
  color: var(--text-color-primary);
  font-size: 24px;
  line-height: 33px;
  margin-bottom: calc(var(--spacing-base) * 6);
}

.form {
  width: 400px;
}

.submitSection {
  display: flex;
  justify-content: space-between;
}

.submitButton {
  flex-grow: 1;
  margin-left: calc(var(--spacing-base) * 6);
}

@media (min-width: $sm) {
  .title {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: calc(var(--spacing-base) * 3);
  }

  .submitButton {
    margin-left: calc(var(--spacing-base) * 4);
  }
}
