@import 'variables';
@import 'mixins';
@import '../light.variables';

.content {
  @include mVerticalScrollbar();
  width: 100%;
  height: $contentHeaderLight;
  display: flex;
  padding: $pagePdVer $pagePdHor;
  overflow-y: auto;
}
