$border-width: 2px;

@mixin switch-base($color) {
  box-shadow: 0 0 0 $border-width var($color) !important;
}

@mixin switch-text($color) {
  color: var($color) !important;
}

:global(.ant-switch) {
  @include switch-base(--switch-color);
  background: transparent;
}

:global(.ant-switch.ant-switch-checked) {
  @include switch-base(--switch-color-checked);
}

:global(.ant-switch-handle:before) {
  background: var(--switch-color);
}

:global(.ant-switch-checked .ant-switch-handle:before) {
  background: var(--switch-color-checked);
}

:global(.ant-switch-inner) {
  @include switch-text(--switch-color);
}

:global(.ant-switch-checked .ant-switch-inner) {
  @include switch-text(--switch-color-checked);
}

.wrapper {
  padding: $border-width;
}
