@mixin mBuyMetalModal {
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &#{&}-total {
      font-size: 1.1em;
      font-weight: bold;
    }

    &-label {
      margin-right: auto;
    }

    &-description {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
