.page {
  max-width: 100%;
  margin: auto;
  padding: 15px 0;
  overflow-x: hidden;
}

.content {
  display: flex;
  justify-content: center;
}
