@import "../../../../shared/styles/variables.antd";
@import "../../mixins";

.tm2-deprecated-page-styles {
  .back-arrow {
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 20px;
    opacity: 0.6;
    .mBgContain;
    h1 &, h2 &, a & {
      margin-right: 6px;
    }
    a&:hover {
      opacity: 1;
    }
  }
  .back-link {
    text-decoration: none;
    font-size: 1.1em;
    .back-arrow {
      vertical-align: middle;
    }
    &:hover {
      text-decoration: none;
    }
  }
  .link-deprecated { display: inline-block; }
  .link-deprecated .icon-item__text {
    color: @TM2Gold;
    text-decoration: underline;
    outline: none;
  }
  .link-deprecated .icon-item__text:hover {
    color: @TM2Grey4;
    text-decoration: underline;
  }

  .link {
    position: relative;
    background: none;
    padding: 0;
    border: none;
    border-radius: 0;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    .transition(0.3s);
    transition-property: color, background-color, border-color, opacity;
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
