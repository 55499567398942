@import 'theme/variables';

.submitRow {
  display: flex;
  justify-content: space-between;
}

.tabHeader {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    right: -10px;
    top: 12px;
    border-radius: 50%;
    opacity: 0;
    background: $tm2-red;
  }
}

.tabHeaderWithNotification:after {
  opacity: 1;
  transition: opacity .3s;
}
