@import 'theme/variables';

.banner {
  width: 100%;
  height: 130px;
  max-width: var(--page-content-max-width);
  margin-bottom: calc(var(--spacing-base) * 3);
  opacity: 0.8;
  background-size: cover;
  font-size: 16px;
  font-family: var(--text-h-font-family);
  line-height: 1.9;

  @media (min-width: $sm) {
    margin-bottom: var(--spacing-base);
  }

  @media (min-width: $md) {
    height: 260px;
    font-size: 36px;
    letter-spacing: 0.30em;
  }
  // стили ниже формируются на этапе запроса на бекенд
  :global(.banner-layout) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :global(.banner-layout-line-1) {
    margin-right: 40%;

    @media (min-width: $md) {
      margin-right: 50%;
    }
  }

  :global(.banner-layout-line-2) {
    margin-right: 40%;

    @media (min-width: $md) {
      margin-right: 50%;
    }
  }

  :global(.banner-layout-line-3) {
    margin-right: 40%;

    @media (min-width: $md) {
      margin-right: 50%;
    }
  }

  :global(.banner-layout-line-3-text) {
    width: 100%;
    display: flex;
  }

  :global(.banner-layout-line-3-text-border) {
    display: inline-block;
    flex-grow: 1;
    margin-bottom: 7px;
    border-bottom-color: white;
    border-bottom-style: solid;
    border-bottom-width: 1px;

    @media (min-width: $md) {
      margin-bottom: 14px;
      border-bottom-width: 3px;
    }
  }
}
