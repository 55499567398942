@import 'theme/variables';

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: $sm) {
    padding-top: calc(var(--spacing-base) * 4);
  }
}

.item {
  display: flex;
  width: calc(50% - var(--spacing-base));

  @media (max-width: $sm) {
    width: 100%;
  }
}