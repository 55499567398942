@import '../../../../shared/styles/variables.antd';
@import '../../mixins';

html, body, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ant-dropdown {
  font-family: @font-family;
}

.tm2-deprecated-page-styles {
  tbody {
    font-family: @fontFamilySecondary;

    .link {
      font-family: @fontFamilySecondary;
    }
    .icon-item__text {
      font-family: @fontFamilySecondary;
    }
  }

  a {
    text-decoration: underline;
    outline: none;
  }
  input, select, textarea, .input {
    outline: none;
    font-size: 1em;
  }
  textarea { resize:none; }
  img { max-width: 100%; }

  p {
    margin: 0.5em 0 1em;
    &:first-child { margin-top: 0 !important; }
    &:last-child { margin-bottom: 0 !important; }
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 1.5em 0 0.5em;
    line-height: 1.2em;
    font-weight: 400;
    font-style: normal;
    &:first-child {
      margin-top: 0;
      padding-top: 0.5em;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .title-1, h1, h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 1em;
  }
  .subtitle, h3 {
    font-size: 16px;
    text-transform: none;
    .mMediaMaxWidth(@xl, {
      font-size: 1.5em;
    });
  }
  small {
    font-size: 90%;
    line-height: 1.1em;
  }
  hr {
    border: none;
    background-color: @TM2Grey1;
    height: 1px;
    &.blue-hr {
      background-color: @TM2Gold;
    }
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    a {
      color: @TM2Gold;
      &:hover {
        filter: brightness(1.1);
      }
    }
    input, select, textarea, .input {
      font-family: @font-family;
      color: @textColorLight;
    }
    h1, h2, h3, h4, h5, h6 {
      color: @textColorLight;
    }
  }
}
