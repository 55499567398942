.label {
  top: 2px;
}

.labelShifted {
  top: -11px;
}

.item {
  margin-bottom: 0 !important;

  :global(.ant-picker-range) {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background: transparent !important;
    z-index: 10;
  }

  :global(.ant-picker-active-bar) {
    margin-left: 0 !important;
  }
}

.rangePicker {
  :global(.ant-picker-suffix) {
    color: var(--tm2-white);
    font-size: 16.5px;
  }

  :global(.ant-picker-clear) {
    right: 0 !important;
    background: var(--bg2) !important;
    font-size: 16.5px;

    svg {
      color: var(--tm2-white);
    }
  }
}
