$downloadWidth: calc(32px + (var(--spacing-base) * 6));

.actionsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.bankAccount {
  width: calc(100% - #{$downloadWidth} - var(--spacing-base));
  margin-bottom: var(--spacing-base);

  @media (min-width: 930px) {
    width: calc(50% - ((#{$downloadWidth} + (var(--spacing-base) * 2)) / 2));
    max-width: 310px;
    margin-right: var(--spacing-base);
  }
}

.period {
  width: 100%;
  margin-bottom: var(--spacing-base);

  @media (min-width: 930px) {
    width: calc(50% - ((#{$downloadWidth} + (var(--spacing-base) * 2)) / 2));
    max-width: 310px;
  }
}
