@import 'variables';

$height: 52px;
$background: var(--bg2);
$paddingHor: calc(var(--spacing-base) * 3);

.label {
  max-width: 100%;
  position: absolute;
  left: 0;
  top: -2px;
  margin: 0;
  padding: 0;
  font-size: var(--text-b1-size) !important;
  color: var(--tm2-grey3);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: top $transitionDuration, left $transitionDuration, font-size $transitionDuration;
  z-index: 1;
}

.labelShifted {
  top: -15px;
  font-size: 0.85em !important;
  z-index: 10;
}

.compact {
  margin-bottom: 0 !important;
}

.wrapper {
  height: $height;
  padding: 12px $paddingHor;
  background-color: $background;
}

.disabled {
  opacity: $formFieldDisabledOpacity;
  pointer-events: none;
}

.box {
  height: $height;
  padding-left: $paddingHor;
  padding-right: $paddingHor;
  background-color: $background;
}
