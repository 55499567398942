.priceRow {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.priceInput {
  display: flex;
  flex-grow: 1;
  min-height: 30px;
  margin-right: 24px;

  & > :first-child {
    width: 100%;
    margin-bottom: 0;
  }
}
