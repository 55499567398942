@import 'variables';

.loader {
  width: 1.2em;
  height: 1.2em;
}

.circle {
  fill: none;
  stroke: $TM2Black;
  stroke-width: 12;
}

.circleContrast {
  stroke: $TM2Grey3;
}
