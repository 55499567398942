@import 'variables';

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  transform: translateY(calc(-1 * var(--dynamic-page-content-top-indent-height)));
  padding: {
    top: calc(var(--dynamic-page-content-top-indent-height) * 1.2);
    bottom: calc(var(--dynamic-page-content-top-indent-height) * 1.2);
  }
  background: var(--palette-color-primary);
  word-break: break-word;

  &:before, &:after {
    content: "";
    position: absolute;
    width: $pagePdHor;
    height: 100%;
    top: 0;
    background: var(--palette-color-primary);
  }

  &:before {
    left: calc(-1 * #{$pagePdHor});
  }

  &:after {
    right: calc(-1 * #{$pagePdHor});
  }
}

.text {
  display: block;
  text-align: center;
  color: var(--text-color-primary) !important;
}

@media (min-width: $sm) {
  .banner {
    padding: {
      top: calc(var(--dynamic-page-content-top-indent-height) / 2);
      bottom: calc(var(--dynamic-page-content-top-indent-height) / 2);
    }
  }
}