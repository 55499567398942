@import 'theme/variables';

.outer {
  font-size: var(--text-b2-size);
  font-family: var(--text-b-font-family);
  line-height: var(--text-b2-line-height);
  color: var(--text-color-primary);
  z-index: map-get($layers, 'table-tooltip');
}

.outer :global(.ant-tooltip-arrow-content),
.outer :global(.ant-tooltip-inner) {
  background-color: var(--bg1);
  border: 1px solid #{$tm2-grey1};
  filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.3));
}

.outer :global(.ant-tooltip-arrow) {
  z-index: 100;
  width: 30px;
  height: 13px;
  bottom: -4px;
}

.outer :global(.ant-tooltip-arrow-content) {
  position: absolute;
  top: -7px;
  width: 25px;
  height: 20px;
  border: {
    top: none;
    left: none;
  };
}
