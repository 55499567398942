@import 'theme/variables';

$tab-indent: 54px;

.header {
  position: relative;
  display: flex;
  margin-bottom: 24px;
}

.headerBordered {
  border-bottom: {
    style: solid;
    width: var(--tabs-border-width);
    color: var(--tabs-border-color);
  }
}

.headerScrollableContainer {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  // https://github.com/nolimits4web/swiper/blob/5466df697934d1632c8c574c05964c02d95dcf9a/src/core/core.scss#L109
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    display: none;
  }
}

.underline {
  position: absolute;
  height: 3px;
  bottom: -2px;
  transition: .4s all;
  background: var(--tabs-border-color-selected);
}

.tabHeader {
  display: block;
  width: 0;
  flex-grow: 1;
  text-align: center;
  padding-bottom: 11px;
  padding-top: 11px;
  font-family: var(--tabs-font-family);
  font-size: var(--tabs-text-size);
  color: var(--tabs-text-color-base);
  cursor: pointer;
  user-select: none;

  &:first-child {
    margin-left: 0;
  }
}

.tabHeaderScrollable {
  flex-grow: 0;
  width: auto;
  margin-left: $tab-indent;
}

.tabHeaderActive {
  color: var(--tabs-text-color-selected);
}

@media (min-width: $sm) {
  .tabHeader {
    width: auto;
    flex-grow: 0;
    margin-left: $tab-indent;
    text-align: left;
  }
}

.tabHidden {
  display: none !important;
}
