@import 'variables';

.tm2-field-textarea {
  .ant-input-disabled {
    opacity: $formFieldDisabledOpacity;
  }
}

.tm2-light-theme .tm2-form-field {
  &.tm2-field-textarea {
    textarea {
      color: $textColorDark;
    }
  }
}
