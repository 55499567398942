@import 'theme/variables';

.label {
  top: 2px;
}

.labelShifted {
  top: -11px;
}

.item {
  margin-bottom: 0;

  :global(.ant-form-item-control-input-content) {
    position: relative;
  }

  :global(.ant-select) {
    z-index: 10;
  }
}

.select {
  :global(.ant-select-selector) {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :global(.ant-select-selection-search) {
    left: 0 !important;
    right: 0 !important;
  }

  :global(.ant-select-selection-item) {
    color: #FFFFFF !important;
    font-family: Arial, sans-serif;
    font-weight: normal !important;
  }

  :global(.ant-select-arrow) {
    right: 0 !important;
    color: var(--tm2-white) !important;
    font-size: 16.5px;
  }
}

.dropdown { // note этот блок живет вне приложения и ему не доступны scss-variables
  background: transparent;
  overflow: visible;

  & > div {
    width: calc(100% + 24px + 24px);
    padding: 10px 12px;
    margin-left: -24px;
    margin-top: 4px;
    border: solid #5e5a5a 1px;
    background: $tm2-charcoal;

    @media (min-width: $sm) {
      margin-top: 8px;
    }
  }

  :global(.ant-select-item-option) {
    background: transparent !important;
    font-family: Arial, sans-serif;
    font-weight: normal;
  }

  :global(.ant-select-item-option-active) {
    color: $tm2-gold;
  }

  :global(.ant-select-item-option-selected) {
    font-weight: 600;
  }
}

.option {
  background: transparent !important;
}
