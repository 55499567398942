@import 'variables';
@import 'mixins';
@import '../../../../../shared/modules/modal/base/style.variables';

.wallet-transfer-modal { // TODO основано на старых стилях, переписать после миграции табов

  .ant-modal {
    width: calc(100vw - 10px) !important;
    max-width: 600px !important;
  }

  .tab-links {
    display: flex;
    height: $headerHeight;
    border-bottom: solid $modalBorder 1px;

    @include mMaxWidth($xs) {
      height: $headerHeightMobile;
    };

    .tab-link {
      $tabSpace: 20px;
      $tabSpaceMobile: 10px;

      height: $headerHeight;
      margin-bottom: 0;
      line-height: $headerHeight;
      overflow-x: hidden;
      white-space: pre-line;

      @include mMaxWidth(570px) {
        font-size: 18px;
      };

      @include mMaxWidth($xs) {
        height: $headerHeightMobile;
        line-height: $headerHeightMobile;
      };

      @include mMaxWidth(400px) {
        line-height: 1.1em;
      };

      &:nth-child(1) { // transfer metals tab
        max-width: calc(50% - (#{$modalPadding} + #{$headerHeight} + #{$tabSpace}) / 2);
        margin-left: $modalPadding;
        margin-right: $tabSpace;

        @include mMaxWidth($xs) {
          max-width: calc(50% - (#{$modalPaddingMobile} + #{$headerHeightMobile} + #{$tabSpaceMobile}) / 2);
          margin-left: $modalPaddingMobile;
          margin-right: $tabSpaceMobile;
        };
      }

      &:nth-child(2) { // transfer funds tab
        max-width: calc(50% - (#{$modalPadding} + #{$headerHeight} + #{$tabSpace}) / 2);
        margin-left: 0;
        margin-right: 0;

        @include mMaxWidth($xs) {
          max-width: calc(50% - (#{$modalPaddingMobile} + #{$headerHeightMobile} + #{$tabSpaceMobile}) / 2);
        };
      }

      &:last-child {
        width: $headerHeight;
        margin-left: auto;

        @include mMaxWidth($xs) {
          width: $headerHeightMobile;
        };
      }
    }
  }

  .tab-blocks {
    max-height: calc(100vh - #{$headerHeight} - #{$modalPadding} - #{$modalPadding} - #{$phoneBarsHeight});
    margin: $modalPadding;
    overflow-y: auto;
    overflow-x: hidden;
    @include mVerticalScrollbar();

    @include mMaxWidth($xs) {
      max-height: calc(100vh - #{$headerHeightMobile} - #{$modalPaddingMobile} - #{$modalPaddingMobile} - #{$phoneBarsHeight});
      margin: $modalPaddingMobile;
    };
  }
}
