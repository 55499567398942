@import 'theme/variables';

.form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: $sm) {
    flex-direction: column;
    justify-content: unset;
  }
}

.field {
  width: calc(50% - (var(--spacing-base) * 2));

  &:last-child:nth-child(2n + 1) {
    width: 100%;
  }

  @media (max-width: $sm) {
    width: 100%;
  }
}
