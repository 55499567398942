@import 'theme/variables';

.box {
  max-width: 700px;
}

.form {
  display: flex;
  flex-direction: column;
}

.numberOfAccount {
  width: 100%;
  margin-left: unset;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);

  @media (min-width: $sm) {
    width: 40%;
    margin-left: auto;
  }
}

.saveButton {
  margin-left: unset;
  width: 100%;

  @media (min-width: $sm) {
    margin-left: auto;
    width: unset;
  }
}