@import 'variables';

.label {
  color: $textColorGrey1;
}

.form {
  width: 100%;
  max-width: 400px;
}
