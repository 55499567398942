@import 'variables';

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.resultRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.2;
}
