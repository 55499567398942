@import 'variables';

.tm2-field-checkbox-group {
  &-item {
    &-label {
      color: $textColorGrey1;
      font-family: var(--text-b-font-family);
    }

    &-box {
      $gap: 25px;
      width: 100%;
      display: grid;
      gap: 8px 25px;
    }
  }

  // TODO: ниже временное решение, чтобы переопределить старые стили, нужен большой рефакторинг (подробнее в TM2-1953)
  input[type=checkbox] {
    top: 0 !important;
    left: 0 !important;
  }

  .ant-checkbox-wrapper {
    align-items: center;

    .ant-checkbox {
      top: unset;

      &-checked {
        &:after {
          border: none;
        }
      }
    }

    .ant-checkbox-inner {
      width: 22px;
      height: 22px;

      &:before {
        display: none;
      }

      &:after {
        left: 27% !important;
        transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--tm2-grey1);
    border-color: var(--tm2-grey1);
  }

  .ant-checkbox-group-item:hover .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $primaryColor;
  }
}

.tm2-light-theme .tm2-form-field {
  .tm2-field-checkbox-group {
    &-item {
      &-label {
        color: $textColorDark;
        font-family: var(--text-b-font-family);
      }

      &-box {
        .ant-checkbox-group-item {
          color: $textColorDark;
        }
      }
    }
  }
}
