@import 'variables';

.tm2-field-number-range {
  .tm2-form-field-item-instance {
    height: $formFieldHeight;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 11px;
    border: solid 1px;
    cursor: pointer;

    &.ant-input-disabled,
    &.ant-input-disabled .tm2-form-label {
      cursor: not-allowed;
    }
  }

  .tm2-form-label {
    cursor: pointer;
  }

  &-value {
    color: $textColorLight;
    font-family: var(--text-b-font-family) !important;
  }

  .ant-input-number-disabled {
    opacity: $formFieldDisabledOpacity;
  }
}

.tm2-light-theme .tm2-field-number-range {
  &-value {
    color: $textColorDark;
  }
}
