@import "../../../../../shared/styles/variables.antd";
@import "../../../mixins";

@label-image-src: "../../../../../../../img/admin";

.tm2-deprecated-page-styles {
  label.field-label, label.form-label {
    position: relative;
    text-align: left;
  }
  label.field-label {
    display: block;
    margin-bottom: 6px;
    line-height: 1.1em;
    &.label-small {
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: -4px;
      & + .textarea-block {
        margin-top: 8px;
      }
      & + .row-labels, & ~ label.form-label {
        margin-top: 8px;
      }
      & + .button-labels {
        margin-top: 10px;
      }
      & + .input {
        margin-top: 5px;
      }
      .mMediaMaxWidth(1400px, {
        font-size: 12px;
      });
    }
  }
  label.form-label {
    display: inline-block;
    cursor: pointer;
    margin-right: 25px;
    margin-bottom: 8px;
    &:last-child {
      margin-right: 0;
    }
    & + & {
      /*margin-top: 1.5em;*/
      margin-top: 8px;
    }
  }
  div:not([class]) > label.form-label, div:not([class]) > label.form-label span {
    width: 100%;
  }

  .row-image-labels {
    margin: 0 -5px;
    div[class*="col"] {
      padding: 0 5px 5px;
    }
  }
  label.logo-label {
    display: block;
    margin: 0;
    .logo-label__image {
      padding: 25px 15px;
      border: solid 1px transparent;

    }
    input[type=checkbox]:checked + .logo-label__image,
    input[type=radio]:checked + .logo-label__image {
      border-color: @TM2Gold;
    }
  }
  .logo-label__image {
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      .mBgContain;
      background-size: 80% 80%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .logo-label--tm2-wallet & {
        background-image: url("@{label-image-src}/payments/tm2-wallet.svg");
        html.no-svg & {
          background-image: url("@{label-image-src}/payments/tm2-wallet.png");
        }
      }
      .logo-label--paypal & {
        background-image: url("@{label-image-src}/payments/paypal.svg");
        html.no-svg & {
          background-image: url("@{label-image-src}/payments/paypal.png");
        }
      }
      .logo-label--epayments & {
        background-image: url("@{label-image-src}/payments/epayments.svg");
        html.no-svg & {
          background-image: url("@{label-image-src}/payments/epayments.png");
        }
      }
    }
  }
  .row-labels {
    margin: 0 -5px;
    > div[class*="col"] {
      padding: 5px;
    }
  }
  .line-label {
    display: inline-block;
    margin-right: 10px;
  }

  /* ---------TM2 Dark Layout---------- */
  &.tm-dark-theme {
    label {
      i {
        color: @textColorLight;
      }
    }

    .logo-label {
      input[type=checkbox]:checked + .logo-label__image,
      input[type=radio]:checked + .logo-label__image {
        .linear-gradient(90deg, @TM2Grey3, @TM2Charcoal);
      }
    }
  }
}
